
import Vue from 'vue'
import App from './App.vue'

import dotenv from 'dotenv'


import jQuery from 'jquery'

global.jquery = jQuery
global.$ = jQuery
window.$ = window.jQuery = require('jquery')


import 'jquery-ui';
import 'touch-punch'






Vue.config.productionTip = false

dotenv.config()

new Vue({
    // router,
    render: h => h(App),
}).$mount('#app')


