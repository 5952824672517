<template>


    <div id="wrapper">




        <div class="main" style="position:relative" id="main">
            <div class="step00" style="" v-show="gamestart ==  3">
             
                <div style="" class="tobe">
                    <br /><br /><br /><br /> <br /><br /><br /><br />
                    <img :src="'./img/common/tobe.png?2'" style="width:80%" />
                    <br />
                    <br /><br /><br /><br /> <br /><br /><br /> <br /><br /><br /> <br /><br />
                                                                                         <div style="font-size:0.8em">
                                                                                             クリアポストをしてみんなにシェアしよう！
                                                                                         </div>
                    <div style="margin-top:20px">
                        <a href="https://x.gd/ew74S" target="_blank">
                            <img :src="'./img/common/tweet.png?2'" style="width:60%" />
                        </a>
                    </div>
                    <br />

                    <div style="width:80%;background-color:rgb(255 255 255 / 0.68);color:black;font-size:0.5em;text-align:left;line-height:1.2em;padding:20px;">

    

                        <div>

                        </div>
                        <div style="text-align:center">
                            ＜街ハックの別企画の紹介＞<br /><br />
                            <a href="https://machihack.com/akihabara" target="_blank">
                                <img :src="'./img/common/akihabara_cover.png?2'" style="width:100%" />
                            </a> <br /> <br />
                            <a href="https://machihack.com/asakusa" target="_blank">
                                <img :src="'./img/common/asakusa_cover.jpeg?1'" style="width: 100%" />
                            </a> <br /> <br />
                            <a href="https://tokyometro.machihack.com/" target="_blank">
                                <img :src="'./img/common/metro_application_1.png?2'" style="width: 100%" />
                            </a> <br /> <br />
                            <br />
                        </div>
                        <br />
                        <a href="https://www.machihack.com/top/list" target="_blank" class="link">

                            <div class="btn1">一覧はこちら</div>
                        </a>
                        <br /><br /><br />
                    </div>


                    <br /> <br />
                    <a href="https://www.machihack.com/" target="_blank">
                        <img :src="'./img/top/hakku_logo.png?2'" style="width:30%" />
                    </a>  <br /><br /><br />
                </div>
            </div>
            <!--TOPページ-->
            <div id="toppage" v-show="gamestart == -1">
                <div class="top1">
                    <br />
                    <img :src="'./img/top/top_main.png?3'" class="" style="width:80%;max-width:550px" /><br />
                    <div>
                    </div>
                    <img :src="'./img/top/top_catch.png?2'" class="" style="width:90%;max-width:500px" /><br />

                    <br />
                </div>
                <div class="top2">
                    <br />
                    <img :src="'./img/top/story_title.png?2'" class="" style="width:40%;max-width:300px" /><br />

                    <div style="margin-top:-30px">

                        <img :src="'./img/top/ojyo_top_kazari.png?2'" class="" style="width:90%;max-width:550px" />
                    </div>


                    <div class="toptext" style="width:80%;text-align:left;margin:0 auto;font-size:0.7em">
                        探偵事務所で暇を持て余していたミライは<br />美術館で展示会中止のニュースを耳にする。<br />
                        事件の予感がしたミライはメイドの渚と執事の銀河と共に美術館へ向かうと、画家と絵画が失踪したことを知る。<br />
                        ミライたちは失踪事件の謎に迫っていく！<br />
                        想定プレイ時間：60～120分
                        <br />
                        <br />

                        <div style="width:100%;text-align:center">
                            遊ぶためにはログインが必要です。<br />
                            <a href="https://www.machihack.com/hints/ojosama-web-202406-keyword" target="_blank">
                                <img :src="'./img/top/hakku_logo.png?2'" style="width:200px" />
                            </a>
                        </div>

                    </div>
                    <br />
                    <img :src="'./img/top/ojyo_top_gamestart.png?2'" class="" style="width:50%;max-width:400px" @click.stop="gamestartclick()" /><br />
                    <br />
                    <img :src="'./img/top/ojyo_top_kazari2.png?2'" class="" style="width:90%;max-width:500px" /><br />
                    <br />
                    <br />
                </div>
                <div class="top1">
                    <br />
               
                    <div class="keytitle" style="font-size:0.8em">
                        ミライの右手に宿るエスパー能力とは・・・!!
                    </div>
                    <div>


                        <div class="toptext" style="width:80%;line-height:2em;font-size:0.6em">
                            <div style="">
                                <br />

                                右手で触れることで、その場所やモノに宿った<br />「残留思念」を読み取ることができる。
                                <br />
                                15歳の時に事故に遭い、その衝撃で能力が開花した。<br />
                                <br />
                                残留思念…人が強く何かを思ったり願ったりした時、<br />その場所やモノに留まり続ける思考や感情のこと。<br />
                            </div>
                        </div>

                        <br />
                        <div style="line-height:1.2em;font-size:0.6em">
                            これまでのおはなし <br /><br />
                        </div>

                        <div style="display:flex">
                            <div style="width:25%">

                                <a href="https://machihack.com/landmarkmystery" target="_blank">
                                    <img :src="'./img/top/ojyo_series1.png?2'" class="" style="width:100%;" />
                                </a>
                                <br />
                                <img :src="'./img/top/kako_yokohama.png'" style="width:70%;" />
                            </div>
                            <div style="width:25%">
                                <a href="https://machihack.com/asakusa" target="_blank">
                                    <img :src="'./img/top/ojyo_series2.png?2'" class="" style="width:100%;" />
                                </a>
                                <br />
                                <img :src="'./img/top/kako_asakusa.png'" style="width:70%;" />
                            </div>
                            <div style="width:25%">
                                <a href="https://machihack.com/akihabara" target="_blank">
                                    <img :src="'./img/top/ojyo_series3.png?2'" style="width:100%;" />
                                </a><br />
                                <img :src="'./img/top/kako_akiba.png'" style="width:70%;" />

                            </div>
                        </div>



                    </div>
                    <br />
                    <img :src="'./img/top/ojyo_top_kazari2.png?2'" class="" style="width:90%;max-width:500px" /><br />
                    <br />
                    <div>
                        <img :src="'./img/top/character_title.png?2'" class="" style="width:80%;max-width:400px" />
                        <br /><br />
                        <div style="width:100%">
                            <div style="width:90%;display:flex">
                                <div style="width:50%">
                                    <img :src="'./img/top/ojyo_top_mirai.png?2'" class="" style="width:90%" />
                                </div>
                                <div style="width: 50%;text-align:left;vertical-align:bottom">
                                    <br /><br /><br />
                                    <span class="charaname">濱野 ミライ(22)</span>
                                    <div class="charatext" style="vertical-align:bottom">
                                        大手不動産企業の社長令嬢。<br />
                                        典型的なワガママお嬢様。<br />
                                        とある事件をキッカケに<br />
                                        「エスパー探偵 」を名乗り始める。<br />
                                        そう。ミライの右手には、とある<br />
                                        珍しい能力が宿っているのだ。
                                    </div>
                                </div>

                            </div>
                            <br /><br />
                            <div style="display:flex">
                                <div style="width:50%">
                                    <div style="width:80%;text-align:left">
                                        <img :src="'./img/top/ojyo_top_ginga.png?2'" class="" style="width:80%" />

                                        <div>
                                            <span class="charaname">銀河(25)</span>
                                            <div class="charatext">
                                                ミライを真摯に守る執事。<br />
                                                ワガママなミライの<br />
                                                扱いに慣れている。<br />
                                                多趣味で、色々な物事に<br />
                                                精通しているようだが・・・
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style="width: 50% ">
                                    <div style="width:80%;text-align:left">
                                        <img :src="'./img/top/ojyo_top_nagisa.png?2'" class="" style="width:80%" />

                                        <div>
                                            <span class="charaname">渚(20)</span>
                                            <div class="charatext">
                                                ミライに忠実に仕えるメイド。<br />
                                                ミライから可愛がられて<br />
                                                おり仲が良い。<br />
                                                ハッキング等
                                                機械操作が得意。
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <br />

                </div>


                    <div class="top2">
                        <!--<div>

            <br />
            <div style="width: 90%; max-width: 600px; background-color: white; color: #3d1104">
                <div style="text-align:center">
                    よくある質問
                </div>
                <div style="text-align:left">
                    <div>

                    </div>
                </div>

            </div>
            <br />
        </div>-->
                        <div>
                            <br />
                            <div style="width:90%;max-width:600px;background-color:white;color:#3d1104">
                                <div style="text-align:center">
                                    注意事項
                                </div>
                                <div style="text-align:left;font-size:0.4em;line-height:1.3em;width:90%;margin:0 auto;">
                                    ・企画への参加には街ハック！への無料の会員登録が必要です。<br />
                                    ・Webの通信料はお客様負担となります。<br />
                                    ・下記「配信ガイドラインはこちら」の内容をご確認いただいたうえで、ネタバレ配信・投稿をすることが可能です。<br />
                                    <br />

                                    <div v-show="chuiflg == 1">
                                        <div style="">
                                            ＜配信ガイドライン＞<br />
                                            ・はじめに<br />
                                            まだ謎解きを体験していないお客様が、ネタバレ配信・投稿を不意に目にする状況は、すべて自分で楽しむことを望むお客様の喜びを奪う可能性があります。そこで、以下に定めたガイドラインを必ずご確認の上、その内容に従い配信・投稿してください。<br />
                                            <br />
                                            ▽配信・投稿ルール▽<br />
                                            ・タイトルには「ネタバレを含む配信・投稿であること」を必ず記載してください。<br />
                                            <br />
                                            ・概要欄等には、<br />
                                            ①本企画タイトルの「お嬢様はエスパー探偵 WEBの特別編」<br />
                                            ②本企画のURLである「https://ojousama.web.machihack.com/」<br />
                                            をそれぞれ必ず記載してください。<br />
                                            <br />
                                            ・指定の媒体にて一般公開で配信・投稿してください。<br />
                                            <br />
                                            ▽ガイドライン▽<br />
                                            ■配信・動画投稿は、以下の媒体において行うことができます。<br />
                                            ・ニコニコ動画／生放送<br />
                                            ・YouTube<br />
                                            ・TwitCasting<br />
                                            ・OPENREC.tv<br />
                                            ・Twitch<br />
                                            ・Mirrativ<br />
                                            ・Mildom<br />
                                            <br />
                                            ■配信・投稿は、一般公開してください。<br />
                                            <br />
                                            ■SNS（X、Instagram、TikTok、Facebook等）などの、投稿を不意に目にするおそれがある媒体で、謎の答えを投稿する行為は、まだプレイしていないお客様の楽しみを不用意に奪うおそれがあるため禁止します。ただし、ネタバレを含まないクリア報告や感想等の投稿は大歓迎です。この場合、本ガイドラインに従う必要はありません。<br />
                                            <br />
                                            ■公序良俗に反する配信・投稿、このガイドラインに従わない配信・投稿には、媒体への削除申請またはお客様への削除要請を行うことがあります。当社から削除要請を受けた場合、すみやかにこれに従ってください。<br />
                                        </div>

                                        <br /> <br /><br />
                                        <!--</div>


                        <div style="text-align:left;font-size:0.4em;line-height:1.3em;width:90%;margin:0 auto;">-->
                                      
                                    </div>
                                    <br />
                                    <div class="btn--orange" @click.stop="showtyui()">{{chuiflg == 1? "閉じる":"もっとみる"}}</div>
                                </div>
                                <br />
                            </div>
                            <br /><br />
                        </div>
                    </div>

                </div>

            <!--導入-->
            <div class="step00" style="" v-show="gamestart ==  0" id="step0">
                <div class="common">
                    <br />

                    <img :src="'./img/top/title.png?2'" class="" />
                    <br />
                    <div>
                        <br /><br />
                        <div class="text">
                            合言葉
                        </div>
                        <span class="error" id="aikotobaerror">合言葉が違います。</span>
                        <div class="inputarea">
                            <form @submit.prevent="Start()">

                                <input type="text" placeholder="" class="inputbox" v-model="inputstart" maxlength="10" />
                                <img :src="'./img/common/enter.png?2'" class="inputenter" @click.stop="Start()" />

                            </form>

                        </div>
                    </div>

                    <br />
                    <br />
                    <div style="text-align:center;font-size:0.7em;line-height:1.3em">
                        合言葉を入手するためには<br />街ハックへのログインもしくは新規登録が必要です。
                    </div>
                    <div>
                        <br />
                        <a href="https://www.machihack.com/hints/ojosama-web-202406-keyword" target="_blank" class="link">
                            <div class="btn1">合言葉の入手はこちら</div>
                        </a>
                    </div>
                    <br />
                    <br />
                    <br />
                </div>
            </div>

            <div v-if="gamestart == 1" class="step00 ">
                <div class="common">
                    <br />
                    <br />
                    <br />
                    <img :src="'./img/top/title.png?2'" class="" />
                    <br />
                    <br />
                    <!--はじめから-->
                    <img :src="'./img/top/top_-badge_hajime.png?2'" class="btnstart" @click.stop="GameNewStart()" />
                    <br />
                    <br />
                    <!--続きから-->
                    <img :src="'./img/top/top_-badge_tsuduki.png?2'" class="btnstart" @click.stop="GameContinue()" v-show="this.savedata.maxque > 0" />
                    <br />
                    <br />
                </div>
            </div>
            <!--START-->

            <div v-if="gamestart == 2" style="position: relative; width: 750px;height:1200px;background-color:antiquewhite">

                <div style="" @click.stop="Next()" v-show="savedata.story == 1 ">


                    <!--<div style="position: absolute; top: 0; left: 0; width: 750px; height: 1200px; ">
        <div style="width:100%;height:100%;background-color:antiquewhite">
            <div style="margin:0 auto">
                <img :src="'./img/haikei/' + data[savedata.nowque].back + '.png?2'" class=""  />
            </div>
        </div>
    </div>-->
                    <img :src="'./img/haikei/' + data[savedata.nowque].back + '.png?2'" class="haikei2" style=" " v-show="data[savedata.nowque].step == -1" />
                    <img :src="'./img/haikei/' + data[savedata.nowque].back + '.png?2'" class="haikei" style=" "  v-show="data[savedata.nowque].step != -1" />
                    <div>
                        <img :src="'./img/chara/mirai-01.png?2'" :class="[ 'charamain_mirai',data[savedata.nowque].subchara != '' ? 'taransx_1':'']" v-show="data[savedata.nowque].mainchara == 'mirai-01'" />
                        <img :src="'./img/chara/mirai-02.png?2'" :class="[ 'charamain_mirai',data[savedata.nowque].subchara != '' ? 'taransx_1':'']" style="" v-show="data[savedata.nowque].mainchara == 'mirai-02'" />
                        <img :src="'./img/chara/mirai-03.png?2'" :class="[ 'charamain_mirai',data[savedata.nowque].subchara != '' ? 'taransx_1':'']" style="" v-show="data[savedata.nowque].mainchara == 'mirai-03'" />

                        <img :src="'./img/chara/nagisa-01.png?2'" :class="[ 'charamain_nagisa',data[savedata.nowque].subchara != '' ? 'taransx_1':'']" style="" v-show="data[savedata.nowque].mainchara == 'nagisa-01'" />
                        <img :src="'./img/chara/nagisa-02.png?2'" :class="[ 'charamain_nagisa',data[savedata.nowque].subchara != '' ? 'taransx_1':'']" style="" v-show="data[savedata.nowque].mainchara == 'nagisa-02'" />
                        <img :src="'./img/chara/nagisa-03.png?2'" :class="[ 'charamain_nagisa',data[savedata.nowque].subchara != '' ? 'taransx_1':'']" style="" v-show="data[savedata.nowque].mainchara == 'nagisa-03'" />

                        <img :src="'./img/chara/ginga-01.png?2'" :class="[ 'charamain_ginga',data[savedata.nowque].subchara != '' ? 'taransx_1':'']" style="" v-show="data[savedata.nowque].mainchara == 'ginga-01'" />
                        <img :src="'./img/chara/ginga-02.png?2'" :class="[ 'charamain_ginga',data[savedata.nowque].subchara != '' ? 'taransx_1':'']" style="" v-show="data[savedata.nowque].mainchara == 'ginga-02'" />

                        <img :src="'./img/chara/joren-01.png?2'" :class="[ 'charamain_joren',data[savedata.nowque].subchara != '' ? 'taransx_1':'']" style="" v-show="data[savedata.nowque].mainchara == 'joren-01'" />
                        <img :src="'./img/chara/kantyou-01.png?2'" :class="[ 'charamain_kantyou',data[savedata.nowque].subchara != '' ? 'taransx_1':'']" style="" v-show="data[savedata.nowque].mainchara == 'kantyou-01'" />
                        <img :src="'./img/chara/tensyu-01.png?2'" :class="[ 'charamain_tensyu',data[savedata.nowque].subchara != '' ? 'taransx_1':'']" style="" v-show="data[savedata.nowque].mainchara == 'tensyu-01'" />

                        <img :src="'./img/chara/narikawa-01.png?2'" :class="[ 'charamain_narikawa',data[savedata.nowque].subchara != '' ? 'taransx_1':'']" style="" v-show="data[savedata.nowque].mainchara == 'narikawa-01'" />
                        <img :src="'./img/chara/narikawa-02.png?2'" :class="[ 'charamain_narikawa',data[savedata.nowque].subchara != '' ? 'taransx_1':'']" style="" v-show="data[savedata.nowque].mainchara == 'narikawa-02'" />
                        <img :src="'./img/chara/narikawa-03.png?2'" :class="[ 'charamain_narikawa',data[savedata.nowque].subchara != '' ? 'taransx_1':'']" style="" v-show="data[savedata.nowque].mainchara == 'narikawa-03'" />
                        <img :src="'./img/chara/narikawa-04.png?2'" :class="[ 'charamain_narikawa',data[savedata.nowque].subchara != '' ? 'taransx_1':'']" style="" v-show="data[savedata.nowque].mainchara == 'narikawa-04'" />
                        <img :src="'./img/chara/narikawa-05.png?2'" :class="[ 'charamain_narikawa',data[savedata.nowque].subchara != '' ? 'taransx_1':'']" style="" v-show="data[savedata.nowque].mainchara == 'narikawa-05'" />

                        <img :src="'./img/chara/fudetsuka-01.png?2'" :class="[ 'charamain_fude',data[savedata.nowque].subchara != '' ? 'taransx_1':'']" style="" v-show="data[savedata.nowque].mainchara == 'fudetsuka-01'" />


                        <img :src="'./img/chara/mirai-ginga.png?2'" :class="[ 'charamain_2',data[savedata.nowque].subchara != '' ? 'taransx_1':'']" style="" v-show="data[savedata.nowque].mainchara == 'mirai-ginga'" />
                        <img :src="'./img/chara/nagisa-ginga.png?2'" :class="[ 'charamain_2',data[savedata.nowque].subchara != '' ? 'taransx_1':'']" style="" v-show="data[savedata.nowque].mainchara == 'nagisa-ginga'" />

                        <!--<img :src="'./img/chara/' + data[savedata.nowque].mainchara + '.png?2'" class="charamain" style="" v-show="data[savedata.nowque].mainchara != ''" />-->
                        <!--<img :src="'./img/chara/' + data[savedata.nowque].subchara  + '.png?2'" class="charasub" style="" v-show="data[savedata.nowque].subchara != ''" />-->


                        <img :src="'./img/chara/mirai-01.png?2'" class="charasub" v-show="data[savedata.nowque].subchara == 'mirai-01'" />
                        <img :src="'./img/chara/mirai-02.png?2'" class="charasub" style="" v-show="data[savedata.nowque].subchara == 'mirai-02'" />
                        <img :src="'./img/chara/mirai-03.png?2'" class="charasub" style="" v-show="data[savedata.nowque].subchara == 'mirai-03'" />

                        <img :src="'./img/chara/nagisa-01.png?2'" class="charasub" style="" v-show="data[savedata.nowque].subchara == 'nagisa-01'" />
                        <img :src="'./img/chara/nagisa-02.png?2'" class="charasub" style="" v-show="data[savedata.nowque].subchara == 'nagisa-02'" />
                        <img :src="'./img/chara/nagisa-03.png?2'" class="charasub" style="" v-show="data[savedata.nowque].subchara == 'nagisa-03'" />

                        <img :src="'./img/chara/ginga-01.png?2'" class="charasub_ginga" style="" v-show="data[savedata.nowque].subchara == 'ginga-01'" />
                        <img :src="'./img/chara/ginga-02.png?2'" class="charasub_ginga" style="" v-show="data[savedata.nowque].subchara == 'ginga-02'" />

                        <img :src="'./img/chara/joren-01.png?2'" class="charasub" style="" v-show="data[savedata.nowque].subchara == 'joren-01'" />
                        <img :src="'./img/chara/kantyou-01.png?2'" class="charasub_kan" style="" v-show="data[savedata.nowque].subchara == 'kantyou-01'" />
                        <img :src="'./img/chara/tensyu-01.png?2'" class="charasub_tensyu" style="" v-show="data[savedata.nowque].subchara == 'tensyu-01'" />

                        <img :src="'./img/chara/narikawa-01.png?2'" class="charasub" style="" v-show="data[savedata.nowque].subchara == 'narikawa-01'" />
                        <img :src="'./img/chara/narikawa-02.png?2'" class="charasub" style="" v-show="data[savedata.nowque].subchara == 'narikawa-02'" />
                        <img :src="'./img/chara/narikawa-03.png?2'" class="charasub" style="" v-show="data[savedata.nowque].subchara == 'narikawa-03'" />
                        <img :src="'./img/chara/narikawa-04.png?2'" class="charasub" style="" v-show="data[savedata.nowque].subchara == 'narikawa-04'" />
                        <img :src="'./img/chara/narikawa-05.png?2'" class="charasub" style="" v-show="data[savedata.nowque].subchara == 'narikawa-05'" />
                        <img :src="'./img/chara/fudetsuka-01.png?2'" class="charasub_fude" style="" v-show="data[savedata.nowque].subchara == 'fudetsuka-01'" />

                        <img :src="'./img/chara/mirai-ginga.png?2'" class="charasub" style="" v-show="data[savedata.nowque].subchara == 'mirai-ginga'" />
                        <img :src="'./img/chara/nagisa-ginga.png?2'" class="charasub" style="" v-show="data[savedata.nowque].subchara == 'nagisa-ginga'" />


                    </div>
                    <div class="raincontainer" v-show="data[savedata.nowque].event == 'ENDING1'">
                        <div class="rains">
                            <span></span><span></span><span></span><span></span><span></span>
                            <span></span><span></span><span></span><span></span><span></span>
                            <span></span><span></span><span></span><span></span><span></span>
                            <span></span><span></span><span></span><span></span><span></span>
                        </div>

                    </div>
                    <img :src="'./img/haikei/scene_step' + data[savedata.nowque].step + '_haikei.png?2'" class="bgwaku" v-show="data[savedata.nowque].step >= 0" style="" />


                    <div style="z-index:20" class="textarea" v-show="data[savedata.nowque].text != ''">
                        <div>
                            <img :src="'./img/chara/name_fudetsuka.png?2'" class="nametag" v-show="data[savedata.nowque].name == 'name_fudetsuka'" />
                            <img :src="'./img/chara/name_ginga.png?2'" class="nametag" v-show="data[savedata.nowque].name == 'name_ginga'" />
                            <img :src="'./img/chara/name_hatena.png?2'" class="nametag" v-show="data[savedata.nowque].name == 'name_hatena'" />
                            <img :src="'./img/chara/name_joren.png?2'" class="nametag" v-show="data[savedata.nowque].name == 'name_joren'" />
                            <img :src="'./img/chara/name_kantyou.png?2'" class="nametag" v-show="data[savedata.nowque].name == 'name_kantyou'" />
                            <img :src="'./img/chara/name_mirai.png?2'" class="nametag" v-show="data[savedata.nowque].name == 'name_mirai'" />
                            <img :src="'./img/chara/name_mirai_ginga.png?2'" class="nametag" v-show="data[savedata.nowque].name == 'name_mirai_ginga'" />
                            <img :src="'./img/chara/name_nagisa.png?2'" class="nametag" v-show="data[savedata.nowque].name == 'name_nagisa'" />
                            <img :src="'./img/chara/name_nagisa_ginga.png?2'" class="nametag" v-show="data[savedata.nowque].name == 'name_nagisa_ginga'" />
                            <img :src="'./img/chara/name_narikawa.png?2'" class="nametag" v-show="data[savedata.nowque].name == 'name_narikawa'" />
                            <img :src="'./img/chara/name_radio.png?2'" class="nametag" v-show="data[savedata.nowque].name == 'name_radio'" />
                            <img :src="'./img/chara/name_tegami.png?2'" class="nametag" v-show="data[savedata.nowque].name == 'name_tegami'" />
                            <img :src="'./img/chara/name_tensyu.png?2'" class="nametag" v-show="data[savedata.nowque].name == 'name_tensyu'" />
                        </div>
                        <span v-html="data[savedata.nowque].text" @click="handleLineClick"></span>
                    </div>

                    <!--<img :src="'./img/chara/' + data[savedata.nowque].name  + '.png?2'" class="nametag" v-show="data[savedata.nowque].name != ''" />-->

                    <img :src="'./img/common/triangleicon.png?2'" class="kaiwacursor" />



                </div>

                <div v-show="savedata.story == 2 ">
                    <div>
                        <!--チュートリアル出題-->
                        <div v-if="data[savedata.nowque].step == 0">
                            <div>

                                <img :src="'./img/haikei/scene_step1_haikei.jpg'" class="" />
                                <div class="imgposion">
                                    <img :src="'./img/step00/renshu_nazo.png?2'" style="width:80%" />
                                    <br />
                                    <img :src="'./img/step00/step1_mirai-serihu04.png?2'" />
                                </div>
                                <div class="inputpostion">

                                    <br />
                                    <span class="error" id="aikotobaerror1">間違っているようだ。</span>
                                    <div class="inputarea">

                                        <form @submit.prevent="Rensyu()">

                                            <input type="text" placeholder="" class="inputbox" v-model="rensyustart" maxlength="10" />
                                            <img :src="'./img/common/enter.png?2'" class="inputenter" @click.stop="Rensyu()" />

                                        </form>

                                    </div>
                                </div>

                            </div>


                        </div>
                        <img :src="'./img/haikei/step1_intro.png?2'" class="" v-show="data[savedata.nowque].step == 1" @click.stop="dispQuestion(1)" />
                        <img :src="'./img/haikei/step2_intro.png?2'" class="" v-show="data[savedata.nowque].step == 2" @click.stop="dispQuestion(2)" />
                        <img :src="'./img/haikei/step3_intro.png?2'" class="" v-show="data[savedata.nowque].step == 3" @click.stop="dispQuestion(3)" />
                        <img :src="'./img/haikei/step4_intro.png?2'" class="" v-show="data[savedata.nowque].step == 4" @click.stop="dispQuestion(4)" />
                        <img :src="'./img/haikei/step5_intro.png?2'" class="" v-show="data[savedata.nowque].step == 5" @click.stop="dispQuestion(5)" />
                    </div>


                </div>
                <!--出題エリア-->
                <div v-show="savedata.story == 3 ">
                    <!--Step1-->
                    <div v-if="data[savedata.nowque].step == 1">
                        <div class="quesbase">

                            <img :src="'./img/haikei/scene_step1_haikei.jpg'" class="" />
                            <div class="ques">
                                <div v-show="savedata.step1dispmode == 0 ">

                                    <div style="display:flex">
                                        <div v-bind:style="{'z-index':[savedata.step1flg == 0 ? 15:1]} " class="step01_box" @click.stop="Step1(1)">

                                            <span v-show="savedata.clearqeus[0][0] == 1 ">済</span>
                                            <span v-show="savedata.clearqeus[0][0] == 0 ">①</span>


                                            <div>
                                                <img :src="'./img/step01/scene_step1_Q1_1.png?2'" class="step01_1" />
                                                <img :src="'./img/step01/scene_step1_Q1_2.png?2'" class="step01_1" /><br />
                                                <img :src="'./img/step01/scene_step1_Q1_3.png?2'" class="step01_1" />
                                                <img :src="'./img/step01/scene_step1_Q1_4.png?2'" class="step01_1" /><br />
                                                <img :src="'./img/step01/scene_step1_Q1_5.png?2'" class="step01_1" />
                                            </div>

                                        </div>
                                        <div class="step01_box" @click.stop="Step1(2)">


                                            <span v-show="savedata.clearqeus[0][1] == 1 ">済</span>
                                            <span v-show="savedata.clearqeus[0][1] == 0 ">②</span>

                                            <div>
                                                <img :src="'./img/step01/scene_step1_Q2_1.png?2'" class="step01_2" /><br />
                                                <img :src="'./img/step01/scene_step1_Q2_2.png?2'" class="step01_2" />
                                            </div>

                                        </div>
                                    </div>

                                    <div style="display:flex">
                                        <div class="step01_box" @click.stop="Step1(3)">

                                            <div>

                                                <span v-show="savedata.clearqeus[0][2] == 1 ">済</span>
                                                <span v-show="savedata.clearqeus[0][2] == 0 ">③</span>

                                            </div>
                                            <div style="text-align:center">
                                                <img :src="'./img/step01/scene_step1_Q3_1.png?2'" class="step01_1" />
                                                <img :src="'./img/step01/scene_step1_Q3_2.png?2'" class="step01_1" />
                                                <br />
                                                <img :src="'./img/step01/scene_step1_Q3_3.png?2'" class="step01_1" />
                                            </div>

                                        </div>
                                        <div class="step01_box" @click.stop="Step1(4)">

                                            <div>
                                                <span v-show="savedata.clearqeus[0][3] == 1 ">済</span>
                                                <span v-show="savedata.clearqeus[0][3] == 0 ">④</span>

                                            </div>
                                            <div>
                                                <img :src="'./img/step01/scene_step1_Q4_1.png?2'" class="step01_1" />
                                                <img :src="'./img/step01/scene_step1_Q4_2.png?2'" class="step01_1" /><br />
                                                <img :src="'./img/step01/scene_step1_Q4_3.png?2'" class="step01_1" />
                                                <img :src="'./img/step01/scene_step1_Q4_4.png?2'" class="step01_1" />
                                            </div>

                                        </div>
                                    </div>


                                </div>

                                <div v-show="savedata.step1dispmode != 0 ">
                                    <div style="text-align: right; margin-top: 0px">
                                        <img :src="'./img/common/close_icon.png?2'" class="" @click.stop="Step1(0)" style="width:8%;margin-right:50px;" />
                                    </div>
                                    <div v-show="savedata.step1dispmode == 1 ">
                                        <div>
                                            <div style="display:flex" class="">
                                                <div class="step01_box_2" v-bind:style="{'z-index':[savedata.step1flg == 2 ? 15:1]} ">
                                                    <img :src="'./img/step01/scene_step1_Q1_1.png?2'" class="step01_1_2" @click="step1Kaiga(0,0)" />
                                                    <div class="sinenboxarea">
                                                        <div class="sinenbox" v-show="savedata.step1sinen[0][0] == 1 ">
                                                            題名「あめ」
                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="step01_box_2">
                                                    <img :src="'./img/step01/scene_step1_Q1_2.png?2'" class="step01_1_2" @click="step1Kaiga(0,1)" />
                                                    <div class="sinenboxarea">
                                                        <div class="sinenbox" v-show="savedata.step1sinen[0][1] == 1 ">
                                                            題名「かみなり」
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style="display:flex">
                                                <div class="step01_box_2">
                                                    <img :src="'./img/step01/scene_step1_Q1_3.png?2'" class="step01_1_2" @click="step1Kaiga(0,2)" />
                                                    <div class="sinenboxarea">
                                                        <div class="sinenbox" v-show="savedata.step1sinen[0][2] == 1 ">
                                                            題名「はたけ」
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="step01_box_2">
                                                    <img :src="'./img/step01/scene_step1_Q1_4.png?2'" class="step01_1_2" @click="step1Kaiga(0,3)" />
                                                    <div class="sinenboxarea">
                                                        <div class="sinenbox" v-show="savedata.step1sinen[0][3] == 1 ">
                                                            題名「まち」
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style="display:flex">

                                                <div class="step01_box_2">
                                                    <img :src="'./img/step01/scene_step1_Q1_5.png?2'" class="step01_1_2" @click="step1Kaiga(0,4)" />
                                                    <div class="sinenboxarea">
                                                        <div class="sinenbox" v-show="savedata.step1sinen[0][4] == 1 ">
                                                            題名はかな４文字
                                                        </div>
                                                    </div>
                                                </div>
                                                <!--<div class="step01_box_2">

                                            </div>-->
                                            </div>

                                        </div>
                                    </div>
                                    <div v-show="savedata.step1dispmode == 2 ">
                                        <div>
                                            <div style="display:flex" class="">
                                                <div class="step01_box_2_2">
                                                    <img :src="'./img/step01/scene_step1_Q2_1.png?2'" class="step01_2_2" @click="step1Kaiga(1,0)" />
                                                    <div class="sinenboxarea">
                                                        <div class="sinenbox" v-show="savedata.step1sinen[1][0] == 1 ">
                                                            題名「ハト」

                                                        </div>
                                                    </div>

                                                </div>


                                            </div>
                                            <div style="display:flex">
                                                <div class="step01_box_2_2">
                                                    <img :src="'./img/step01/scene_step1_Q2_2.png?2'" class="step01_2_2" @click="step1Kaiga(1,1)" />
                                                    <div class="sinenboxarea">
                                                        <div class="sinenbox" v-show="savedata.step1sinen[1][1] == 1 ">
                                                            題名が２文字

                                                        </div>
                                                    </div>
                                                </div>

                                            </div>


                                        </div>
                                    </div>

                                    <div v-show="savedata.step1dispmode == 3">
                                        <div>
                                            <div style="display:flex" class="">
                                                <div class="step01_box_2_3">
                                                    <img :src="'./img/step01/scene_step1_Q3_1.png?2'" class="step01_1_2" @click="step1Kaiga(2,0)" />
                                                    <div class="sinenboxarea">
                                                        <div class="sinenbox" v-show="savedata.step1sinen[2][0] == 1 ">
                                                            題名「リ」

                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="step01_box_2_3">
                                                    <img :src="'./img/step01/scene_step1_Q3_2.png?2'" class="step01_1_2" @click="step1Kaiga(2,1)" />
                                                    <div class="sinenboxarea">
                                                        <div class="sinenbox" v-show="savedata.step1sinen[2][1] == 1 ">
                                                            題名「４」

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style="display:flex">
                                                <div class="step01_box_2_3">
                                                    <img :src="'./img/step01/scene_step1_Q3_3.png?2'" class="step01_1_2" @click="step1Kaiga(2,2)" />
                                                    <div class="sinenboxarea">
                                                        <div class="sinenbox" v-show="savedata.step1sinen[2][2] == 1 ">
                                                            題名はかな３文字
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>


                                        </div>
                                    </div>

                                    <div v-show="savedata.step1dispmode == 4">
                                        <div>
                                            <div style="display:flex" class="">
                                                <div class="step01_box_2_4">
                                                    <img :src="'./img/step01/scene_step1_Q4_1.png?2'" class="step01_1_2" @click="step1Kaiga(3,0)" />
                                                    <div class="sinenboxarea">
                                                        <div class="sinenbox" v-show="savedata.step1sinen[3][0] == 1 ">
                                                            こい

                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="step01_box_2_4">
                                                    <img :src="'./img/step01/scene_step1_Q4_2.png?2'" class="step01_1_2" @click="step1Kaiga(3,1)" />
                                                    <div class="sinenboxarea">
                                                        <div class="sinenbox" v-show="savedata.step1sinen[3][1] == 1 ">
                                                            がけ

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style="display:flex">
                                                <div class="step01_box_2_4">
                                                    <img :src="'./img/step01/scene_step1_Q4_3.png?2'" class="step01_1_2" @click="step1Kaiga(3,2)" />
                                                    <div class="sinenboxarea">
                                                        <div class="sinenbox" v-show="savedata.step1sinen[3][2] == 1 ">
                                                            えき

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="step01_box_2_4">
                                                    <img :src="'./img/step01/scene_step1_Q4_4.png?2'" class="step01_1_2" @click="step1Kaiga(3,3)" />
                                                    <div class="sinenboxarea">
                                                        <div class="sinenbox" v-show="savedata.step1sinen[3][3] == 1 ">
                                                            たて


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div style="width:80%;margin-top:20px;">
                                                <div class="sinenboxarea">
                                                    <div class="sinenbox2" v-show="savedata.step1sinen[3][0] == 1 && savedata.step1sinen[3][1] == 1 && savedata.step1sinen[3][2] == 1 && savedata.step1sinen[3][3] == 1">
                                                        4枚からなる連作のタイトルは4文字
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div v-show="savedata.step1dispmode == 5">
                                        <div>
                                            <br />
                                            <img :src="'./img/step01/step1_kami.png?2'" class="step01_1_2" style="width:90%" />

                                            <br /><br /> <br />
                                            <img :src="'./img/step01/step1_mirai-serihu03.png?2'" />
                                        </div>
                                    </div>
                                </div>

                                <div style="" class="hukidasi" v-show="savedata.step1dispmode == 0 ">
                                    <!--<div style="display:inline">
                                    <img :src="'./img/chara/icon_mirai-01.png?2'" class="iconc" />
                                </div>-->
                                    <div class="" style="display:inline">
                                        <div v-if="(savedata.step01kami[0] != 0 && savedata.step01kami[1] != 0 && savedata.step01kami[2] != 0 && savedata.step01kami[3] != 0)">
                                            <!--４つの紙がつながりましたわ！-->
                                            <br />
                                            <img :src="'./img/step01/step1_mirai-serihu01.png?2?1'" />
                                        </div>
                                        <div v-if="!(savedata.step01kami[0] != 0 && savedata.step01kami[1] != 0 && savedata.step01kami[2] != 0 && savedata.step01kami[3] != 0)">

                                            <br />
                                            <img :src="'./img/step01/step1_mirai-serihu02.png?2?1'" />
                                        </div>

                                    </div>
                                </div>





                            </div>

                            <div style="z-index:10" class="cover" @click.stop="Step1(-1)" v-show="savedata.step1flg <= 2">
                            </div>

                            <div class="arrow_box2" style="top: 100px; z-index: 20; position: absolute; top: 100px; left: 100px" v-show="savedata.step1flg == 0 ">
                                まずは絵画をタップしよう
                            </div>
                            <div class="arrow_box2" style="top: 100px; z-index: 20; position: absolute; top: 920px; left: 50px" v-show="savedata.step1flg == 1">
                                思念をタップしよう
                            </div>
                            <div class="arrow_box2" style="top: 100px; z-index: 20; position: absolute; top: 50px; left: 100px;height:70px;line-height:1.1em" v-show="savedata.step1flg == 2 ">
                                絵画をタップすると謎を解く手がかりになる思念が現れるよ
                            </div>
                            <div class="arrow_box2" style="top: 100px; z-index: 20; position: absolute; top: 900px; left: 100px; height: 70px; line-height: 1.1em" v-show="savedata.step1flg == 2 ">
                                思念の情報を駆使して謎を解き明かしていこう
                            </div>
                        </div>

                        <!--STEP1 回答入力欄-->
                        <div class="inputpostion2">
                            <div style="display:flex!important;margin-top:30px;">
                                <div v-show="savedata.step1dispmode != 0 " style="display:flex;margin-top:10px;text-align:left;width:300px;">
                                    <div class="sinen" v-bind:style="{'z-index':[savedata.step1flg == 1 ? 15:1],} ">
                                        <img :src="'./img/common/scene_step_b1.png?2'" class="sinenicon" v-show="savedata.step1dispmode != 0 && savedata.step1sinenflg == 0 " @click.stop="Step1Sine(1)" />
                                        <img :src="'./img/common/scene_step_b2.png?2'" class="sinenicon" v-show="savedata.step1dispmode != 0 && savedata.step1sinenflg == 1 " @click.stop="Step1Sine(0)" />
                                    </div>
                                </div>
                                <div v-show="savedata.step1dispmode != 0 " style="width:calc(100% - 150px);">

                                    <div style="margin-right:150px;">
                                        <span class="error" id="step1error">間違っているようだ。</span> <span>&emsp;</span>
                                        <div class="inputarea2">

                                            <form @submit.prevent="Step1Ansewer()">

                                                <input type="text" placeholder="" class="inputbox" v-model="inputStep1" maxlength="10" />
                                                <img :src="'./img/common/enter.png?2'" class="inputenter" @click.stop="Step1Ansewer()" />

                                            </form>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--STEP1アイテム欄-->
                        <div class="inputpostion2">
                            <div style="display:flex;" class="" v-show="savedata.step1dispmode == 0 ">

                                <div>

                                    <table class="itemtable" style="z-index:2">
                                        <tr>
                                            <th colspan="5">
                                                アイテム
                                            </th>

                                        </tr>
                                        <tr>
                                            <td>
                                                <img :src="'./img/step01/step1_kami.png?2'" class="getitem" @click.stop="Step1(5)" v-if="(savedata.step01kami[0] != 0 && savedata.step01kami[1] != 0 && savedata.step01kami[2] != 0 && savedata.step01kami[3] != 0)" />

                                                <img :src="'./img/step01/step1_kami_'+ savedata.step01kami[0] +'.png?2'" class="getitem" v-if="!(savedata.step01kami[0] != 0 && savedata.step01kami[1] != 0 && savedata.step01kami[2] != 0 && savedata.step01kami[3] != 0)" />
                                            </td>
                                            <td>

                                                <img :src="'./img/step01/step1_kami_'+ savedata.step01kami[1] +'.png?2'" class="getitem" v-if="!(savedata.step01kami[0] != 0 && savedata.step01kami[1] != 0 && savedata.step01kami[2] != 0 && savedata.step01kami[3] != 0)" />
                                            </td>
                                            <td>

                                                <img :src="'./img/step01/step1_kami_'+ savedata.step01kami[2] +'.png?2'" class="getitem" v-if="!(savedata.step01kami[0] != 0 && savedata.step01kami[1] != 0 && savedata.step01kami[2] != 0 && savedata.step01kami[3] != 0)" />
                                            </td>
                                            <td>


                                                <img :src="'./img/step01/step1_kami_'+ savedata.step01kami[3] +'.png?2'" class="getitem" v-if="!(savedata.step01kami[0] != 0 && savedata.step01kami[1] != 0 && savedata.step01kami[2] != 0 && savedata.step01kami[3] != 0)" />
                                            </td>
                                            <td>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                            </td>
                                            <td>
                                            </td>
                                            <td>
                                            </td>
                                            <td>
                                            </td>
                                            <td>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>

                        </div>
                    </div>

                    <!--Step2-->
                    <div v-if="data[savedata.nowque].step == 2" style="background-color:#696969">



                        <div v-show="step02flg == 0">
                            <video playsinline width="700" height="1200" :src="'./img/step02/step2_01.mp4'" id="video1" @timeupdate="videoTimeUpdate(1)"></video>
                            <!--<div style="width: 80px; height: 80px;  position: absolute; top: 860px; left: 380px" @click.stop="Step2Next(1);">

                        </div>-->


                        </div>
                        <div v-show="step02flg == 1">
                            <video playsinline width="700" height="1200" :src="'./img/step02/step2_02.mp4'" id="video2" @timeupdate="videoTimeUpdate(2)"></video>
                            <!--<div style="width: 80px; height: 80px;  position: absolute; top: 480px; left: 470px" @click.stop="Step2Next(2);">

                        </div>-->
                        </div>
                        <div v-show="step02flg == 2">
                            <video playsinline width="700" height="1200" :src="'./img/step02/step2_03.mp4'" id="video3" @timeupdate="videoTimeUpdate(3)"></video>
                            <!--<div style="width: 80px; height: 80px;  position: absolute; top: 955px; left: 285px" @click.stop="Step2Next(3);">

                        </div>-->
                        </div>

                        <div v-show="step02flg == 3">
                            <video playsinline width="700" height="1200" :src="'./img/step02/step2_04.mp4'" id="video4" @timeupdate="videoTimeUpdate(4)"></video>
                            <!--<div style="width: 80px; height: 80px; position: absolute; top: 385px; left: 192px" @click.stop="Step2Next(4);">

                        </div>-->
                        </div>
                        <div style="position:absolute;top:295px;left:90px;">
                            <div v-for="index1 in 8" v-bind:key="index1">
                                <div style="display:flex">
                                    <div v-for="index2 in 6" v-bind:key="index2" style="width:94px;height:94px; " @click.stop="Step2Next(index1,index2)">

                                    </div>
                                </div>

                            </div>
                        </div>

                        <div style=" position: absolute; top: 0px; left: 0px;width:100%;height:100%;background-color:rgb(0 0 0 / 0.7)" v-show="step2clear == 1">
                            <br /><br /><br /><br /><br /><br />
                            <div>
                                <img :src="'./img/common/success.png?2'" style="width:70%;" />
                            </div>
                            <br /><br /><br />
                            <img :src="'./img/step02/next.png?2'" style="width:30%;" v-show="step02flg == 0 && step2clear == 1" @click.stop="Step2NextVideo(2);" />
                            <img :src="'./img/step02/next.png?2'" style="width:30%;" v-show="step02flg == 1 && step2clear == 1" @click.stop="Step2NextVideo(3);" />
                            <img :src="'./img/step02/next.png?2'" style="width:30%;" v-show="step02flg == 2 && step2clear == 1" @click.stop="Step2NextVideo(4);" />


                            <img :src="'./img/step02/clear.png?2'" style="width:30%;" v-show="step02flg == 3 && step2clear == 1" @click.stop="Step2NextVideo(5);" />


                        </div>

                        <div style=" position: absolute; top: 0px; left: 0px; width: 100%; height: 100%; background-color: rgb(0 0 0 / 0.7); z-index: 300" v-show="step2gameover == 1">

                            <br /><br /><br /><br /><br /><br />
                            <div>
                                <img :src="'./img/common/game over.png?2'" style="width:70%;" v-show="step2gameover2 == 1" />
                                <div v-show="step2gameover2 == 0">
                                    <br /><br /><br />
                                </div>
                            </div>
                            <br /><br /><br />
                            <img :src="'./img/common/retry.png?2'" style="width:30%;" @click.stop="Step2NextVideo(1);" />

                        </div>
                    </div>

                    <!--Step3-->
                    <div v-if="data[savedata.nowque].step == 3">
                        <div style="position: relative; width: 750px;height:1200px;background-color:antiquewhite" @click="setSinen()">


                            <!--<img :src="'./img/step03/step3_room1_05.png?2'" class="room" style="" v-show="step3room == 0" />-->
                            <!--<div style="" class="room_base">-->
                            <!--<div class="room_base" v-show="step3room == 0">
        <img :src="'./img/step03/step3_room1_05.png?2'" class="room" />
    </div>

    <div class="room_base" v-show="step3room == 1">
        <img :src="'./img/step03/step3_room2_01.png?2'" />
    </div>
    <div class="room_base" v-show="step3room == 2">
        <img :src="'./img/step03/step3_room3_01.png?2'" />
    </div>
    <div class="room_base" v-show="step3room == 3">
        <img :src="'./img/step03/step3_room4_01.png?2'" />
    </div>
    <div class="room_base" v-show="step3room == 4">
        <img :src="'./img/step03/step3_room3_01.png?2'" />
    </div>-->
                            <img :src="'./img/step03/step3_room1_02.png?2'" class="room_base" v-show="step3room == 0  && step3pc != 8" />
                            <img :src="'./img/step03/step3_room1_03.png?2'" class="room_base" v-show="step3room == 0 && step3pc == 8" />

                            <img :src="'./img/step03/step3_room2_01.png?2'" class="room_base" v-show="step3room == 1" />
                            <img :src="'./img/step03/step3_room3_01.png?2'" class="room_base" v-show="step3room == 2" />
                            <img :src="'./img/step03/step3_room4_01.png?2'" class="room_base" v-show="step3room == 3 && step3pc != 8 " />
                            <img :src="'./img/step03/step3_room4_01_2.png?2'" class="room_base" v-show="step3room == 3 && step3pc == 8  " />

                            <img :src="'./img/step03/step3_room3_01.png?2'" class="room_base" v-show="step3room == 4" />




                            <img :src="'./img/step03/step3_room4_01_3.png?2'" id="clearroom" class="room_base fade" style="display:none" />
                            <img :src="'./img/haikei/scene_step3_haikei.png?2'" class="bgwaku2" style="" />

                            <div style="position: absolute; width: 165px; height: 100px; top: 490px; left: 100px; " class="pcselect"
                                 v-show="step3room == 0 && !step3dispc" @click="openPC(true)"></div>

                            <div class="dial_mini" v-show="step3room == 0" @click="step03desklockopen(true)">
                                <div style="position:relative;height:12px;display:flex">
                                    <div style="">
                                        <img :src="'./img/step03/dial/red_yajirushi_l.png?2'" />
                                    </div>

                                    <div style="background-color:black;height:10px;padding:2px">
                                        <img :src="'./img/step03/dial/scene_step3_no' + step3lock[0] + '.png?2'" />

                                        <img :src="'./img/step03/dial/scene_step3_no' + step3lock[1] + '.png?2'" />
                                        <img :src="'./img/step03/dial/scene_step3_no' + step3lock[2] + '.png?2'" />
                                        <img :src="'./img/step03/dial/scene_step3_no' + step3lock[3] + '.png?2'" />
                                        <img :src="'./img/step03/dial/scene_step3_no' + step3lock[4] + '.png?2'" />
                                        <img :src="'./img/step03/dial/scene_step3_no' + step3lock[5] + '.png?2'" />
                                    </div>
                                    <div style="">
                                        <img :src="'./img/step03/dial/red_yajirushi_r.png?2'" />
                                    </div>
                                </div>
                            </div>

                            <div class="room" v-show="step3room == 1">

                                <div style="">
                                    <img :src="'./img/step03/step3_room2_e4_doro.png?2'" style="position: absolute; width: 85px; bottom: 280px; left: 80px" @click.stop="clearn(1)" v-show="savedata.step03clean[0] == 0" />
                                    <img :src="'./img/step03/step3_room2_e5_doro.png?2'" style="position: absolute; width: 85px; bottom: 310px; left: 180px " @click.stop="clearn(2)" v-show="savedata.step03clean[1] == 0" />
                                    <img :src="'./img/step03/step3_room2_e1_doro.png?2'" style="position: absolute; height: 85px; bottom: 260px; left: 280px " @click.stop="clearn(3)" v-show="savedata.step03clean[2] == 0" />

                                    <img :src="'./img/step03/step3_room2_e2_doro.png?2'" style="position: absolute; height: 85px; bottom: 160px; left: 60px " @click.stop="clearn(4)" v-show="savedata.step03clean[3] == 0" />
                                    <img :src="'./img/step03/step3_room2_e6_doro.png?2'" style="position: absolute; width: 85px; bottom: 160px; left: 180px " @click.stop="clearn(5)" v-show="savedata.step03clean[4] == 0" />
                                    <img :src="'./img/step03/step3_room2_e3_doro.png?2'" style="position: absolute; width: 85px; bottom: 130px; left: 280px " @click.stop="clearn(6)" v-show="savedata.step03clean[5] == 0" />


                                    <img :src="'./img/step03/step3_room2_e4.png?2'" style="position: absolute; width: 85px; bottom: 280px; left: 80px" v-show="savedata.step03clean[0] == 1" />
                                    <img :src="'./img/step03/step3_room2_e5.png?2'" style="position: absolute; width: 85px; bottom: 310px; left: 180px " v-show="savedata.step03clean[1] == 1" />
                                    <img :src="'./img/step03/step3_room2_e1.png?2'" style="position: absolute; height: 85px; bottom: 260px; left: 280px " v-show="savedata.step03clean[2] == 1" />

                                    <img :src="'./img/step03/step3_room2_e2.png?2'" style="position: absolute; height: 85px; bottom: 160px; left: 60px " v-show="savedata.step03clean[3] == 1" />
                                    <img :src="'./img/step03/step3_room2_e6.png?2'" style="position: absolute; width: 85px; bottom: 160px; left: 180px " v-show="savedata.step03clean[4] == 1" />
                                    <img :src="'./img/step03/step3_room2_e3.png?2'" style="position: absolute; width: 85px; bottom: 130px; left: 280px " v-show="savedata.step03clean[5] == 1" />



                                    <img :src="'./img/step03/step3_room2_kaiga1.png?2'" style="position: absolute; width: 85px; top: 150px; left: 400px " @click.stop="rotation(1)" id="kaiga1" />
                                    <img :src="'./img/step03/step3_room2_kaiga2.png?2'" style="position: absolute; width: 85px; top: 150px; left: 500px " @click.stop="rotation(2)" id="kaiga2" />
                                    <img :src="'./img/step03/step3_room2_kaiga3.png?2'" style="position: absolute; width: 85px; top: 150px; left: 600px " @click.stop="rotation(3)" id="kaiga3" />


                                    <img :src="'./img/step03/step3_room2_book00.png?2'" style="position: absolute; width:180px; top: 280px; left: 450px " v-show="savedata.step03openkinko == 0" />
                                    <img :src="'./img/step03/step3_room2_book02.png?2'" style="position: absolute; width:180px; top: 280px; left: 450px " v-show="savedata.step03openkinko == 1 " @click.stop="getItem(1)" />
                                    <img :src="'./img/step03/step3_room2_book01.png?2'" style="position: absolute; width:180px; top: 280px; left: 450px " v-show="savedata.step03openkinko == 2   " />

                                </div>


                            </div>



                            <div class="room" v-show="step3room == 2">

                                <div style="">
                                    <img :src="'./img/step03/step3_book1_01.png?2'" style="position: absolute; width: 60px; bottom: 50px; left: 70px " @click.stop="getItem(5)" v-show=" !savedata.gatItem.includes(5) && !savedata.useItem.includes(5)" />
                                    <img :src="'./img/step03/step3_book1_02.png?2'" style="position: absolute; width: 60px; bottom: 115px; left: 200px " @click.stop="getItem(2)" v-show=" !savedata.gatItem.includes(2) && !savedata.useItem.includes(2)" />


                                    <img :src="'./img/step03/step3_room3_cushion.png?2'" style="position: absolute; width: 100px; bottom: 110px; left: 200px; z-index: 30" v-bind:class="[step3kusson == 0 ? '':'rotated'] " @click.stop="itemClick(2)" />
                                    <img :src="'./img/step03/step3_room3_shokubutu.png?2'" style="position: absolute; width: 160px; bottom: 50px; left: 40px;z-index:30" v-bind:class="[step3ueki == 0 ? '':'rotated'] " @click.stop="itemClick(1)" />

                                    <img :src="'./img/step03/step3_hondana.png?2'" style="position: absolute; width: 200px; bottom: 20px; right: 34px;z-index:30" />
                                    <!--<img :src="'./img/step03/step3_hondana_02 mihon.png?2'" style="position: absolute; width: 160px; bottom: 50px; left: 40px;z-index:30" v-bind:class="[step3ueki == 0 ? '':'rotated'] " @click.stop="itemClick(1)" />-->
                                    <!--<div style="position:absolute">-->

                                    <div style="position: absolute; width: 200px; height: 400px; z-index: 50; right: 40px; top: 100px" class="hondana" @click="setBook()">
                                        <div style="position: relative; height: 100%; width: 80%;margin-top: 186px;height:150px;text-align:left;margin-left:13%">
                                            <!--<div v-for="a in step3bookstand" :key="a" style="">-->
                                            <img v-for="a in step3bookstand" :key="a" :src="'./img/step03/step3_book_0' + a + '.png?2'" style="" class="book" @click.stop="getItem2(a)" />
                                            <!--</div>-->
                                            <!--<div v-for="step3bookstand">
                        <img :src="'./img/step03/step3_book_0 + '1'+ .png?2'" style="" v-show="savedata.useItem.includes(3)" id="setbook3" class="book" />
                    </div>-->
                                            <!--<img :src="'./img/step03/step3_book_0 + '1'+ .png?2'" style="" v-show="savedata.useItem.includes(3)" id="setbook3" class="book" />
                    <img :src="'./img/step03/step3_book_02.png?2'" style="" v-show="savedata.useItem.includes(2)" id="setbook2" class="book" />
                    <img :src="'./img/step03/step3_book_03.png?2'" style="" v-show="savedata.useItem.includes(6)" id="setbook6" class="book" />
                    <img :src="'./img/step03/step3_book_04.png?2'" style="" v-show="savedata.useItem.includes(4)" id="setbook4" class="book" />
                    <img :src="'./img/step03/step3_book_05.png?2'" style="" v-show="savedata.useItem.includes(5)" id="setbook5" class="book" />
                    <img :src="'./img/step03/step3_book_06.png?2'" style="" v-show="savedata.useItem.includes(1)" id="setbook1" class="book" />-->
                                        </div>

                                    </div>

                                    <!--{ item: "step3_book_01.png?2", keyno: 3, setumei: "本3", syurui:1 },
            { item: "step3_book_02.png?2", keyno: 2, setumei: "本2", syurui: 1 },
            { item: "step3_book_03.png?2", keyno: 6, setumei: "本6", syurui: 1},
            { item: "step3_book_04.png?2", keyno: 4, setumei: "本4", syurui: 1},
            { item: "step3_book_05.png?2", keyno: 5, setumei: "本5", syurui: 1 },
            { item: "step3_book_06.png?2", keyno: 1, setumei: "本1", syurui: 1},-->
                                </div>
                                <!--</div>-->
                            </div>


                            <div class="room" v-show="step3room == 3">

                                <div style="">


                                    <img :src="'./img/step03/step3_room4_camvas02.png?2'" style="position:absolute;width:180px;bottom:30px;right:100px" @click="getItem(7)" v-show="savedata.gatItem.includes(7) == false" />

                                    <img :src="'./img/step03/step3_room4_camvas.png?2'" style="position:absolute;width:180px;bottom:30px;right:100px" @click="canvas()" v-show="savedata.gatItem.includes(7) == true && savedata.gatItem.includes(10) == false" />


                                    <img :src="'./img/step03/step3_room4_camvas_02.png?2'" style="position:absolute;width:180px;bottom:30px;right:100px" @click="getItem(7)" v-show="savedata.gatItem.includes(10) == true" />
                                </div>
                                <div style="position:absolute;width:80px;height:100px; top:210px;left:100px;z-index:180;" @click="step03clear()">

                                </div>
                            </div>



                            <img :src="'./img/step03/triangleico_left.png?2'" class="arrow_l" style="" @click.stop="leftRoom()" />
                            <img :src="'./img/step03/triangleico_right.png?2'" class="arrow_r" style="" @click.stop="rightRoom()" />


                            <!--本棚-->
                            <!--<div v-show="step3Hondanazoom == 1" class="room" style="z-index:30">
        <img :src="'./img/common/close_icon.png?2'" style="width:60px;margin-right:20px;cursor:pointer;position:absolute;top:0px;right:10px" @click.stop="closeHondana()" />
        <img :src="'./img/step03/step3_hondana_zoom.png?2'" />
    </div>-->
                            <!--PC-->
                            <div class="room" style="width:750px;z-index:40;padding-top:40px;padding-bottom:50px;" v-show="step3dispc">

                                <div style="background-color:rgb(4 4 4 / 0.39);width:92%;position:relative">
                                    <div style="text-align:right">
                                        <img :src="'./img/common/close_icon.png?2'" style="width:60px;" @click.stop="openPC(false)" />

                                    </div>
                                    <div>
                                        <img :src="'./img/step03/step3_pc_01.png?2'" style="width:100%;" v-show="step3pc == 0" />

                                        <img :src="'./img/step03/step3_pc_02.png?2'" style="width:100%;" v-show="step3pc == 2" />
                                        <div v-show="step3pc == 3" style="position:relative">
                                            <img :src="'./img/step03/step3_pc_02.png?2'" style="width:100%;" />

                                            <div style="position:absolute;top:0;left:0;background-color:rgb(0 0 0 / 0.30);z-index:60">
                                                <img :src="'./img/common/seikai2.png?2'" style="width:40%;" /><br />
                                                <img :src="'./img/step03/step3_mirai-serihu07.png?2'" style="width:100%;" /><br />
                                                <img :src="'./img/step02/next.png?2'" style="width:30%;" @click.stop="setPCNext()" />
                                            </div>
                                        </div>
                                        <img :src="'./img/step03/step3_pc_03.png?2'" style="width:100%;" v-show="step3pc == 4" />
                                        <div v-show="step3pc == 5" style="position:relative">
                                            <img :src="'./img/step03/step3_pc_03.png?2'" style="width:100%;" />

                                            <div style="position:absolute;top:0;left:0;background-color:rgb(0 0 0 / 0.30);z-index:60">
                                                <img :src="'./img/common/seikai2.png?2'" style="width:40%;" /><br />
                                                <img :src="'./img/step03/step3_mirai-serihu08.png?2'" style="width:100%;" /><br />
                                                <img :src="'./img/step02/next.png?2'" style="width:30%;" @click.stop="setPCNext()" />
                                            </div>
                                        </div>
                                        <img :src="'./img/step03/step3_pc_04.png?2'" style="width:100%;" v-show="step3pc == 6" />
                                        <div v-show="step3pc == 7" style="position:relative">
                                            <img :src="'./img/step03/step3_pc_04.png?2'" style="width:100%;" />

                                            <div style="position:absolute;top:0;left:0;background-color:rgb(0 0 0 / 0.30);z-index:60">
                                                <img :src="'./img/common/seikai2.png?2'" style="width:40%;" /><br />
                                                <img :src="'./img/step03/step3_mirai-serihu09.png?2'" style="width:100%;" /><br />
                                                <img :src="'./img/step02/next.png?2'" style="width:30%;" @click.stop="setPCNext()" />
                                            </div>
                                        </div>

                                        <img :src="'./img/step03/step3_pc_05.png?2?1'" style="width:100%;" v-show="step3pc == 1" />

                                        <!--<img :src="'./img/step03/step3_pc_08.png?2'" style="width:100%;" v-show="step3pc == 5" />-->
                                        <img :src="'./img/step03/step3_pc_06.png?2'" style="width:100%;" v-show="step3pc == 8" />
                                        <img :src="'./img/step03/step3_pc_07.png?2'" style="width:100%;" v-show="step3pc == 9" />

                                        <!--<img :src="'./img/step03/step3_pc_05.png?2'" style="width:100%;" v-show="step3pc == 4" />
                <img :src="'./img/step03/step3_pc_06.png?2'" style="width:100%;" v-show="step3pc == 5" />
                <img :src="'./img/step03/step3_pc_07.png?2'" style="width:100%;" v-show="step3pc == 6" />
                <img :src="'./img/step03/step3_pc_08.png?2'" style="width:100%;" v-show="step3pc == 7" />-->

                                        <img :src="'./img/step03/step3_room2_ginga_atama.png?2'" style="width:100%;position:absolute;bottom:0px;z-index:50;left:0px" @click="step03atama()" />



                                        <!--<div style="position: absolute; width: 170px; height: 165px; top: 195px; left: 120px; " class="pcselect" v-show="step3pc == 0" @click="security(1,1)"></div>
                <div style="position: absolute; width: 170px; height: 165px; top: 195px; left: 400px;  " class="pcselect" v-show="step3pc == 0" @click="security(1,2)"></div>-->

                                        <div style="position: absolute; width: 250px; height: 205px; top: 205px; left: 85px; " class="pcselect" v-show="step3pc == 2" @click="security(1,1)"></div>
                                        <div style="position: absolute; width: 250px; height: 205px; top: 205px; left: 363px; " class="pcselect" v-show="step3pc == 2" @click="security(1,2)"></div>

                                        <div style="position: absolute; width: 180px; height: 165px; top: 240px; left: 55px; " class="pcselect" v-show="step3pc == 4" @click="security(2,1)"></div>
                                        <div style="position: absolute; width: 180px; height: 165px; top: 240px; left: 250px; " class="pcselect" v-show="step3pc == 4" @click="security(2,2)"></div>
                                        <div style="position: absolute; width: 180px; height: 165px; top: 240px; left: 455px; " class="pcselect" v-show="step3pc == 4" @click="security(2,3)"></div>

                                        <div style="position: absolute; width: 170px; height: 165px; top: 270px; left: 75px; " class="pcselect" v-show="step3pc == 6" @click="security(3,1)"></div>
                                        <div style="position: absolute; width: 170px; height: 165px; top: 270px; left: 255px; " class="pcselect" v-show="step3pc == 6" @click="security(3,2)"></div>
                                        <div style="position: absolute; width: 170px; height: 165px; top: 270px; left: 440px; " class="pcselect" v-show="step3pc == 6" @click="security(3,3)"></div>
                                        <div style="position: absolute; width: 170px; height: 165px; top: 90px; left: 440px; " class="pcselect" v-show="step3pc == 6" @click="security(3,4)"></div>





                                        <div style="position: absolute;  height: 120px; top: 200px; left: 150px;" class="pcselect" v-show="step3pc == 1">
                                            <form @submit.prevent="step03PcOK()">

                                                <div>
                                                    <input type="tel" ime-mode:inactive placeholder="" class="inputnum" id="num1" v-model="inputStep3pc1" maxlength="1" autocomplete="off" />
                                                    <input type="tel" ime-mode:inactive placeholder="" class="inputnum" id="num2" v-model="inputStep3pc2" maxlength="1" autocomplete="off" />
                                                    <input type="tel" ime-mode:inactive placeholder="" class="inputnum" id="num3" v-model="inputStep3pc3" maxlength="1" autocomplete="off" />
                                                    <input type="tel" ime-mode:inactive placeholder="" class="inputnum" id="num4" v-model="inputStep3pc4" maxlength="1" autocomplete="off" />
                                                </div>
                                            </form>
                                        </div>

                                        <img :src="'./img/step03/ok.png?2'" style="position: absolute; top: 335px; left: 250px;z-index:60 " class="" v-show="step3pc == 1" @click.stop="step03PcOK()" />

                                        <div class="">
                                            <!--<div class="sinenbox" style="position: absolute; left: 10px; width: 300px; bottom: 10px;" v-show="step3pc == 0 &&   savedata.step3sinen[0] == 1">
                        縦長の絵画は偽物
                    </div>-->
                                            <div class="sinenbox" style="position: absolute; left: 10px; width: 300px; bottom: 10px; " v-show="step3pc == 2 &&  savedata.step3sinen[0] == 1">
                                                縦長の絵画は偽物
                                            </div>
                                            <div class="sinenbox" style="position: absolute; left: 10px; width: 300px; bottom: 10px;" v-show="step3pc == 4 &&   savedata.step3sinen[1] == 1">
                                                「ごしょく」あるのは偽物
                                            </div>
                                            <div class="sinenbox" style="position: absolute; left: 10px; width: 300px; bottom: 10px; line-height: 1.5em;padding:20px;" v-show="step3pc == 6 &&   savedata.step3sinen[2] == 1">

                                                偽物は４つの作品の中です<br />
                                                一つ描かれています

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div id="clearroom2" style=" width:750px;z-index:40;padding-top:40px;padding-bottom:50px; position: relative;display:none " class=" fade">
                                <div class="">
                                    <img :src="'./img/step03/step3_room4_popup.png?2'" class=" " style="margin-top:500px" />
                                </div>
                                <img :src="'./img/haikei/scene_step3_haikei.png?2'" class="bgwaku2" style="" />

                            </div>


                                <!--PC-->
                                <div class="room" style="width: 750px; z-index: 60; padding-top: 40px; padding-bottom: 50px; " v-show="step03desk" @click.stop="stop()">

                                    <div style="width:92%;height:550px;">
                                        <div style="text-align:right">
                                            <img :src="'./img/common/close_icon.png?2'" style="width:60px;" @click.stop="step03desklockopen(false)" />

                                        </div>
                                        <div style="width: 100%; height:85%; background-image: url('./img/step03/hikidashi.png?2'); " v-show="step3Opendesk == 0">
                                            <div style="position: relative;  display: flex;width:80%;margin:0 auto; ">
                                                <div style="margin-top:100px">
                                                    <img :src="'./img/step03/dial/red_yajirushi_l.png?2'" style="width: 50px" />
                                                </div>

                                                <div style="margin-top:100px;background-color: black; height: 60px; padding: 5px; vertical-align: bottom; border-radius: 10px;">
                                                    <img :src="'./img/step03/dial/scene_step3_no' + step3lock[0] + '.png?2'" style="width: 60px" @click.stop="step03num(0)" />

                                                    <img :src="'./img/step03/dial/scene_step3_no' + step3lock[1] + '.png?2'" style="width: 60px" @click.stop="step03num(1)" />
                                                    <img :src="'./img/step03/dial/scene_step3_no' + step3lock[2] + '.png?2'" style="width: 60px" @click.stop="step03num(2)" />
                                                    <img :src="'./img/step03/dial/scene_step3_no' + step3lock[3] + '.png?2'" style="width: 60px" @click.stop="step03num(3)" />
                                                    <img :src="'./img/step03/dial/scene_step3_no' + step3lock[4] + '.png?2'" style="width: 60px" @click.stop="step03num(4)" />
                                                    <img :src="'./img/step03/dial/scene_step3_no' + step3lock[5] + '.png?2'" style="width: 60px" @click.stop="step03num(5)" />
                                                </div>
                                                <div style="margin-top: 100px;">
                                                    <img :src="'./img/step03/dial/red_yajirushi_r.png?2'" style="width: 50px " />
                                                </div>
                                            </div>


                                        </div>
                                        <div style="position: relative;" v-show="step3Opendesk == 1">
                                            <div style="">
                                                <img :src="'./img/step03/step3_room1_hikidasi.png?2'" style="width: 100%" @click.stop="getItem(8)" v-show="!savedata.gatItem.includes(8) " />
                                                <img :src="'./img/step03/step3_room1_hikidasi_2.png?2'" style="width: 100%" v-show="savedata.gatItem.includes(8)" />
                                            </div>

                                        </div>
                                        <div style="position: relative;" v-show="step3Opendesk == 2">
                                            <div style="width:100%;background-color:white">
                                                <img :src="'./img/step03/step3_canvas_nuno_popup.png?2'" style="width: 65%" />
                                            </div>

                                        </div>
                                        <div style="position: relative;" v-show="step3Opendesk == 3">
                                            <div style="width:100%;background-color:white">
                                                <img :src="'./img/step03/' + step03items[step3useItemKeyNo] +'.png?2'" style="width: 50%" />
                                            </div>

                                        </div>

                                    </div>

                                </div>
                                <!--PC-->
                                <div class="room" style="width: 750px; z-index: 60; padding-top: 40px; padding-bottom: 50px; " v-show="step03Item >= 1" @click.stop="stop()">

                                    <div style="width:92%;height:550px;">
                                        <div style="text-align:right">
                                            <img :src="'./img/common/close_icon.png?2'" style="width:60px;" @click.stop="step03Itemopen(0)" />

                                        </div>


                                        <div style="position: relative;" v-show="step03Item == 1">
                                            <div style="width:100%;background-color:white">
                                                <img :src="'./img/step03/step3_canvas_nuno_popup.png?2'" style="width: 65%" />
                                            </div>

                                        </div>
                                        <div style="position: relative;" v-show="step03Item == 2">
                                            <div style="width:100%;background-color:white">
                                                <img :src="'./img/step03/' + step03items[step3useItemKeyNo].item + ''" style="width: 50%" />
                                            </div>

                                        </div>

                                    </div>

                                </div>

                                <!--POPUP

        -->
                                <!--<div class="room" style="width: 750px; z-index: 60; padding-top: 40px; padding-bottom: 50px; " v-show="step03DispItem != -1 " @click.stop="stop()">
            <div style="background-color:rgb(4 4 4 / 0.39);width:92%;position:relative">
                <div style="text-align:right">
                    <img :src="'./img/common/close_icon.png?2'" style="width:60px;"  @click.stop="DispItem(-1)" />

                </div>

            </div>

        </div>-->

                                <div style="z-index:20;position:absolute;top:750px;left:50px;margin-top:90px;z-index:300" v-show="step3pc == 8">
                                    <img :src="'./img/step03/step3_mirai-serihu05.png?2'" style="width:90%;margin-right:50px" />

                                </div>
                                <div style="z-index:20;position:absolute;top:750px;left:50px;margin-top:90px;z-index:300" v-show="step3pcsinen == 0 && step3pc == 2">
                                    <img :src="'./img/step03/step3_mirai-serihu06.png?2'" style="width:90%;margin-right:50px" />

                                </div>
                                <div class="sinen" style="z-index:20;position:absolute;top:930px;left:50px;width:150px;margin-top:90px;z-index:300">
                                    <img :src="'./img/common/scene_step_b1.png?2'" class="sinenicon" v-show="step3sinenflg == 0 " @click.stop="Step3Sine(1)" />
                                    <img :src="'./img/common/scene_step_b2.png?2'" class="sinenicon" v-show="step3sinenflg == 1 " @click.stop="Step3Sine(0)" />
                                </div>


                                <div class="inputpostion2" style="left:90px;">
                                    <div style="display:flex;margin-top:10px">

                                        <div>

                                            <table class="itemtable">
                                                <tr>
                                                    <th colspan="5">
                                                        アイテム
                                                    </th>

                                                </tr>
                                                <tr>
                                                    <td :class="[step3useItem == 1? 'selectitem':'']">

                                                        <img :src="setItem(1)" v-show="setItem != ''" @click.stop="useItem(1)" class="getitemicon" id="step03item1" />

                                                    </td>
                                                    <td :class="[step3useItem == 2? 'selectitem':'']">
                                                        <img :src="setItem(2)" v-show="setItem != ''" @click.stop="useItem(2)" class="getitemicon" id="step03item2" />
                                                    </td>
                                                    <td :class="[step3useItem == 3? 'selectitem':'']">
                                                        <img :src="setItem(3)" v-show="setItem != ''" @click.stop="useItem(3)" class="getitemicon" id="step03item3" />
                                                    </td>
                                                    <td :class="[step3useItem == 4? 'selectitem':'']">

                                                        <img :src="setItem(4)" v-show="setItem != ''" @click.stop="useItem(4)" class="getitemicon" id="step03item4" />
                                                    </td>
                                                    <td :class="[step3useItem == 5? 'selectitem':'']">
                                                        <img :src="setItem(5)" v-show="setItem != ''" @click.stop="useItem(5)" class="getitemicon" id="step03item5" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td :class="[step3useItem == 6? 'selectitem':'']">
                                                        <img :src="setItem(6)" v-show="setItem != ''" @click.stop="useItem(6)" class="getitemicon" id="step03item6" />
                                                    </td>
                                                    <td :class="[step3useItem == 7? 'selectitem':'']">
                                                        <img :src="setItem(7)" v-show="setItem != ''" @click.stop="useItem(7)" class="getitemicon" id="step03item7" />
                                                    </td>
                                                    <td :class="[step3useItem == 8? 'selectitem':'']">
                                                        <img :src="setItem(8)" v-show="setItem != ''" @click.stop="useItem(8)" class="getitemicon" id="step03item8" />
                                                    </td>
                                                    <td :class="[step3useItem == 9? 'selectitem':'']">
                                                        <img :src="setItem(9)" v-show="setItem != ''" @click.stop="useItem(9)" class="getitemicon" id="step03item9" />
                                                    </td>
                                                    <td :class="[step3useItem == 10? 'selectitem':'']">
                                                        <img :src="setItem(10)" v-show="setItem != ''" @click.stop="useItem(10)" class="getitemicon" id="step03item10" />
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    <!--<div v-if="data[savedata.nowque].step == 4">

                </div>-->
                    <div v-if="data[savedata.nowque].step == 4">
                        <img :src="'./img/haikei/' + data[savedata.nowque].back + '.png?2'" class="" style="position: absolute; top: 50%; transform: translate(0,-50%);left:0px " />
                        <div>

                            <img :src="'./img/chara/mirai-01.png?2'" class="charamain_mirai" style="" v-show="step04error == false" />
                            <img :src="'./img/chara/nagisa-01.png?2'" class="charamain_nagisa" style="" v-show="step04error == true" />
                            <!--<img :src="'./img/chara/' + data[savedata.nowque].mainchara + '.png?2'" class="charamain" style="" v-show="data[savedata.nowque].mainchara != ''" />-->
                            <!--<img :src="'./img/chara/' + data[savedata.nowque].subchara  + '.png?2'" class="charasub" style="" v-show="data[savedata.nowque].subchara != ''" />-->

                        </div>

                        <img :src="'./img/haikei/scene_step' + data[savedata.nowque].step + '_haikei.png?2'" class="bgwaku" style="" />

                        <div class="textareasentaku" v-show="step04error == false">
                            <div class="sentakutitle">

                                {{step04Sentakusi[step04quesNo].text}}
                            </div>
                            <div v-show="step04quesNo != 4">
                                <div class="sentaku" @click.stop="sentaku(1)" :style="{ 'filter':[step04ans[step04quesNo][0]?  'brightness(40%)':'' ]}">
                                    {{step04Sentakusi[step04quesNo].q1}}
                                </div>
                                <div class="sentaku" @click.stop="sentaku(2)" :style="{ 'filter':[step04ans[step04quesNo][1]?  'brightness(40%)':'' ]}">
                                    {{step04Sentakusi[step04quesNo].q2}}
                                </div>
                                <div class="sentaku" @click.stop="sentaku(3)" :style="{ 'filter':[step04ans[step04quesNo][2]?  'brightness(40%)':'' ]}">
                                    {{step04Sentakusi[step04quesNo].q3}}
                                </div>
                                <div class="sentaku" @click.stop="sentaku(4)" :style="{ 'filter':[step04ans[step04quesNo][3]?  'brightness(40%)':'' ]}">
                                    {{step04Sentakusi[step04quesNo].q4}}
                                </div>
                            </div>
                            <div v-show="step04quesNo == 4">
                                <br />
                                <div class="inputarea">

                                    <form @submit.prevent="Step4Ansewer()">

                                        <input type="text" placeholder="" class="inputbox" v-model="inputStep4" maxlength="10" />
                                        <img :src="'./img/common/enter.png?2'" class="inputenter" @click.stop="Step4Ansewer()" />

                                    </form>

                                </div>
                            </div>
                        </div>

                        <div v-show="step04error" @click.stop="step04hide">
                            <div style="" class="textarea">
                                <div>
                                    <img :src="'./img/chara/' + data[savedata.nowque].name  + '.png?2'" class="nametag" v-show="data[savedata.nowque].name != ''" />
                                </div>
                                <span>お嬢様！もう１度考え直してみてください！</span>
                            </div>
                        </div>
                    </div>


                    <div v-if="data[savedata.nowque].step == 5">

                        <img :src="'./img/haikei/' + data[savedata.nowque].back + '.png?2'" class="" style="position: absolute; top: 50%; transform: translate(0,-50%);left:0px " />
                        <div>

                            <img :src="'./img/chara/narikawa-03.png?2'" class="charanari" v-show="savedata.step05win == false" />
                            <img :src="'./img/chara/narikawa-04.png?2'" class="charanari" v-show="savedata.step05win == true" />

                            <div style="position:absolute;top:45px;width:100%;color:black;background-color:rgb(255 255 255 / 0.51)">

                                <span style="font-size:0.8em">成川輪廻</span><br />
                                HP:<meter min="0" max="100" v-bind:value="Step05seikai" style="width:80%"></meter>

                            </div>



                            <img :src="'./img/step05/step5_canvas_02.png?2'" class="canvas" />
                            <img :src="'./img/haikei/scene_step' + data[savedata.nowque].step + '_haikei.png?2'" class="bgwaku" style="" />
                            <img :src="'./img/step05/step5_nagisatoginga.png?2'" class="kaiga" id="kaiganari" @click.stop="rotationstep05()" />


                            <img :src="'./img/haikei/scene_step' + data[savedata.nowque].step + '_haikei.png?2'" class="bgwaku" style="" v-show="savedata.step05win == true" />


                            <div class="syutudai" v-show="savedata.step05win == false">
                                <img :src="'./img/step05/' + step05queno + '.png?2'" class="step05quesimg zoom" id="quesimg" style="" />
                            </div>
                        </div>





                        <div class="step05kaitou" v-show="step5gameover != 1">
                            <span class="error2" v-show="step05error">間違っているようだ。</span>
                            <div class="inputarea">

                                <form @submit.prevent="Step5Ansewer()">

                                    <input type="text" placeholder="" class="inputbox" v-model="inputStep5" maxlength="10" />
                                    <img :src="'./img/common/enter.png?2'" class="inputenter" @click.stop="Step5Ansewer()" />

                                </form>

                            </div>
                        </div>
                    </div>
                </div>


                <div style="position:absolute;left:0;top:0;text-align:center;display:none" id="seikaistamp" class="stampanime">
                    <img :src="'./img/common/seikai2.png?2'" style="width:60%;margin-left:40px;margin-top:300px" />
                </div>
                <div style="position:absolute;left:0;top:0;text-align:center;display:none" id="seikaistamp2" class="stampanime">
                    <img :src="'./img/step05/step5_shougeki.png?2'" style="width:60%;margin-left:40px;margin-top:270px" />
                </div>

                <div style=" position: absolute; top: 0px; left: 0px; width: 100%; height: 100%; background-color: rgb(0 0 0 / 0.7); z-index: 300" v-show="step5gameover == 1">

                    <br /><br /><br /><br /><br /><br />
                    <div>
                        <img :src="'./img/common/game over.png?2'" style="width:70%;" />
                    </div>
                    <br /><br /><br />
                    <img :src="'./img/common/retry.png?2'" style="width:30%;" @click.stop="step05Rest(0);" />

                </div>
            </div>
            <!--<div class="step" v-show="data[savedata.nowque].step ==  1" id="step1">
            <div class="table">


                <img :src="'./imgren/step00/story.png?2'" class="title" />
                <br /> <br />
                <img :src="'./imgren/step00/text.png?2'" class="img" />
                <br />
                <img :src="'./imgren/step00/chara.png?2'" class="chara" />
                <br />
                <br />
                <img :src="'./imgren/common/next.png?2'" class="btn" @click.stop="goToStep(2,2)" />

                <br />
            </div>
        </div>-->



        </div>
        
        <div class="rightfix" v-if="gamestart == 2 && data[savedata.nowque].step >= 0"  v-bind:style="{ top : [ data[savedata.nowque].step == 2 && savedata.story == 3 ? '10px': '50px' ]}">
            <img :src="'./img/common/menu_tsuika3.png?2'" class="logbtn" style="" @click.stop="kaiwalognow" />
        </div>

        <div class="leftfix" v-if="(gamestart == 2  && data[savedata.nowque].step >= 0 ) || gamestart ==  3" v-bind:style="{ top : [ data[savedata.nowque].step == 2 && savedata.story == 3 ? '10px': '50px' ]}">

            <div style="text-align:left">
                <img :src="'./img/common/menu.png?2'" class="menuicon" @click.stop="showmenu()" v-show="menu == 0" />
                <img :src="'./img/common/menuclose.png?2'" class="menuicon" @click.stop="closemenu()" v-show="menu != 0" />
            </div>
            <div class="leftarea1" v-show="menu == 1">
                <div class="menudiv">
                    <img :src="'./img/common/menu1_parts/menu1_parts_top.png?2'" class="leftbtn"  @click.stop="showtop()"  />
                </div>
                    <div class="menudiv">
                        <!--<img :src="'./img/common/menu2_parts/menu2_parts_key.png?2'" class="leftbtn" v-show="savedata.clearstep >= 1" />-->
                        <img :src="'./img/common/menu1_parts/menu1_parts_key.png?2'" class="leftbtn" @click.stop="openmenu($event)" />
                        <div style="display:none;" class="menusecond">
                            <div v-for="a in savedata.checkkeyword" :key="a" style="line-height: normal; font-size: 0.8em">
                                <!--{{a}}-->

                                <img :src="'./img/common/keyword/ojyo_keyword' + a + '.png?2'" class="leftbtn2" @click.stop="dispkeyword(a)" />
                            </div>
                            <!--<div v-for="key in keyword" :key="key.no" style="line-height: normal; font-size: 0.8em">-->
                            <!--{{key.no}}-->
                            <!--<img :src="'./img/common/keyword/ojyo_keyword' + key.no + '.png?2'" class="leftbtn2" @click.stop="dispkeyword(key.no)" v-show="savedata.checkkeyword.includes(key.no)" />
                </div>-->
                            <!--<img :src="'./img/common/keyword/ojyo_keyword1.png?2'" class="leftbtn2" @click.stop="dispkeyword(1)" />
                <img :src="'./img/common/keyword/ojyo_keyword2.png?2'" class="leftbtn2" @click.stop="dispkeyword(2)" />
                <img :src="'./img/common/keyword/ojyo_keyword3.png?2'" class="leftbtn2" @click.stop="dispkeyword(3)" />
                <img :src="'./img/common/keyword/ojyo_keyword4.png?2'" class="leftbtn2" @click.stop="dispkeyword(4)" />
                <img :src="'./img/common/keyword/ojyo_keyword5.png?2'" class="leftbtn2" @click.stop="dispkeyword(5)" />
                <img :src="'./img/common/keyword/ojyo_keyword3.png?2'" class="leftbtn2" @click.stop="dispkeyword(3)" />-->
                        </div>
                    </div>
                    <div class="menudiv">
                        <img :src="'./img/common/menu2_parts/menu2_parts1.png?2'" class="leftbtn" v-show="savedata.clearstep < 0" />
                        <img :src="'./img/common/menu1_parts/menu1_parts1.png?2'" class="leftbtn" v-show="savedata.clearstep >= 0" @click.stop="openmenu($event)" />
                        <div style="display:none;" class="menusecond">

                            <div style="line-height: normal; font-size: 0.8em">
                                <a href="https://www.machihack.com/hints/ojosama-web-202406" target="_blank"><img :src="'./img/common/menu_tsuika1.png?2'" class="leftbtn2" /></a>
                            </div>
                            <div style="line-height: normal; font-size: 0.8em">
                                <img :src="'./img/common/menu_tsuika2.png?2'" class="leftbtn2" @click.stop="setStep(0)" />
                            </div>
                            <div style="line-height: normal; font-size: 0.8em">
                                <img :src="'./img/common/menu_tsuika3.png?2'" class="leftbtn2" @click.stop="kaiwalog(1)" />
                            </div>

                        </div>
                    </div>
                    <div class="menudiv">
                        <img :src="'./img/common/menu2_parts/menu2_parts2.png?2'" class="leftbtn" v-show="savedata.clearstep < 2" />
                        <img :src="'./img/common/menu1_parts/menu1_parts2.png?2'" class="leftbtn" v-show="savedata.clearstep >= 2" @click.stop="openmenu($event)" />
                        <div style="display:none;" class="menusecond">

                            <div style="line-height: normal; font-size: 0.8em">
                                <a href="https://www.machihack.com/hints/ojosama-web-202406" target="_blank"><img :src="'./img/common/menu_tsuika1.png?2'" class="leftbtn2" /></a>
                            </div>
                            <div style="line-height: normal; font-size: 0.8em">
                                <img :src="'./img/common/menu_tsuika2.png?2'" class="leftbtn2" @click.stop="setStep(2)" />
                            </div>
                            <div style="line-height: normal; font-size: 0.8em">
                                <img :src="'./img/common/menu_tsuika3.png?2'" class="leftbtn2" @click.stop="kaiwalog(2)" />
                            </div>
                        </div>
                    </div>
                    <div class="menudiv">
                        <img :src="'./img/common/menu2_parts/menu2_parts3.png?2'" class="leftbtn" v-show="savedata.clearstep < 3" />
                        <img :src="'./img/common/menu1_parts/menu1_parts3.png?2'" class="leftbtn" v-show="savedata.clearstep >= 3" @click.stop="openmenu($event)" />
                        <div style="display:none;" class="menusecond">
                            <div style="line-height: normal; font-size: 0.8em">

                                <a href="https://www.machihack.com/hints/ojosama-web-202406" target="_blank"><img :src="'./img/common/menu_tsuika1.png?2'" class="leftbtn2" /></a>
                            </div>

                            <div style="line-height: normal; font-size: 0.8em">

                                <img :src="'./img/common/menu_tsuika2.png?2'" class="leftbtn2" @click.stop="setStep(3)" />
                            </div>
                            <div style="line-height: normal; font-size: 0.8em">
                                <img :src="'./img/common/menu_tsuika3.png?2'" class="leftbtn2" @click.stop="kaiwalog(3)" />
                            </div>
                        </div>
                    </div>
                    <div class="menudiv">
                        <img :src="'./img/common/menu2_parts/menu2_parts4.png?2'" class="leftbtn" v-show="savedata.clearstep < 4" />
                        <img :src="'./img/common/menu1_parts/menu1_parts4.png?2'" class="leftbtn" v-show="savedata.clearstep >= 4" @click.stop="openmenu($event)" />
                        <div style="display:none;" class="menusecond">
                            <div style="line-height: normal; font-size: 0.8em">

                                <a href="https://www.machihack.com/hints/ojosama-web-202406" target="_blank"><img :src="'./img/common/menu_tsuika1.png?2'" class="leftbtn2" /></a>
                            </div>
                            <div style="line-height: normal; font-size: 0.8em">
                                <img :src="'./img/common/menu_tsuika2.png?2'" class="leftbtn2" @click.stop="setStep(4)" />
                            </div>
                            <div style="line-height: normal; font-size: 0.8em">
                                <img :src="'./img/common/menu_tsuika3.png?2'" class="leftbtn2" @click.stop="kaiwalog(4)" />
                            </div>
                        </div>
                    </div>
                    <div class="menudiv">
                        <img :src="'./img/common/menu2_parts/menu2_parts5.png?2'" class="leftbtn" v-show="savedata.clearstep < 5" />
                        <img :src="'./img/common/menu1_parts/menu1_parts5.png?2'" class="leftbtn" v-show="savedata.clearstep >= 5" @click.stop="openmenu($event)" />
                        <div style="display:none;" class="menusecond">

                            <div style="line-height: normal; font-size: 0.8em">
                                <a href="https://www.machihack.com/hints/ojosama-web-202406" target="_blank"><img :src="'./img/common/menu_tsuika1.png?2'" class="leftbtn2" /></a>
                            </div>
                            <div style="line-height: normal; font-size: 0.8em">
                                <img :src="'./img/common/menu_tsuika2.png?2'" class="leftbtn2" @click.stop="setStep(5)" />
                            </div>
                            <div style="line-height: normal; font-size: 0.8em">
                                <img :src="'./img/common/menu_tsuika3.png?2'" class="leftbtn2" @click.stop="kaiwalog(5)" />
                            </div>
                        </div>
                    </div>

                </div>
 

            </div>



        <!--v-show="isPopUpShow"-->
        <div class="popup-wrapper" v-show="isPopUpShow">
            <div class="popup-background" ></div>
            <div class="popup-body">

                <div style="width: 80%;">
                    <div style="text-align:right">
                        <img :src="'./img/common/close_icon.png?2'" style="width:60px;margin-right:20px;cursor:pointer" @click.stop="hidPopUp()" />
                    </div>
                    <div style=" " class="poptext">
                        <div>
                            <span style="" class="keytitle">
                                {{keyword[selectkey].key}}
                            </span>
                            <div v-show="keyword[selectkey].img != '' " style="width:100%;text-align:center;">
                                <img :src="'./img/common/keyimg/' +  keyword[selectkey].img + '.png?2'" class="" style="width:50%;text-align:center" />
                            </div>
                            <div style="line-height:1.5em">
                                <span v-html="keyword[selectkey].text"></span>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--v-show="isPopUpShow"-->
        <div class="popup-wrapper" v-show="isPopUpShowKami">
            <div class="popup-background" @click.stop="hidPopUpKami()"></div>
            <div class="popup-body">

                <div style="width: 80%;">
                    <div style="text-align:right">
                        <img :src="'./img/common/close_icon.png?2'" style="width:60px;margin-right:20px;cursor:pointer" @click.stop="hidPopUpKami()" />
                    </div>
                    <div style=" " class="poptext">
                        <div>
                            <img :src="'./img/step01/popup message_mihon.png?2'" /><br />
                            <img :src="'./img/step01/step1_kami_01.png?2'" v-if="step1kami == 1" />
                            <img :src="'./img/step01/step1_kami_02.png?2'" v-if="step1kami == 2" />
                            <img :src="'./img/step01/step1_kami_03.png?2'" v-if="step1kami == 3" />
                            <img :src="'./img/step01/step1_kami_04.png?2'" v-if="step1kami == 4" />
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!--データベース-->
        <div class="popup-wrapper" v-show="isPopUpShowDataBase">
            <div class="popup-background"></div>
            <div class="popup-body">

                <div style="width: 80%;">
                    <div style="text-align:right">
                        <img :src="'./img/common/close_icon.png?2'" style="width:60px;margin-right:20px;cursor:pointer" @click.stop="hidisPopUpDataBase()" v-show="keywordDB == true" />
                    </div>
                    <div style=" " class="poptext">
                        <span style="" class="keytitle">データベース</span>
                        <br />
                        美術館のデータベース
                        調べたいことがあれば、実際に検索することができる

                        <div v-show="keywordDB == false">
                            <br />
                            <span class="red" v-show="data[savedata.nowque].step == 2">チュートリアル「筆塚石生」と検索してみよう</span>
                            <span class="red" v-show="data[savedata.nowque].step == 4">「成川輪廻」を検索しよう</span>
                        </div>
                    </div>
                    <br />
                    <div style="background-image: url('./img/common/bijyutsu.png?2');height:310px;width:600px;background-size:100% 100%;position:relative;padding-top:90px">
                        <!--<img :src="'./img/common/bijyutsu.png?2'" />-->

                        <div>
                            <div style="width:80%;">
                                <img :src="'./img/common/database2.png?2'" />
                                <br />
                                <form @submit.prevent="DBSerach()" style="margin-top:10px">

                                    <input type="text" placeholder="" class="inputboxserch" v-model="inputDB" maxlength="10" style="background-color:white;border-radius:10px" />
                                    <img :src="'./img/common/serach.png?2'" class="inputserch" @click.stop="DBSerach()" />

                                </form>
                            </div>
                            <div class="serchreasulst">
                                検索結果：{{serchcount}}件
                                <table v-show="serchcount != 0 ">
                                    <tr>
                                        <td style="width:20%">
                                            {{serchtitle}}
                                        </td>
                                        <td style="width:80%">
                                            {{serchresult}}
                                        </td>
                                    </tr>
                                </table>


                            </div>
                        </div>
                    </div>
                    <div v-show="keywordDB == false && data[savedata.nowque].step == 2 && serchtitle == '筆塚石生'">
                        <img :src="'./img/common/next.png'" style="width:30%" @click.stop="dbNext()" />
                    </div>
                    <div v-show="keywordDB == false && data[savedata.nowque].step == 4 && serchtitle == '成川輪廻' ">
                        <img :src="'./img/common/next.png'" style="width:30%" @click.stop="dbNext()" />
                    </div>
                </div>
            </div>
        </div>


        <!--会話ログ-->
        <div class="popup-wrapper" v-show="isPopUpShowLog">
            <div class="popup-background"></div>
            <div class="popup-body">
                <div>
                    <div style="width:90%;text-align:right">
                        <img :src="'./img/common/close_icon.png?2'" style="width:60px;cursor:pointer" @click.stop="hidisPopLog()" />

                    </div>
                    <div style="height:70vh;overflow-y:auto">

                        <div v-for="index in savedata.nowque + 1" :key="index">


                            <div style="display:flex" v-show="logstep.includes(data[index - 1].step)">
                                <div style="width:150px;">
                                    <img :src="'./img/chara/icon_' + data[index - 1].mainchara + '.png?2'" style="width:150px;" v-show="data[index - 1].mainchara != ''" />
                                </div>
                                <div :class="[data[index - 1].name , 'log']" style="">
                                    <div v-show="data[index - 1].name != '' ">
                                        <img :src="'./img/chara/' + data[index - 1].name  + '.png?2'" class="nametaglog" v-show="data[index - 1].name != ''" />
                                    </div>
                                    <div v-html="data[index - 1].text">

                                    </div>
                                </div>
                                <br />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <!--ポップアップ-->
        <!--<div class="popup-wrapper" v-show="isPopUpShow">
    <div class="popup-background" @click.stop="hidPopUp()"></div>
    <div class="popup-body">-->
        <!--ポップアップ本体-->
        <!--<div class="" style=" width: 90vw; max-width: 950px; ">
                <div style="width:100%;text-align:right">
                    <img :src="'./img/close.png?2'" @click.stop="hidPopUp()" style="cursor:pointer" />
                </div>
                <div>

                </div>

            </div>
        </div>
    </div>


    <div class="sp-only">
        <div class="popup-wrapper2" v-show="isPopUpShow2">
            <div class="popup-background" @click.stop="hidPopUp2()"></div>
            <div class="popup-body2">-->
        <!--ポップアップ本体-->
        <!--<div class="" style=" width: 100%;padding-bottom:20%; ">
                    <div style="width:100%;text-align:right">
                        <img :src="'./img/close.png?2'" @click.stop="hidPopUp2()" style="cursor:pointer" />
                    </div>
                    <div style="height:100%;overflow-y:scroll"></div>
                    </div>
                </div>
        </div>


    </div>-->
    </div>

</template>

<script>

    document.body.addEventListener("click", drop, false);

    import $ from 'jquery'
    import jQuery from 'jquery'
    import 'jquery-ui';
   import 'touch-punch';

    //ステップクリア状態
    const localstorageKey = "ozyousamatantei1"
    const localstorageKeyNowQue = "ozyousamatantei_nowque"
    const localstorageKeyMaxQue = "ozyousamatantei_maxque"

    const localstorageKeyClearStep = "ozyousamatantei_clearstep"

    const localstorageKeyGameStatus = "ozyousamatantei_gamestatus"
    var timer = null;
    var pageX = 0;
    var pageY = 0;
    //var rousoku1 = false;s
    //var rousoku2 = false;
    //var rousoku3 = false;
    //var rousoku4 = false;

    export default {
        name: 'App',

        mounted: function () {
            this.gamestart = -1;
            this.targetStep();



        },
        data() {
            return {
                chuiflg: 0,
                camflg: 0,
                camflg1: 0,
                gamestart: -1,
                menu: 0,
                keyselect: 0,

                step1kami: 0,

                enbale: false,
              

                savedata: {
                    nowque: 0,
                    maxque: 0,
                    clearstep: 0,

                    story:1,
                    nowstep: 0,

                    step1flg:0,
                    step1dispmode: 0,
                    step1sinenflg: 0,
                   

                    step01kami: ["00", "00", "00", "00"],

                    //Step03
                    gatItem: [],
                    useItem: [],
                    step03clean:[0,0,0,0,0,0],
                    step03deg: [0, 0, 0],
                    step03openkinko: 0,
                    //step03getbook5: false,
                    //step03getbook2:false,

                    //step02DB: 0,
                    //step04DB: 0,

                    step1sinen: [
                        [0, 0, 0, 0, 0],
                        [0, 0, 0, 0, 0],
                        [0, 0, 0, 0, 0],
                        [0, 0, 0, 0, 0],

                    ],
                    step3sinen: [
                        [0, 0, 0, 0, 0],

                    ],

                    clearqeus: [
                        [0, 0, 0, 0,0],
                    ],

                    checkkeyword:[1,2,3],


                    step05deg: 0,
                    step05win:false,

                    endfl: 0,
                    
                    //answercheck: [
                    //    [false, false, false, false, false, false],
                    //    [false, false, false, false, false, false],
                    //    [false, false, false, false, false, false],
                    //    [false, false, false, false, false, false],
                    //    [false, false, false, false, false, false],
                    //    [false, false, false, false, false, false],
                    //    [false, false, false, false, false, false],
                    //    [false, false, false, false, false, false],
                    //],


                },

                step1 :0,

                hint1: false,
                error1: false,
                tea1: false,
                seikai1: false,


                keywordDB: false,

                step2: 0,
                hint2: false,
                error2: false,
                tea2: false,
                seikai2: false,

                //SteopCommon=======
                selectkey: 0,
                logstep:[],

                //Step03================
                step3room: 0,
                step3ueki: 0,
                step3kusson: 0,
                step3useItem: 0,
                step3useItemKeyNo: 0,
                step3Hondanazoom: 0,
                step3lock: [0, 0, 0, 0, 0, 0],
                step3pclock: [0, 0, 0, 0],

                step3bookstand:[],
                step03desk: false,
                step03Item:0,
                step03DispItem:0,
                inputStep3pc1:"",
                inputStep3pc2: "",
                inputStep3pc3: "",
                inputStep3pc4: "",

                //Step04====================
                step04quesNo: 0,
                step04ans: [
                    [false, false, false, false],
                    [false, false, false, false],
                    [false, false, false, false],
                    [false, false, false, false],
                    [false, false, false, false],
                ],

                step04error: false,
                inputStep4: "",


                //Step05===
                step05queno: 1,
                Step05seikai: 100,
                inputStep5:"",

                step05error:false,


                popupflg: 0,


                isPopUpShow: false,
                isPopUpShow2: false,

                isPopUpShowKami: false,
                isPopUpShowDataBase: false,
                isPopUpShowLog:false,


                inputstart: "",
                rensyustart: "",
                inputStep1: "",

                inputDB: "",
                serchcount: 0,
                serchtitle: "",
                serchresult: "",

                step02flg: 0,
                step2play: 0,
                step2gameover: 0,
                step2gameover2: 0,
                step2clear:0,

                step3sinenflg: 0,
                step3pc: 1,
                step3pcsinen: 0,
                step3dispc: false,
                step3Opendesk: 0,
                step5gameover: 0,



                inputanswer: [
                    ["", "", ""],
                    ["", "", ""],
                    ["", "", ""],
                    ["", "", ""],
                    ["", "", ""],
                    ["", "", ""],
                    ["", "", ""],
                    ["", "", ""],
                    ["", "", ""],
                    ["", "", ""],
                    ["", "", ""],
                    ["", "", ""],
                ],
                answers: [
                    ["", ""],
                    ["658", "6,映画鑑賞,エイガカンショウ", "センタク,洗濯", "40", "ダンサー", "10,十,面白イ人"],
                    ["748", "D8"],
                    ["231", "", ""],
                    ["148", "カイヌシ,飼イ主"],
                    ["956", "カワイイ,可愛イ", ""],
                    ["248", "", ""],
                   
                ],

       /*         itempos: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0,0],*/
            /*    seikaipos: [2, 0, 0, 0, 3, 1, 0, 0, 0, 5, 4],*/

                db: [

                    { key: '筆塚石生', event: '', text: 'ふでづか・いそう。江須派エリアにアトリエを持つ日本画の巨匠。アトリエで絵画教室を行うこともある。住所：○○区××・・・' },

                    {key: '成川輪廻', event:'', text: 'なりかわ・りんね。筆塚石生アトリエ絵画教室の展示会で「大好きな団子」というタイトルの絵が掲示された経験がある。' },

                ],

                keyword:
                    [
                        { no: 1, key: 'ミライ', img: 'mirai-prf', event:'', text: '大手不動産企業の会長の娘。典型的なお嬢様。右手にエスパー能力が宿っており、その能力を活かして探偵業を営んでいる。' },
                        { no: 2, key: '渚', img: 'nagisa-prf', event:'', text: 'ミライに忠実に仕えるメイド。ミライから可愛がられており仲が良い。ハッキング等機械操作が得意。' },
                        { no: 3, key: '銀河', img: 'ginga-prf', event:'', text: 'ミライを真摯にお守りするイケメン執事。柔道や極真空手に長けている。本物を見抜く力がある。' },
                        { no: 4, key: '濱野将樹', img: 'masaki-prf', event:'', text: '大手不動産企業の会長。未来視能力の持ち主。卓越した判断力と行動力で、一代でその地位を築き上げた。' },
                        { no: 5, key: 'ダイヤの指輪', img: '', event:'', text: 'ミライが父から『今日はこれを一日身に着けていなさい』という手紙と共に譲り受けた指輪。ピカピカに磨き上げられており、光を反射する。' },
                        { no: 6, key: '江須派美術館', img: '', event:'', text: 'ミライの暮らす街にある美術館。珍しい展示会をよく開催しており、館内でデッサンが出来る場所としても有名。' },
                        { no: 7, key: '筆塚石生', img: 'fudetsuka-prf', event:'', text: '巨匠。江須派美術館で15年ぶりの最新作の絵画の展示会を行う予定だったが、何か事件に巻き込まれたようだ。' },
                        { no: 8, key: 'アトリエ', img: 'room2', event:'', text: '巨匠・筆塚石生のアトリエ。この街の近くにあり、筆塚がさまざまな作品を描いてきた拠点。' },
                        { no: 9, key: 'データベース', img: '', event:'', text: '' },
                        { no: 10, key: 'スプリンクラー', img: '', event:'', text: '美術館内でセキュリティシステムが発動するとスプリンクラーの水が巻かれる。館内でデッサンを行っていた人は、絵画が描けなくなったようだ。' },
                        { no: 11, key: 'エンジンルーム', img: '', event:'', text: '何者かが絵画を無理やり押し込み、エンジンルームに不具合が起こっている。中に入っている絵画には、美術館の館長らしき人物が描かれていた。' },
                        { no: 12, key: '成川輪廻', img: '', event:'', text: 'データベースで検索すると、何か情報が得られるかもしれない。' },
                        { no: 13, key: '甘味処', img: '', event:'', text: '江須派美術館の近くにある甘味処。みたらし団子が有名。' },
                        { no: 14, key: '浄蓮脚', img: '', event:'', text: '甘味処で出会った謎の人物。名前の通り甘味処の常連客。とある願いがあるようで、その願いを叶えるためのアイテムを欲しがっている。' },
                        { no: 15, key: '切望の達磨', img: 'setubou_daruma', text:'その達磨に書いた願いは“達磨が倒れなければ”絶対に叶うらしい<br>反対に倒れてしまうと逆の願いが叶ってしまう。' },
                        { no: 16, key: '街泊神社', img: '', event:'', text: 'アトリエにあった絵画に描かれていたこけしを売っている神社。この街の近くにあるようで、さまざまな屋台が出ている場所としても知られている。' },
                        { no: 17, key: '執念の筆', img: '', event: '', text: '描いた物を絵画の中に閉じ込める能力を持つ筆。この筆について詳しく知っている人物の影があるようだが――' },

                    ],

                data:
                    [

                        { step: -1, back: 'tutorial_01', mainchara: '', mainchara2: '', subchara: '', name: '', keyword: '', event: '', text: '' },
                        { step: -1, back: 'tutorial_02', mainchara: '', mainchara2: '', subchara: '', name: '', keyword: '', event: '', text: '' },
                        { step: -1, back: 'tutorial_03', mainchara: '', mainchara2: '', subchara: '', name: '', keyword: '', event: '', text: '' },
                        { step: -1, back: 'tutorial_04', mainchara: '', mainchara2: '', subchara: '', name: '', keyword: '', event: '', text: '' },


                        { step: 0, back: 'back-01', mainchara: 'mirai-01', mainchara2:'', subchara: '', name: 'name_mirai', keyword: '', event:'', text: '暇！！やることがないわ！！雨が続いてばかりだし・・・<br>何か室内で事件でも起きないかしら！' },
                        { step: 0, back: 'back-01', mainchara: 'nagisa-03', mainchara2:'', subchara: 'mirai-01', name: 'name_nagisa', keyword: '', event:'', text: 'また物騒なこと言わないでください、お嬢様！' },
                        { step: 0, back: 'back-01', mainchara: 'nagisa-02', mainchara2:'', subchara: '', name: 'name_nagisa', keyword: '', event:'', text: '最近は雨の日が多いですが、今日は珍しく晴れてるんです！<br>健康のためにもお散歩に行って、お団子でも食べに行きましょう！' },
                        { step: 0, back: 'back-01', mainchara: '', mainchara2:'', subchara: '', name: '', keyword: '', event:'', text: '<span class="blue">～ピンポーン～</span>'},
                        { step: 0, back: 'back-01', mainchara: '', mainchara2:'', subchara: '', name: '', keyword: '', event:'', text: 'お届け物でーす' },
                        { step: 0, back: 'back-01', mainchara: 'mirai-01', mainchara2:'', subchara: '', name: 'name_mirai', keyword: '', event:'', text: 'あら、何か届いたようね。銀河、受け取ってきて頂戴' },
                        { step: 0, back: 'back-01', mainchara: 'ginga-01', mainchara2:'', subchara: 'mirai-01', name: 'name_ginga', keyword: '', event:'', text: 'かしこまりました' },
                        { step: 0, back: 'back-01', mainchara: '', mainchara2:'', subchara: '', name: '', keyword: '', event:'', text: '<span class="blue">～数分後～</span>'},
                        { step: 0, back: 'back-01', mainchara: 'ginga-01', mainchara2: '', subchara: 'mirai-01', name: 'name_ginga', keyword: '4', event: '', text: 'どうやら旦那様（ミライの父・<span class="keyword"  id="keyword" >濱野将樹</span>）からのようです' },
                        { step: 0, back: 'back-01', mainchara: 'mirai-01', mainchara2:'', subchara: 'ginga-01', name: 'name_mirai', keyword: '', event:'', text: '何か事件解決の依頼かしら？' },
                        { step: 0, back: 'back-01', mainchara: 'ginga-01', mainchara2:'', subchara: 'mirai-01', name: 'name_ginga', keyword: '', event:'', text: 'いえ。違うようです。小さい封筒のようですね' },
                        { step: 0, back: 'back-01', mainchara: 'mirai-02', mainchara2:'', subchara: 'ginga-01', name: 'name_mirai', keyword: '', event:'', text: '開けてみましょう！' },
                        { step: 0, back: 'back-01', mainchara: '', mainchara2:'', subchara: '', name: '', keyword: '', event:'', text: '<span class="blue">カサカサ、コロン（封筒から物が出る）</span>'},
                        { step: 0, back: 'back-01', mainchara: 'nagisa-02', mainchara2: '', subchara: '', name: 'name_nagisa', keyword: '5', event: '', text: 'きれいな<span class="keyword"  id="keyword" >ダイヤの指輪</span>ですー！'},
                        { step: 0, back: 'back-01', mainchara: 'mirai-01', mainchara2:'', subchara: 'nagisa-02', name: 'name_mirai', keyword: '', event:'', text: 'ダイヤがついているわね。手紙も同封されているわ。<br>ナニナニ・・・' },
                        { step: 0, back: 'back-01', mainchara: '', mainchara2:'', subchara: '', name: 'name_tegami', keyword: '', event:'', text: '『ミライよ。今日はこれを一日身に着けていなさい』' },
                        { step: 0, back: 'back-01', mainchara: 'nagisa-02', mainchara2:'', subchara: 'mirai-01', name: 'name_nagisa', keyword: '', event:'', text: 'ミライお嬢様似合ってます！素敵です！' },
                        { step: 0, back: 'back-01', mainchara: 'mirai-03', mainchara2:'', subchara: 'nagisa-02', name: 'name_mirai', keyword: '', event:'', text: '何か事件の依頼だと思ったのにがっかりだわ・・・' },
                        { step: 0, back: 'back-01', mainchara: '', mainchara2: '', subchara: '', name: 'name_radio', keyword: '6,7', event: '', text: '<span class="blue">ニュースです。<span class="keyword"  id="keyword1" >江須派美術館</span>で今日から行われる予定だった巨匠「<span class="keyword"  id="keyword2" >筆塚石生</span>（ふでづか いそう）」による15年ぶりの最新作の絵画の展示が急遽中止となりました</span>'},
                        { step: 0, back: 'back-01', mainchara: '', mainchara2:'', subchara: '', name: 'name_radio', keyword: '', event:'', text: '<span class="blue">詳細は不明ですが、美術館によると展示会の再開の目処はたっていないとのことです。～～～～～</span>'},
                        { step: 0, back: 'back-01', mainchara: 'mirai-02', mainchara2:'', subchara: '', name: 'name_mirai', keyword: '', event:'', text: 'あら！事件の香りがしますわね！<br>ここからすぐのところにあるみたいだし、何より暇だし、向かいましょう！' },
                        { step: 0, back: 'back-01', mainchara: '', mainchara2: '', subchara: '', name: 'name_radio', keyword: '', event: '', text: '<span class="blue">次のニュースです。各地で謎の失踪事件が相次いでおり、捜査関係者は・・・</span>'},
                        { step: 0, back: 'back-01', mainchara: '', mainchara2:'', subchara: '', name: '', keyword: '', event:'', text: 'ミライ一行はラジオを流しっぱなしで事務所を出ていった' },

                        { step: 0, back: 'back-02', mainchara: '', mainchara2: '', subchara: '', name: '', keyword: '', event: '', text: '〜美術館前〜' },
                        { step: 0, back: 'back-02', mainchara: 'nagisa-01', mainchara2:'', subchara: '', name: 'name_nagisa', keyword: '', event:'', text: 'なにやら慌ただしい雰囲気ですね' },
                        { step: 0, back: 'back-02', mainchara: 'ginga-01', mainchara2: '', subchara: 'nagisa-01', name: 'name_ginga', keyword: '', event: '', text: 'いきなり首を突っ込むと警戒されかねませんね・・・' },
                        { step: 0, back: 'back-02', mainchara: 'mirai-01', mainchara2:'', subchara: 'ginga-01', name: 'name_mirai', keyword: '', event:'', text: 'そうね、少しこの辺りの思念を読み取って一旦調査してから伺いましょうか' },
                        { step: 0, back: 'back-02', mainchara: '', mainchara2:'', subchara: '', name: '', keyword: '', event:'', text: 'ミライは右手で美術館の周りの物の思念を読み取った' },

                        { step: 1, back: 'back-02', mainchara: 'ginga-01', mainchara2:'', subchara: '', name: 'name_ginga', keyword: '', event:'', text: 'なるほど。筆塚石生と公開予定だった最新作の絵画が<br>同時に姿をくらましてしまったと・・・' },
                        { step: 1, back: 'back-02', mainchara: 'mirai-01', mainchara2:'', subchara: 'ginga-01', name: 'name_mirai', keyword: '', event:'', text: 'ちょっとそこのあなた！' },
                        { step: 1, back: 'back-02', mainchara: '', mainchara2:'', subchara: '', name: '', keyword: '', event:'', text: 'ミライは突然美術館前にいる男性に向かって声をかけた' },
                        { step: 1, back: 'back-02', mainchara: 'kantyou-01', mainchara2:'', subchara: '', name: 'name_kantyou', keyword: '', event:'', text: '！？' },
                        { step: 1, back: 'back-02', mainchara: 'mirai-01', mainchara2:'', subchara: 'kantyou-01', name: 'name_mirai', keyword: '', event:'', text: 'そう、あなたのことよ。今すごく困ってるのではなくて？' },
                        { step: 1, back: 'back-02', mainchara: 'mirai-02', mainchara2: '', subchara: 'kantyou-01', name: 'name_mirai', keyword: '', event: '', text: '失踪してしまった筆塚（なんでしたっけ・・・）イルノと絵画を私なら見つけられますわ！' },
                        { step: 1, back: 'back-02', mainchara: 'kantyou-01', mainchara2:'', subchara: 'mirai-02', name: 'name_kantyou', keyword: '', event:'', text: 'なぜそのことを！？<br>絶対にこの情報は漏れていないはずなのに・・・' },
                        { step: 1, back: 'back-02', mainchara: 'mirai-02', mainchara2:'', subchara: 'kantyou-01', name: 'name_mirai', keyword: '', event:'', text: '私はいくつもの大事件を解決した名探偵。<br>あなたが困っていることくらいすぐにわかりますわ！' },
                        { step: 1, back: 'back-02', mainchara: 'nagisa-03', mainchara2:'', subchara: 'mirai-02', name: 'name_nagisa', keyword: '', event:'', text: '（そんなに解決してないと思います・・・！）' },
                        { step: 1, back: 'back-02', mainchara: 'kantyou-01', mainchara2:'', subchara: 'nagisa-03', name: 'name_kantyou', keyword: '', event:'', text: 'なんでこんなところに探偵が・・・<br>とはいえ、困っていたのも事実だし・・・' },
                        { step: 1, back: 'back-02', mainchara: 'mirai-01', mainchara2:'', subchara: 'kantyou-01', name: 'name_mirai', keyword: '', event:'', text: 'もしあなたがどうしてもというなら、その巨匠と絵画。<br>探してあげなくもないわよ？' },
                        { step: 1, back: 'back-02', mainchara: 'kantyou-01', mainchara2:'', subchara: 'mirai-01', name: 'name_kantyou', keyword: '', event:'', text: 'ぐぬぬ・・・頼れる相手もいないと思っていたところではある・・・<br>何より失踪の事実を知られているのなら・・・' },
                        { step: 1, back: 'back-02', mainchara: 'kantyou-01', mainchara2:'', subchara: 'mirai-01', name: 'name_kantyou', keyword: '', event:'', text: '仕方がない、筆塚石生と絵画の捜索を頼むしかあるまい・・・' },
                        { step: 1, back: 'back-02', mainchara: 'mirai-02', mainchara2:'', subchara: 'kantyou-01', name: 'name_mirai', keyword: '', event:'', text: '承知しましたわ！絶対この事件解決してみせますわ！' },
                        { step: 1, back: 'back-02', mainchara: 'nagisa-02', mainchara2:'', subchara: 'mirai-02', name: 'name_nagisa', keyword: '', event:'', text: 'さすがお嬢様！有無を言わさぬ畳かけで依頼を一つGETです！' },
                        { step: 1, back: 'back-02', mainchara: 'mirai-01', mainchara2:'', subchara: 'nagisa-02', name: 'name_mirai', keyword: '', event:'', text: 'では早速思念を読み取って調査していきましょう' },

                      
                        { step: 2, back: 'back-03', mainchara: 'mirai-02', mainchara2:'', subchara: '', name: 'name_mirai', keyword: '8', event:'', text: 'わかったわ！失踪の手がかりは<span class="keyword"  id="keyword" >アトリエ</span>にあるようだわ！<br>早速そこへ向かいましょう！'},
                        { step: 2, back: 'back-03', mainchara: 'ginga-01', mainchara2:'', subchara: 'mirai-02', name: 'name_ginga', keyword: '', event:'', text: 'ってお嬢様。筆塚石生のアトリエはどこにあるのですか？' },
                        { step: 2, back: 'back-03', mainchara: 'mirai-01', mainchara2:'', subchara: 'ginga-01', name: 'name_mirai', keyword: '', event:'', text: 'そっそれは・・・思念をさらに深くまで読み取ればきっと・・・' },
                        { step: 2, back: 'back-03', mainchara: '', mainchara2:'', subchara: '', name: '', keyword: '', event:'', text: '<span class="blue">ｶﾀｶﾀｶﾀｶﾀｶﾀ、ｶﾀｯ!!!</span>'},
                        { step: 2, back: 'back-03', mainchara: 'mirai-01', mainchara2:'', subchara: '', name: 'name_mirai', keyword: '', event:'', text: '渚、何かパソコンには――' },
                        { step: 2, back: 'back-03', mainchara: 'nagisa-02', mainchara2:'', subchara: 'mirai-01', name: 'name_nagisa', keyword: '9', event:'', text: 'すごいです！この美術館！ここに展示する人の<span class="keyword"  id="keyword" >データベース</span>が<br>事細かに保管されています！'},
                        { step: 2, back: 'back-03', mainchara: 'mirai-02', mainchara2: '', subchara: 'nagisa-02', name: 'name_mirai', keyword: '', event: '', text: 'でかしたわ！渚！その情報から筆塚・・・えーっと・・・イクヨのアトリエを特定しましょう！' },
                        { step: 2, back: 'back-03', mainchara: 'kantyou-01', mainchara2:'', subchara: 'mirai-02', name: 'name_kantyou', keyword: '', event:'', text: 'って！勝手にいろいろしてもらっては困ります（怒）<br>大事な情報がたくさん保管されているのですから' },
                        { step: 2, back: 'back-03', mainchara: 'mirai-03', mainchara2:'', subchara: 'kantyou-01', name: 'name_mirai', keyword: '', event:'', text: 'ある程度はいいじゃない！探偵には調査が必要なのよ！' },
                        { step: 2, back: 'back-03', mainchara: 'kantyou-01', mainchara2:'', subchara: 'mirai-03', name: 'name_kantyou', keyword: '', event:'', text: 'まあ、それもそうですが・・・じゃあ必要最低限にしてくださいよ！<br>もちろん悪用も禁止です' },
                        { step: 2, back: 'back-03', mainchara: '', mainchara2:'', subchara: '', name: '', keyword: '', event:'DB', text: '美術館のデータベース検索機能が解禁されました' },



                        { step: 2, back: 'back-03', mainchara: 'nagisa-02', mainchara2:'', subchara: '', name: 'name_nagisa', keyword: '', event:'', text: '筆塚石生さんのアトリエはここから少し離れたところにあるみたいですね' },
                        { step: 2, back: 'back-03', mainchara: 'ginga-01', mainchara2:'', subchara: 'nagisa-02', name: 'name_ginga', keyword: '', event:'', text: '私が車を手配しておきます！早速向かいましょう！' },
                        { step: 2, back: 'back-03', mainchara: 'kantyou-01', mainchara2:'', subchara: 'ginga-01', name: 'name_kantyou', keyword: '', event:'', text: '車を置けるところまで案内します' },
                        { step: 2, back: 'back-03', mainchara: '', mainchara2:'', subchara: '', name: '', keyword: '', event:'', text: '銀河と館長の二人が美術館を出たその時・・・' },
                        { step: 2, back: 'back-03', mainchara: '', mainchara2: '', subchara: '', name: '', keyword: '', event: '', text: '<span class="red"> ～セキュリティシステム～</span><br><span class="red">ウーウーウーウー侵入者発見！侵入者発見！</span>'},
                        { step: 2, back: 'back-03', mainchara: '', mainchara2:'', subchara: '', name: '', keyword: '', event:'', text: '<span class="red">扉を一時的にロックします！</span>'},
                        { step: 2, back: 'back-03', mainchara: 'mirai-01', mainchara2:'', subchara: '', name: 'name_mirai', keyword: '', event:'', text: 'いったいナニゴト！？' },
                        { step: 2, back: 'back-03', mainchara: 'nagisa-01', mainchara2:'', subchara: 'mirai-01', name: 'name_nagisa', keyword: '', event:'', text: 'お嬢様！私たちではない別の誰かがデータベースにアクセスしようとして<br>セキュリティシステムを作動させてしまったようです！！' },
                        { step: 2, back: 'back-03', mainchara: 'nagisa-03', mainchara2:'', subchara: 'mirai-01', name: 'name_nagisa', keyword: '', event:'', text: 'せっかく私はセキュリティシステムを回避しながらアクセスしたのに～<br>めんどくさいことしてくれます！！（怒）' },
                        { step: 2, back: 'back-03', mainchara: '', mainchara2:'', subchara: '', name: '', keyword: '', event:'', text: '<span class="blue">ザアアアアザアアアア</span>'},
                        { step: 2, back: 'back-03', mainchara: 'nagisa-03', mainchara2:'', subchara: '', name: 'name_nagisa', keyword: '10', event:'', text: '誤作動で一部<span class="keyword"  id="keyword" >スプリンクラー</span>が発動しているようです！<br>お嬢様、濡れないようにお気をつけください！'},
                        { step: 2, back: 'back-03', mainchara: '', mainchara2: '', subchara: '', name: 'name_hatena', keyword: '', event: '', text: 'うあわあああああ描いていた絵がああああ！<br>一攫千金を狙ってたのにいいい' },
                        { step: 2, back: 'back-03', mainchara: 'mirai-01', mainchara2: '', subchara: 'nagisa-03', name: 'name_mirai', keyword: '', event: '', text: 'あの人、ピンポイントに上から降ってきた水でビショビショになっているわ！<br>助けてあげましょう！' },
                        { step: 2, back: 'back-03', mainchara: 'nagisa-03', mainchara2:'', subchara: 'mirai-01', name: 'name_nagisa', keyword: '', event:'', text: 'いいえ、その必要はないですよお嬢様' },
                        { step: 2, back: 'back-03', mainchara: 'mirai-01', mainchara2:'', subchara: 'nagisa-03', name: 'name_mirai', keyword: '', event:'', text: 'なんでかしら？' },
                        { step: 2, back: 'back-03', mainchara: 'nagisa-03', mainchara2:'', subchara: 'mirai-01', name: 'name_nagisa', keyword: '', event:'', text: 'あの人は恐らく美術館の絵を真似して、<br>盗作した作品を売ろうとしている方だと思います' },
                        { step: 2, back: 'back-03', mainchara: 'mirai-03', mainchara2:'', subchara: 'nagisa-03', name: 'name_mirai', keyword: '', event:'', text: 'それは残念な人間ね・・・' },
                        { step: 2, back: 'back-03', mainchara: '', mainchara2:'', subchara: '', name: 'name_hatena', keyword: '', event:'', text: '<span class="red">ビショビショに濡れてもうこれ以上は絵が描けないーーー！</span>'},
                        { step: 2, back: 'back-03', mainchara: '', mainchara2:'', subchara: '', name: '', keyword: '', event:'', text: '<span class="red">ガチャン。ガチャン。ガチャン</span>'},
                        { step: 2, back: 'back-03', mainchara: '', mainchara2:'', subchara: '', name: '', keyword: '', event:'', text: 'セキュリティシステムが発動し、次々と扉が閉まっていく' },
                        { step: 2, back: 'back-03', mainchara: 'mirai-03', mainchara2:'', subchara: '', name: 'name_mirai', keyword: '', event:'', text: 'どんどん扉の鍵が閉まっていくわ！どうすればいいのかしら？' },
                        { step: 2, back: 'back-03', mainchara: 'nagisa-03', mainchara2:'', subchara: 'mirai-03', name: 'name_nagisa', keyword: '', event:'', text: '仕方ありません。セキュリティシステムをひとつひとつ解除していきましょう' },


                        { step: 3, back: 'back-02', mainchara: 'ginga-01', mainchara2:'', subchara: '', name: 'name_ginga', keyword: '', event:'', text: 'お嬢様！良かった御無事で・・・すごく心配しましたよ' },
                        { step: 3, back: 'back-02', mainchara: 'nagisa-02', mainchara2: '', subchara: 'ginga-01', name: 'name_nagisa', keyword: '', event: '', text: 'ミライお嬢様のおかげでなんとか脱出できました～' },
                        { step: 3, back: 'back-02', mainchara: 'mirai-03', mainchara2:'', subchara: 'nagisa-02', name: 'name_mirai', keyword: '', event:'', text: '本当に大変だったわ・・・' },
                        { step: 3, back: 'back-02', mainchara: 'kantyou-01', mainchara2:'', subchara: 'mirai-03', name: 'name_kantyou', keyword: '', event:'', text: 'セキュリティシステムが発動したのはあなたがたの仕業なんじゃないですか！？' },
                        { step: 3, back: 'back-02', mainchara: 'mirai-03', mainchara2:'', subchara: 'kantyou-01', name: 'name_mirai', keyword: '', event:'', text: '違うわよ！（怒）私たちも被害者よ。一体誰の仕業なのかしら！<br>絶対に許さないわ！！' },
                        { step: 3, back: 'back-02', mainchara: '', mainchara2:'', subchara: '', name: '', keyword: '', event:'', text: 'そういってミライは美術館の扉に手をかざす' },
                        { step: 3, back: 'back-02', mainchara: '', mainchara2:'', subchara: '', name: '', keyword: '', event:'', text: 'すると、犯人らしき人物のシルエットが見えた' },
                        { step: 3, back: 'back-02', mainchara: 'mirai-03', mainchara2: '', subchara: '', name: 'name_mirai', keyword: '', event: '', text: 'こいつが犯人ね！！<br>この事件が解決したら絶対に捕まえて、<br>皆に謝罪してもらいますわ！！' },
                        { step: 3, back: 'back-04', mainchara: '', mainchara2:'', subchara: '', name: '', keyword: '', event:'', text: '<span class="blue">ブボォォーー、キキー</span>'},
                        { step: 3, back: 'back-04', mainchara: 'ginga-01', mainchara2:'', subchara: '', name: 'name_ginga', keyword: '', event:'', text: 'お嬢様。到着いたしました' },
                        { step: 3, back: 'back-04', mainchara: 'mirai-02', mainchara2: '', subchara: 'ginga-01', name: 'name_mirai', keyword: '', event: '', text: 'ここが筆塚・・・イドウのアトリエね！ここで様々な絵を描いてきたのね' },
                        { step: 3, back: 'back-04', mainchara: 'ginga-01', mainchara2: '', subchara: 'mirai-02', name: 'name_ginga', keyword: '', event: '', text: '私は一旦、車を邪魔にならないところに移動させてきます' },
                        { step: 3, back: 'back-04', mainchara: '', mainchara2:'', subchara: '', name: '', keyword: '', event:'', text: '<span class="blue">キュギギギギ、キュギギギギ・・・</span>'},
                        { step: 3, back: 'back-04', mainchara: 'ginga-02', mainchara2:'', subchara: '', name: 'name_ginga', keyword: '', event:'', text: 'どうしたのでしょう・・・<br>車のエンジンが急にかからなくなってしまいました・・・' },
                        { step: 3, back: 'back-04', mainchara: 'mirai-01', mainchara2:'', subchara: 'ginga-02', name: 'name_mirai', keyword: '', event:'', text: '車・・・故障してしまったのかしら？' },
                        { step: 3, back: 'back-04', mainchara: 'nagisa-02', mainchara2:'', subchara: 'mirai-01', name: 'name_nagisa', keyword: '', event:'', text: '故障なら私にお任せください！直せるかもしれません！' },
                        { step: 3, back: 'back-04', mainchara: 'mirai-02', mainchara2:'', subchara: 'nagisa-02', name: 'name_mirai', keyword: '', event:'', text: 'さすが渚！あなたは最強のメイドだわ！' },
                        { step: 3, back: 'back-04', mainchara: 'nagisa-02', mainchara2: '', subchara: 'mirai-02', name: 'name_nagisa', keyword: '', event: '', text: 'えへへ〜' },
                        { step: 3, back: 'back-04', mainchara: 'ginga-01', mainchara2:'', subchara: 'nagisa-02', name: 'name_ginga', keyword: '', event:'', text: 'ミライお嬢様。とりあえず私たち二人で先にアトリエに伺いましょう' },
                        { step: 3, back: 'back-04', mainchara: 'mirai-01', mainchara2:'', subchara: 'ginga-01', name: 'name_mirai', keyword: '', event:'', text: 'そうね。では渚、車の修理頼んだわよ' },
                        { step: 3, back: 'back-04', mainchara: 'nagisa-02', mainchara2:'', subchara: 'mirai-01', name: 'name_nagisa', keyword: '', event:'', text: 'かしこまりました！' },
                        { step: 3, back: 'back-05', mainchara: '', mainchara2:'', subchara: '', name: '', keyword: '', event:'', text: '<span class="blue">～ピンポーン、ピンポーン～</span>'},
                        { step: 3, back: 'back-05', mainchara: 'ginga-01', mainchara2:'', subchara: '', name: 'name_ginga', keyword: '', event:'', text: '出ませんね・・・' },
                        { step: 3, back: 'back-05', mainchara: 'mirai-01', mainchara2:'', subchara: 'ginga-01', name: 'name_mirai', keyword: '', event:'', text: '仕方ないわね・・・一旦このアトリエ付近を調べてみましょうか' },
                        { step: 3, back: 'back-05', mainchara: '', mainchara2:'', subchara: '', name: '', keyword: '', event:'', text: 'ミライはそう言うとアトリエの玄関に手をかざした' },
                        { step: 3, back: 'back-05', mainchara: '', mainchara2:'', subchara: '', name: '', keyword: '', event:'', text: '扉がいつも開いていることがわかった' },
                        { step: 3, back: 'back-05', mainchara: 'mirai-01', mainchara2:'', subchara: '', name: 'name_mirai', keyword: '', event:'', text: 'どうやらここのアトリエは鍵がかかっていないみたい' },
                        { step: 3, back: 'back-05', mainchara: 'ginga-01', mainchara2:'', subchara: 'mirai-01', name: 'name_ginga', keyword: '', event:'', text: 'このご時世に不用心ですね・・・' },
                        { step: 3, back: 'back-05', mainchara: 'mirai-01', mainchara2:'', subchara: 'ginga-01', name: 'name_mirai', keyword: '', event:'', text: 'まあ開いてるなら玄関から入っちゃいましょう！' },
                        { step: 3, back: 'back-05', mainchara: 'mirai-02', mainchara2: '', subchara: 'ginga-01', name: 'name_mirai', keyword: '', event:'', text: '探偵業だから仕方がないわ！<br>（※皆さんは真似しないようにしましょう）' },
                        { step: 3, back: 'back-05', mainchara: 'ginga-01', mainchara2:'', subchara: 'mirai-02', name: 'name_ginga', keyword: '', event:'', text: '（最近のミライお嬢様は行動に躊躇いがないです・・・）' },
                        { step: 3, back: 'back-05', mainchara: 'mirai-01', mainchara2:'', subchara: 'ginga-01', name: 'name_mirai', keyword: '', event:'', text: 'では入るわよ？お邪魔しますわ！' },
                        { step: 3, back: 'back-05', mainchara: 'ginga-01', mainchara2:'', subchara: 'mirai-01', name: 'name_ginga', keyword: '', event:'', text: '失礼します' },


                        { step: 3, back: 'back-06', mainchara: '', mainchara2:'', subchara: '', name: '', keyword: '', event:'', text: '<span class="blue">・・・シーーーーーン</span>'},
                        { step: 3, back: 'back-06', mainchara: 'mirai-01', mainchara2:'', subchara: '', name: 'name_mirai', keyword: '', event:'', text: '誰もいませんわね・・・' },
                        { step: 3, back: 'back-06', mainchara: '', mainchara2:'', subchara: '', name: 'name_hatena', keyword: '', event:'', text: '（扉の方から）それではごゆっくり' },
                        { step: 3, back: 'back-06', mainchara: 'mirai-ginga', mainchara2: '', subchara: '', name: 'name_mirai_ginga', keyword: '', event:'', text: '！？' },
                        { step: 3, back: 'back-06', mainchara: '', mainchara2:'', subchara: '', name: '', keyword: '', event:'', text: '<span class="blue">ガチャン、カチャリ</span>'},
                        { step: 3, back: 'back-06', mainchara: 'ginga-01', mainchara2:'', subchara: '', name: 'name_ginga', keyword: '', event:'', text: 'まさか、閉められましたか！？' },
                        { step: 3, back: 'back-06', mainchara: '', mainchara2:'', subchara: '', name: '', keyword: '', event:'', text: '<span class="blue">ガチャガチャ、ガチャガチャ</span>'},
                        { step: 3, back: 'back-06', mainchara: '', mainchara2:'', subchara: '', name: '', keyword: '', event:'', text: '銀河があわてて扉を開けようとするが・・・' },
                        { step: 3, back: 'back-06', mainchara: 'ginga-02', mainchara2:'', subchara: '', name: 'name_ginga', keyword: '', event:'', text: 'お嬢様！扉が開きません！！' },
                        { step: 3, back: 'back-06', mainchara: 'mirai-03', mainchara2:'', subchara: 'ginga-02', name: 'name_mirai', keyword: '', event:'', text: 'また閉じ込められてしまいましたわ！？' },


                        { step: 3, back: 'back-06', mainchara: 'mirai-03', mainchara2: '', subchara: '', name: 'name_mirai', keyword: '', event: '', text: '仕方がありません。奥に進んでみましょう' },

                        { step: 3, back: 'step3_room1_06', mainchara: 'mirai-01', mainchara2:'', subchara: '', name: 'name_mirai', keyword: '', event:'', text: 'さすが画家のアトリエですわね。色んな絵が飾られてますわ' },
                        { step: 3, back: 'step3_room1_06', mainchara: 'ginga-01', mainchara2: '', subchara: 'mirai-01', name: 'name_ginga', keyword: '', event: '', text: '・・・ん？このパソコン、<br>「セキュリティチェック」と表示されていますね」' },
                        { step: 3, back: 'step3_pc_01', mainchara: '', mainchara2:'', subchara: '', name: 'name_mirai', keyword: '', event:'', text: 'セキュリティチェックを突破すれば、部屋のセキュリティが解除されそうね！' },
                        { step: 3, back: 'step3_pc_01', mainchara: '', mainchara2:'', subchara: '', name: 'name_ginga', keyword: '', event:'', text: '私、絵には多少心得がございます。<br>これは数字が大きい方が本物の絵画です。' },
                        { step: 3, back: 'step3_pc_01', mainchara: '', mainchara2:'', subchara: '', name: 'name_mirai', keyword: '', event:'', text: 'じゃあ１３まであるトランプが本物ね！<br>（ポチッ）' },
                        { step: 3, back: 'step3_pc_01', mainchara: '', mainchara2:'', subchara: '', name: 'name_ginga', keyword: '', event:'', text: 'ミライ様！13まではキングなどを含めたもので、トランプは10まで――' },
                        { step: 3, back: 'step3_pc_07', mainchara: '', mainchara2:'', subchara: '', name: '', keyword: '', event:'', text: '<span class="red">ブッブー！</span>'},
                        { step: 3, back: 'step3_pc_07', mainchara: '', mainchara2:'', subchara: '', name: '', keyword: '', event:'', text: '<span class="blue">間違えた絵画を選んだとたん、<br>パソコンから催眠針が放たれ<br>銀河に命中した</span>'},
                        { step: 3, back: 'step3_pc_07', mainchara: 'ginga-01', mainchara2: '', subchara: 'mirai-01', name: 'name_ginga', keyword: '', event: '', text: 'お嬢さ・・・ま・・・<br>困ったら思念を・・・よん・・・で' },
                        { step: 3, back: 'step3_room1_02', mainchara: '', mainchara2:'', subchara: '', name: '', keyword: '', event:'', text: '銀河が倒れると、机の上に置かれていた<span class="red">宝石が床に落ち、パリンと砕け散った</span>'},
                        { step: 3, back: 'step3_room1_02', mainchara: 'mirai-01', mainchara2: '', subchara: '', name: 'name_mirai', keyword: '', event: '', text: '・・・あとで銀河に謝らなきゃ。<br>私一人でなんでもできるなんて思ってはダメね' },
                        { step: 3, back: 'step3_room1_02', mainchara: 'mirai-01', mainchara2:'', subchara: '', name: 'name_mirai', keyword: '', event:'', text: '今のでパソコンにロックがかかってしまったわ。<br>最終的にセキュリティチェックを突破する必要はあるようだけれど、' },
                        { step: 3, back: 'step3_room1_02', mainchara: 'mirai-01', mainchara2:'', subchara: '', name: 'name_mirai', keyword: '', event:'', text: '部屋に何か鍵があるかもしれないわ。捜索して４桁の番号が分かったら入力してみましょう' },



                        { step: 4, back: 'back-04', mainchara: '', mainchara2:'', subchara: '', name: '', keyword: '', event:'', text: '扉が開き、新鮮な空気を吸ったことで<br>銀河はすっかり目が覚めた' },
                        { step: 4, back: 'back-04', mainchara: 'mirai-02', mainchara2:'', subchara: '', name: 'name_mirai', keyword: '', event:'', text: 'やっっっと脱出できましたわ！！！' },
                        { step: 4, back: 'back-04', mainchara: 'ginga-01', mainchara2:'', subchara: 'mirai-02', name: 'name_ginga', keyword: '', event:'', text: 'おみごとです！ミライお嬢様！！<br>ほとんどお役に立てず申し訳なかったです・・・' },
                        { step: 4, back: 'back-04', mainchara: 'nagisa-02', mainchara2:'', subchara: 'ginga-01', name: 'name_nagisa', keyword: '', event:'', text: 'ミライお嬢様ーーー！！！心配しましたよーーーー！！！' },
                        { step: 4, back: 'back-04', mainchara: 'mirai-01', mainchara2:'', subchara: 'nagisa-02', name: 'name_mirai', keyword: '', event:'', text: 'ごめんなさいね渚。出てくるのに時間がかかってしまって' },
                        { step: 4, back: 'back-04', mainchara: 'nagisa-03', mainchara2:'', subchara: 'mirai-01', name: 'name_nagisa', keyword: '', event:'', text: '連絡も取れなくなって、どうしようかと思ってました・・・' },
                        { step: 4, back: 'back-04', mainchara: 'mirai-03', mainchara2:'', subchara: 'nagisa-03', name: 'name_mirai', keyword: '', event:'', text: '今日は2回も閉じ込められて、本当にさんざんな日ですわ！！<br>私をこんな目に合わせた犯人を絶対にとっ捕まえてやりますわ！！' },
                        { step: 4, back: 'back-04', mainchara: 'nagisa-03', mainchara2:'', subchara: 'mirai-03', name: 'name_nagisa', keyword: '', event:'', text: '犯人といえば・・・お嬢様！車が大変だったんです！' },
                        { step: 4, back: 'back-04', mainchara: 'mirai-01', mainchara2:'', subchara: 'nagisa-03', name: 'name_mirai', keyword: '', event:'', text: '車がどうかしたの？' },
                        { step: 4, back: 'back-04', mainchara: 'nagisa-03', mainchara2:'', subchara: 'mirai-01', name: 'name_nagisa', keyword: '11', event:'', text: '車の<span class="keyword"  id="keyword" >エンジンルーム</span>にこの絵画が何者かに無理やり押し込まれてて、<br>プラグがおかしくなってエンジンがかからなくなってたんです'},
                        { step: 4, back: 'back-04', mainchara: '', mainchara2:'', subchara: '', name: '', keyword: '', event:'', text: '渚がミライにその絵画を見せる' },
                        { step: 4, back: 'back-04', mainchara: 'mirai-01', mainchara2:'', subchara: '', name: 'name_mirai', keyword: '', event:'', text: '何かしらこの絵画？' },
                        { step: 4, back: 'back-04', mainchara: 'ginga-01', mainchara2:'', subchara: 'mirai-01', name: 'name_ginga', keyword: '', event:'', text: 'この絵、先ほどの美術館の館長のようにも見えますが・・・' },
                        { step: 4, back: 'back-04', mainchara: 'mirai-02', mainchara2:'', subchara: 'ginga-01', name: 'name_mirai', keyword: '', event:'', text: '私たちを閉じ込めた犯人がわかるかもしれないし、<br>試しに思念を読み取ってみましょう！！' },
                        { step: 4, back: 'back-04', mainchara: 'ginga-01', mainchara2:'', subchara: 'mirai-02', name: 'name_ginga', keyword: '', event:'', text: 'しかし、犯人捜しより筆塚石生の行方を追った方がいいのではないですか？' },
                        { step: 4, back: 'back-04', mainchara: 'mirai-03', mainchara2:'', subchara: 'ginga-01', name: 'name_mirai', keyword: '', event:'', text: '筆塚イマヲは一旦いいの！！今は犯人に対してすごく腹立たしいのよ！' },
                        { step: 4, back: 'back-04', mainchara: 'mirai-03', mainchara2:'', subchara: 'ginga-01', name: 'name_mirai', keyword: '', event:'', text: 'せっかく犯人を特定できるアイテムが目の前にあるのだから<br>先にそっちを解決するわ！！' },
                        { step: 4, back: 'back-04', mainchara: '', mainchara2:'', subchara: '', name: '', keyword: '', event:'', text: 'そう言うとミライはその絵画に直接手を触れる' },
                        { step: 4, back: 'back-04', mainchara: '', mainchara2:'', subchara: '', name: '', keyword: '', event:'', text: 'すると、ものすごい量の情報が流れ込んできた' },
                        { step: 4, back: 'back-04', mainchara: 'mirai-01', mainchara2:'', subchara: '', name: 'name_mirai', keyword: '', event: '', text: 'いったいどうゆうこと！？<br>一人の人物の思念が大量に残されていますわ！？これだとうまく読み取れない・・・' },
                        { step: 4, back: 'back-04', mainchara: '', mainchara2:'', subchara: '', name: '', keyword: '', event:'', text: '一度に多くの情報が流れてきたことで、ミライはすこしふらついた' },
                        { step: 4, back: 'back-04', mainchara: 'nagisa-03', mainchara2:'', subchara: 'mirai-01', name: 'name_nagisa', keyword: '', event:'', text: '大丈夫ですか！？ミライお嬢様！！' },
                        { step: 4, back: 'back-04', mainchara: 'mirai-01', mainchara2:'', subchara: 'nagisa-03', name: 'name_mirai', keyword: '', event:'', text: 'ええ大丈夫よ。ありがとう。もうこの絵画自体には手を触れたくないわ・・・' },
                        { step: 4, back: 'back-04', mainchara: 'ginga-02', mainchara2:'', subchara: 'mirai-01', name: 'name_ginga', keyword: '', event:'', text: '御無理なさらず。お嬢様' },
                        { step: 4, back: 'back-04', mainchara: 'mirai-01', mainchara2:'', subchara: 'ginga-02', name: 'name_mirai', keyword: '', event:'', text: '恐らくこの絵画を車に残した人物は、私たちを二度も閉じ込めた人物に違いないわ' },
                        { step: 4, back: 'back-04', mainchara: 'mirai-01', mainchara2:'', subchara: 'ginga-02', name: 'name_mirai', keyword: '', event:'', text: '残されたこの絵画からどうにかして思念の情報を抜き出せると良いのだけれど・・・' },
                        { step: 4, back: 'back-04', mainchara: 'ginga-01', mainchara2:'', subchara: 'mirai-01', name: 'name_ginga', keyword: '', event:'', text: 'この絵画“自体”が厳しいのであれば、額縁はいかがでしょう？' },
                        { step: 4, back: 'back-04', mainchara: 'ginga-01', mainchara2: '', subchara: 'mirai-01', name: 'name_ginga', keyword: '', event: '', text: 'きっと犯人は車に押し込む際に、そこにも触れているはずです' },
                        { step: 4, back: 'back-04', mainchara: 'mirai-02', mainchara2:'', subchara: 'ginga-01', name: 'name_mirai', keyword: '', event:'', text: 'さすがね銀河。額縁から思念を読み取ってみるわ' },
                        { step: 4, back: 'back-04', mainchara: '', mainchara2:'', subchara: '', name: '', keyword: '', event:'', text: 'ミライは絵画の額縁に手をかざした' },
                        { step: 4, back: 'back-04', mainchara: '', mainchara2:'', subchara: '', name: '', keyword: '', event:'', text: 'すると、ある一人の人物の思念が読み取れた' },
                        { step: 4, back: 'back-04', mainchara: 'mirai-01', mainchara2:'', subchara: 'ginga-01', name: 'name_mirai', keyword: '', event:'', text: 'どうやらこの人物がこの絵画を持っていたようね' },
                        { step: 4, back: 'back-04', mainchara: '', mainchara2:'', subchara: '', name: '', keyword: '', event:'', text: 'ミライが読み取った人物のメモを銀河と渚に見せる' },
                        { step: 4, back: 'back-04', mainchara: 'ginga-01', mainchara2:'', subchara: 'mirai-01', name: 'name_ginga', keyword: '12', event: '', text: '<span class="keyword"  id="keyword" >成川輪廻</span>（なりかわ　りんね）・・・'},
                        { step: 4, back: 'back-04', mainchara: 'nagisa-01', mainchara2:'', subchara: 'ginga-01', name: 'name_nagisa', keyword: '', event:'', text: 'この人の名前、どこかで見たような・・・' },
                        { step: 4, back: 'back-04', mainchara: 'nagisa-02', mainchara2:'', subchara: 'ginga-01', name: 'name_nagisa', keyword: '', event:'', text: 'あっ思い出しました！美術館のデータベースに載っていた人です！！' },
                        { step: 4, back: 'back-04', mainchara: 'mirai-01', mainchara2:'', subchara: 'nagisa-02', name: 'name_mirai', keyword: '', event:'', text: 'ということは画家が犯人！？' },
                        { step: 4, back: 'back-04', mainchara: 'ginga-01', mainchara2:'', subchara: 'mirai-01', name: 'name_ginga', keyword: '', event:'', text: 'もしかしたらそうかもしれないですね' },
                        { step: 4, back: 'back-04', mainchara: 'nagisa-01', mainchara2:'', subchara: 'ginga-01', name: 'name_nagisa', keyword: '', event:'DB', text: 'データベースを使ってこの人をしらべてみましょう！' },


                        { step: 4, back: 'back-04', mainchara: 'mirai-01', mainchara2:'', subchara: '', name: 'name_mirai', keyword: '', event:'', text: 'あまり大したことは書いていないわね。' },
                        { step: 4, back: 'back-04', mainchara: 'mirai-01', mainchara2:'', subchara: '', name: 'name_mirai', keyword: '', event:'', text: 'この人、画家としてやっていけてるのかしら？' },
                        { step: 4, back: 'back-04', mainchara: 'nagisa-02', mainchara2:'', subchara: 'mirai-01', name: 'name_nagisa', keyword: '', event:'', text: 'どうやらデータベースによるとお団子が好きみたいですよ～' },
                        { step: 4, back: 'back-04', mainchara: 'mirai-01', mainchara2:'', subchara: 'nagisa-02', name: 'name_mirai', keyword: '13', event:'', text: 'じゃあ今日もお団子を食べに<span class="keyword"  id="keyword" >甘味処</span>にいるんじゃないかしら！？'},
                        { step: 4, back: 'back-04', mainchara: 'ginga-01', mainchara2: '', subchara: 'mirai-01', name: 'name_ginga', keyword: '', event: '', text: 'いるかどうかはわかりませんが・・・<br>たまに来ているようであれば、店員さんなどに聞き込みができるかもしれませんしね' },
                        { step: 4, back: 'back-04', mainchara: 'mirai-01', mainchara2:'', subchara: 'ginga-01', name: 'name_mirai', keyword: '', event:'', text: 'んんっ。そうね。私もそう思っていたわ' },
                        { step: 4, back: 'back-04', mainchara: 'mirai-02', mainchara2:'', subchara: 'ginga-01', name: 'name_mirai', keyword: '', event:'', text: '美術館から一番近い甘味処に向かいましょう' },
                        { step: 4, back: 'back-04', mainchara: 'nagisa-ginga', mainchara2: '', subchara: 'mirai-02', name: 'name_nagisa_ginga', keyword: '', event:'', text: 'かしこまりました！' },
                        { step: 4, back: 'back-07', mainchara: 'nagisa-02', mainchara2:'', subchara: 'ginga-01', name: 'name_nagisa', keyword: '', event:'', text: 'お嬢様！このみたらし団子美味しいですー！' },
                        { step: 4, back: 'back-07', mainchara: 'mirai-02', mainchara2:'', subchara: 'nagisa-02', name: 'name_mirai', keyword: '', event:'', text: 'ほんとね、疲れた頭に糖分がしみわたるわ' },
                        { step: 4, back: 'back-07', mainchara: 'ginga-01', mainchara2:'', subchara: 'mirai-02', name: 'name_ginga', keyword: '', event:'', text: '何をやってるのですか、二人とも。調査に戻りますよ' },
                        { step: 4, back: 'back-07', mainchara: 'mirai-01', mainchara2:'', subchara: 'ginga-01', name: 'name_mirai', keyword: '', event:'', text: '仕方ないわね。一旦ここのお店の思念を読みとってみるわ' },
                        { step: 4, back: 'back-07', mainchara: '', mainchara2:'', subchara: '', name: '', keyword: '', event:'', text: 'そういうとミライはお店に手を触れた' },
                        { step: 4, back: 'back-07', mainchara: 'mirai-01', mainchara2:'', subchara: '', name: 'name_mirai', keyword: '', event:'', text: 'うーん。だめみたい。沢山の思念が集まりすぎてうまく読み取れないわ' },
                        { step: 4, back: 'back-07', mainchara: 'nagisa-01', mainchara2:'', subchara: 'mirai-01', name: 'name_nagisa', keyword: '', event:'', text: 'じゃあ、あの一番奥の席の、お団子ばかり食べてる人！怪しいと思います！' },
                        { step: 4, back: 'back-07', mainchara: 'mirai-01', mainchara2:'', subchara: 'nagisa-01', name: 'name_mirai', keyword: '', event:'', text: 'たしかに！そこのあなた！あなたが成川輪廻ね！' },
                        { step: 4, back: 'back-07', mainchara: 'joren-01', mainchara2:'', subchara: 'mirai-01', name: 'name_joren', keyword: '', event:'', text: 'は！？・・・ちがうよ！な、なんなんだ君たちは！' },
                        { step: 4, back: 'back-07', mainchara: 'mirai-01', mainchara2:'', subchara: 'joren-01', name: 'name_mirai', keyword: '', event:'', text: '私たちは探偵よ！私の勘はあなたが怪しいと言っているわ！' },
                        { step: 4, back: 'back-07', mainchara: 'joren-01', mainchara2: '', subchara: 'mirai-01', name: 'name_joren', keyword: '14', event:'', text: '僕は団子が好きな『<span class="keyword"  id="keyword" >浄蓮 脚</span>（じょうれんきゃく）』だ！！'},
                        { step: 4, back: 'back-07', mainchara: 'joren-01', mainchara2:'', subchara: 'mirai-01', name: 'name_joren', keyword: '', event:'', text: 'よりにもよって成川輪廻と間違われるなんてなんて日だ・・・' },
                        { step: 4, back: 'back-07', mainchara: 'mirai-01', mainchara2:'', subchara: 'joren-01', name: 'name_mirai', keyword: '', event:'', text: 'あなた、成川輪廻のこと知ってるの？' },
                        { step: 4, back: 'back-07', mainchara: 'joren-01', mainchara2:'', subchara: 'mirai-01', name: 'name_joren', keyword: '', event:'', text: 'あー、知ってるさ。あいつのせいで・・・ってなんで君たちにあいつの話をしなきゃいけないんだ！' },
                        { step: 4, back: 'back-07', mainchara: 'mirai-01', mainchara2:'', subchara: 'joren-01', name: 'name_mirai', keyword: '', event:'', text: 'とある理由から今その成川輪廻の跡を追っているの' },
                        { step: 4, back: 'back-07', mainchara: 'nagisa-01', mainchara2:'', subchara: 'mirai-01', name: 'name_nagisa', keyword: '', event:'', text: 'どうか調査にご協力ください！' },
                        { step: 4, back: 'back-07', mainchara: 'joren-01', mainchara2:'', subchara: 'nagisa-01', name: 'name_joren', keyword: '', event:'', text: 'ほほーん（悪そうな笑み）。もちろんただでとは言わないよね？' },
                        { step: 4, back: 'back-07', mainchara: 'ginga-01', mainchara2:'', subchara: 'joren-01', name: 'name_ginga', keyword: '', event:'', text: 'ミライお嬢様。わざわざこの人の要求を飲む必要はありませんよ' },
                        { step: 4, back: 'back-07', mainchara: 'joren-01', mainchara2: '', subchara: 'ginga-01', name: 'name_joren', keyword: '', event:'', text: 'えー。じゃあおしえてやーーーらね' },
                        { step: 4, back: 'back-07', mainchara: 'ginga-01', mainchara2:'', subchara: 'nagisa-03', name: 'name_ginga', keyword: '', event:'', text: 'ぐぬぬ・・・' },
                        { step: 4, back: 'back-07', mainchara: 'mirai-01', mainchara2:'', subchara: 'ginga-01', name: 'name_mirai', keyword: '', event:'', text: 'まあ銀河、落ち着きなさい。わかったわ。あなたの要求をのみましょう。何をしてほしいの？' },
                        { step: 4, back: 'back-07', mainchara: 'joren-01', mainchara2:'', subchara: 'mirai-01', name: 'name_joren', keyword: '', event:'', text: 'えっとねぇ・・・ここのお団子1年分か、それとも・・・' },
                        { step: 4, back: 'back-07', mainchara: 'joren-01', mainchara2:'', subchara: 'mirai-01', name: 'name_joren', keyword: '', event:'', text: 'あっそうだ！そこのメイド！僕に仕えてくれ' },
                        { step: 4, back: 'back-07', mainchara: 'nagisa-01', mainchara2:'', subchara: 'joren-01', name: 'name_nagisa', keyword: '', event:'', text: 'いやです（ガチトーン）' },
                        { step: 4, back: 'back-07', mainchara: 'joren-01', mainchara2:'', subchara: 'nagisa-01', name: 'name_joren', keyword: '', event:'', text: 'くっ・・・じゃあ仕方ない、僕を有名人にしてくれ！' },
                        { step: 4, back: 'back-07', mainchara: 'nagisa-ginga', mainchara2: '', subchara: '', name: 'name_nagisa_ginga', keyword: '', event:'', text: '（何を言っているんだこいつは）' },
                        { step: 4, back: 'back-07', mainchara: 'joren-01', mainchara2:'', subchara: '', name: 'name_joren', keyword: '', event:'', text: '僕は有名人になりたいんだ！' },
                        { step: 4, back: 'back-07', mainchara: 'joren-01', mainchara2:'', subchara: '', name: 'name_joren', keyword: '', event:'', text: '成川輪廻には才能がないと言われたが<br>きっと僕には有名人になれる素質があるんだ！' },
                        { step: 4, back: 'back-07', mainchara: 'mirai-01', mainchara2:'', subchara: 'joren-01', name: 'name_mirai', keyword: '', event:'', text: 'なるほど・・・事情は理解したわ・・・<br>それで、私たちは何をすればいいのかしら・・・？' },
                        { step: 4, back: 'back-07', mainchara: 'joren-01', mainchara2: '', subchara: 'mirai-01', name: 'name_joren', keyword: '15', event:'', text: 'この街のどこかにあると言われている『<span class="keyword"  id="keyword" >切望の達磨</span>』を持ってきてくれればいい'},
                        { step: 4, back: 'back-07', mainchara: 'mirai-01', mainchara2:'', subchara: 'joren-01', name: 'name_mirai', keyword: '', event:'', text: '切望の達磨？' },
                        { step: 4, back: 'back-07', mainchara: 'joren-01', mainchara2:'', subchara: 'mirai-01', name: 'name_joren', keyword: '', event:'', text: 'そうだ。どこにあるかは知らないが、その達磨があれば僕の願いが叶うんだ' },
                        { step: 4, back: 'back-07', mainchara: 'ginga-01', mainchara2:'', subchara: 'joren-01', name: 'name_ginga', keyword: '', event:'', text: '一体どんな達磨なんでしょう？' },
                        { step: 4, back: 'back-07', mainchara: 'joren-01', mainchara2:'', subchara: 'ginga-01', name: 'name_joren', keyword: '', event:'', text: 'その達磨に書いた願いは“達磨が倒れなければ”絶対に叶うらしい。' },
                        { step: 4, back: 'back-07', mainchara: 'joren-01', mainchara2: '', subchara: 'ginga-01', name: 'name_joren', keyword: '', event: '', text: '反対に倒れてしまうと逆の願いが叶ってしまうとかなんとか' },
                        { step: 4, back: 'back-07', mainchara: 'joren-01', mainchara2:'', subchara: 'ginga-01', name: 'name_joren', keyword: '', event:'', text: 'おもりがついてて倒れることはまずないらしいけどね' },
                        { step: 4, back: 'back-07', mainchara: 'nagisa-01', mainchara2:'', subchara: 'joren-01', name: 'name_nagisa', keyword: '', event:'', text: '不思議な達磨さんですね～' },
                        { step: 4, back: 'back-07', mainchara: 'mirai-01', mainchara2:'', subchara: 'nagisa-01', name: 'name_mirai', keyword: '', event:'', text: 'でもその達磨、どこにあるかわからないんでしょ？' },
                        { step: 4, back: 'back-07', mainchara: 'joren-01', mainchara2:'', subchara: 'mirai-01', name: 'name_joren', keyword: '', event:'', text: 'そうさ。だから探偵である君たちにそれを見つけて持ってきて欲しいんだ！' },
                        { step: 4, back: 'back-07', mainchara: 'mirai-01', mainchara2:'', subchara: 'joren-01', name: 'name_mirai', keyword: '', event:'change', text: 'わかったわ。見つけてきてさしあげますわ' },


                        { step: 4, back: 'back-07', mainchara: 'ginga-02', mainchara2:'', subchara: 'mirai-01', name: 'name_ginga', keyword: '', event:'', text: 'ネットには全然情報がないですね・・・' },
                        { step: 4, back: 'back-07', mainchara: 'ginga-02', mainchara2:'', subchara: 'mirai-01', name: 'name_ginga', keyword: '', event:'', text: 'やみくもに残留思念を調べるのも無理でしょうし・・・' },
                        { step: 4, back: 'back-07', mainchara: 'mirai-01', mainchara2:'', subchara: 'ginga-02', name: 'name_mirai', keyword: '', event:'', text: 'そういえばこの達磨最近どこかでみたような・・・' },
                        { step: 4, back: 'back-07', mainchara: 'nagisa-01', mainchara2:'', subchara: 'mirai-01', name: 'name_nagisa', keyword: '', event:'', text: '私は・・・見た記憶はないですね' },
                        { step: 4, back: 'back-07', mainchara: 'ginga-01', mainchara2:'', subchara: 'nagisa-01', name: 'name_ginga', keyword: '', event:'sentaku1', text: '私は一瞬だけ見た記憶があるような・・・' },
                        //{ step: 5, back: 'back-07', mainchara: 'ginga-01', mainchara2:'', subchara: 'nagisa-01', name: 'name_ginga', keyword: '', event: 'sentaku1', text: '' },
                        
                        { step: 4, back: 'back-07', mainchara: 'mirai-01', mainchara2:'', subchara: 'ginga-01', name: 'name_mirai', keyword: '', event:'', text: 'そうだわ！筆塚イタヨのアトリエの写真の中にあったわね' },
                        { step: 4, back: 'back-07', mainchara: 'ginga-01', mainchara2:'', subchara: 'mirai-01', name: 'name_ginga', keyword: '', event:'sentaku2', text: 'そういえばありましたね。あの写真・・・たしか一緒に何か映ってましたよね' },


                        { step: 4, back: 'back-07', mainchara: 'ginga-01', mainchara2:'', subchara: 'mirai-01', name: 'name_ginga', keyword: '', event:'', text: 'そうだった。黄色いこけしがいましたね' },
                        { step: 4, back: 'back-07', mainchara: 'mirai-01', mainchara2:'', subchara: 'ginga-01', name: 'name_mirai', keyword: '', event:'', text: '渚、黄色いこけしについて調べてみて！' },
                        { step: 4, back: 'back-07', mainchara: 'nagisa-02', mainchara2:'', subchara: 'mirai-01', name: 'name_nagisa', keyword: '', event:'', text: '承知です！' },
                        { step: 4, back: 'back-07', mainchara: 'nagisa-01', mainchara2: '', subchara: 'mirai-01', name: 'name_nagisa', keyword: '16', event:'', text: 'えっと...わかりました！<span class="keyword"  id="keyword" >待泊神社</span>（まちはくじんじゃ）という<br>ところで売られてるものですね'},
                        { step: 4, back: 'back-07', mainchara: 'mirai-02', mainchara2:'', subchara: 'nagisa-01', name: 'name_mirai', keyword: '', event:'', text: 'さすが渚。情報収集が早いわ' },
                        { step: 4, back: 'back-07', mainchara: 'mirai-01', mainchara2:'', subchara: 'nagisa-01', name: 'name_mirai', keyword: '', event:'', text: 'ただ、結局神社だといろんな人の残留思念が残されているから、そこで読み取って調査するのは難しいかもしれないわね' },
                        { step: 4, back: 'back-07', mainchara: 'ginga-01', mainchara2:'', subchara: 'mirai-01', name: 'name_ginga', keyword: '', event:'', text: '“待泊神社”で投稿されてる写真を色々調べてみたのですが、やけに食べ歩いている人が多いです' },
                        { step: 4, back: 'back-07', mainchara: 'mirai-01', mainchara2:'', subchara: 'ginga-01', name: 'name_mirai', keyword: '', event:'sentaku3', text: 'ということはもしかして・・・' },


                        { step: 4, back: 'back-08', mainchara: 'mirai-01', mainchara2:'', subchara: 'ginga-01', name: 'name_mirai', keyword: '', event:'', text: 'この神社には屋台が多いんじゃないかしら？' },
                        { step: 4, back: 'back-08', mainchara: 'ginga-01', mainchara2:'', subchara: 'mirai-01', name: 'name_ginga', keyword: '', event:'', text: 'なるほど！たしかにそうかもしれないです' },
                        { step: 4, back: 'back-08', mainchara: 'nagisa-01', mainchara2:'', subchara: 'ginga-01', name: 'name_nagisa', keyword: '', event:'', text: 'じゃあこのたくさんの屋台の中に切望の達磨さんがいるんですかね？' },
                        { step: 4, back: 'back-08', mainchara: 'mirai-01', mainchara2:'', subchara: 'nagisa-01', name: 'name_mirai', keyword: '', event:'', text: 'きっとそうに違いないわ。達磨が置かれていそうな屋台・・・' },
                        { step: 4, back: 'back-08', mainchara: 'mirai-01', mainchara2:'', subchara: 'nagisa-01', name: 'name_mirai', keyword: '', event:'', text: 'うーん、むずかしいわね。どこにでも置いてありそうだけれど・・・' },
                        { step: 4, back: 'back-08', mainchara: 'nagisa-01', mainchara2:'', subchara: 'mirai-01', name: 'name_nagisa', keyword: '', event:'', text: '達磨さんの特徴をいかした屋台ってことはないですかね？' },
                        { step: 4, back: 'back-08', mainchara: 'ginga-01', mainchara2:'', subchara: 'nagisa-01', name: 'name_ginga', keyword: '', event:'sentaku4', text: '達磨の特徴と言えば、書いた願いが叶うことと、そして・・・' },


                        { step: 4, back: 'back-08', mainchara: 'ginga-01', mainchara2:'', subchara: 'nagisa-01', name: 'name_ginga', keyword: '', event:'', text: 'おもりがついていると言ってましたね' },
                        { step: 4, back: 'back-08', mainchara: 'nagisa-01', mainchara2:'', subchara: 'ginga-01', name: 'name_nagisa', keyword: '', event:'', text: 'おもりがついていて倒れない達磨さんがいそうな屋台・・・' },
                        { step: 4, back: 'back-08', mainchara: 'mirai-01', mainchara2:'', subchara: 'nagisa-01', name: 'name_mirai', keyword: '', event:'', text: 'ところで、もし屋台で売られているのだとしたら、何故みんな買わないのかしら？' },
                        { step: 4, back: 'back-08', mainchara: 'ginga-01', mainchara2:'', subchara: 'mirai-01', name: 'name_ginga', keyword: '', event:'', text: '買いたくても“買えない理由”があるんじゃないでしょうか' },
                        { step: 4, back: 'back-08', mainchara: 'mirai-01', mainchara2:'', subchara: 'ginga-01', name: 'name_mirai', keyword: '', event:'', text: 'さすがに屋台だから高額ということはないでしょうし、おもりがついているとはいえ、店に並んでいるのだから持ち帰れないということはなさそうよね' },
                        { step: 4, back: 'back-08', mainchara: 'nagisa-01', mainchara2:'', subchara: 'mirai-01', name: 'name_nagisa', keyword: '', event:'', text: '達磨さんが“倒れない”から“買えない”ってことはないですか？' },
                        { step: 4, back: 'back-08', mainchara: 'mirai-01', mainchara2:'', subchara: 'nagisa-01', name: 'name_mirai', keyword: '', event:'sentaku5', text: 'たしかにその可能性はあるわ。その条件を満たす屋台といえば・・・' },


                        { step: 4, back: 'back-08', mainchara: 'mirai-02', mainchara2:'', subchara: 'nagisa-01', name: 'name_mirai', keyword: '', event:'', text: '“射的”の屋台じゃないかしら！' },
                        { step: 4, back: 'back-08', mainchara: 'ginga-01', mainchara2: '', subchara: 'mirai-02', name: 'name_ginga', keyword: '', event: '', text: 'たしかに！“倒れない”から“買えない”ということは、裏を返せば<br>"倒れる"と"買える"お店ですからね' },
                        { step: 4, back: 'back-08', mainchara: 'ginga-01', mainchara2:'', subchara: 'mirai-02', name: 'name_ginga', keyword: '', event:'', text: 'さすがミライお嬢様です' },
                        { step: 4, back: 'back-08', mainchara: 'nagisa-02', mainchara2: '', subchara: 'ginga-01', name: 'name_nagisa', keyword: '', event: '', text: '調べてみたらこの神社内で射的の屋台が1件見つかりました！境内の裏の方にあります！' },
                        { step: 4, back: 'back-08', mainchara: 'mirai-02', mainchara2:'', subchara: 'nagisa-02', name: 'name_mirai', keyword: '', event:'', text: 'じゃあさっそくその場所に向かいましょう！' },
                        { step: 4, back: 'back-09', mainchara: 'nagisa-02', mainchara2:'', subchara: 'mirai-02', name: 'name_nagisa', keyword: '', event:'', text: 'ありました！切望の達磨さんです！' },
                        { step: 4, back: 'back-09', mainchara: 'tensyu-01', mainchara2:'', subchara: 'nagisa-02', name: 'name_tensyu', keyword: '', event:'', text: 'お嬢ちゃんたち、この達磨が欲しいのかい？' },
                        { step: 4, back: 'back-09', mainchara: 'mirai-01', mainchara2:'', subchara: 'tensyu-01', name: 'name_mirai', keyword: '', event:'', text: 'ええ。そうですわ。譲っていただけないかしら？' },
                        { step: 4, back: 'back-09', mainchara: 'tensyu-01', mainchara2:'', subchara: 'mirai-01', name: 'name_tensyu', keyword: '', event:'', text: 'これはここの屋台を始めて10年、だれにも倒されなかった達磨だ。' },
                        { step: 4, back: 'back-09', mainchara: 'tensyu-01', mainchara2:'', subchara: 'mirai-01', name: 'name_tensyu', keyword: '', event:'', text: 'そう簡単には譲るわけにはいかないな' },
                        { step: 4, back: 'back-09', mainchara: 'ginga-01', mainchara2:'', subchara: 'tensyu-01', name: 'name_ginga', keyword: '', event:'', text: 'お嬢様。私にお任せください。射的で取ります' },
                        { step: 4, back: 'back-09', mainchara: 'tensyu-01', mainchara2:'', subchara: 'ginga-01', name: 'name_tensyu', keyword: '', event:'', text: 'おっ、射的やるんだね。先に言っておくけど、あの達磨は本当に倒れないよ' },
                        { step: 4, back: 'back-09', mainchara: 'ginga-01', mainchara2:'', subchara: 'tensyu-01', name: 'name_ginga', keyword: '', event:'', text: '問題ないです。では、銃と弾をかしてください' },
                        { step: 4, back: 'back-09', mainchara: 'tensyu-01', mainchara2:'', subchara: 'ginga-01', name: 'name_tensyu', keyword: '', event:'', text: 'はいよ' },
                        { step: 4, back: 'back-09', mainchara: '', mainchara2:'', subchara: '', name: '', keyword: '', event:'', text: '店主が銀河に射的用の銃と弾を渡す' },
                        { step: 4, back: 'back-09', mainchara: 'ginga-01', mainchara2:'', subchara: 'tensyu-01', name: 'name_ginga', keyword: '', event:'', text: 'おもりの位置は理解しました。重心がぶれる位置はあの場所ですね' },
                        { step: 4, back: 'back-09', mainchara: '', mainchara2:'', subchara: '', name: '', keyword: '', event:'', text: '<span class="blue">ッダダダダダダッッ</span>'},
                        { step: 4, back: 'back-09', mainchara: 'tensyu-01', mainchara2:'', subchara: '', name: 'name_tensyu', keyword: '', event:'', text: '！？何が起きている！？目にもとまらぬ速さで弾を装填、発射している！？' },
                        { step: 4, back: 'back-09', mainchara: 'ginga-01', mainchara2:'', subchara: 'tensyu-01', name: 'name_ginga', keyword: '', event:'', text: '最後の一発・・・！' },
                        { step: 4, back: 'back-09', mainchara: '', mainchara2:'', subchara: '', name: '', keyword: '', event:'', text: '<span class="blue">バーーンッ・・・ゴトンッ<span class="blue">'},
                        { step: 4, back: 'back-09', mainchara: 'nagisa-02', mainchara2:'', subchara: 'ginga-01', name: 'name_nagisa', keyword: '', event:'', text: 'すごい！落ちました！' },
                        { step: 4, back: 'back-09', mainchara: 'tensyu-01', mainchara2:'', subchara: 'nagisa-02', name: 'name_tensyu', keyword: '', event:'', text: 'こりゃまいったわ。お兄さんすごいわ、この達磨、遠慮なく持って行きな' },
                        { step: 4, back: 'back-09', mainchara: 'ginga-01', mainchara2:'', subchara: 'tensyu-01', name: 'name_ginga', keyword: '', event:'', text: '倒れないとおっしゃっていたので、落とさせて頂きました' },
                        { step: 4, back: 'back-09', mainchara: 'mirai-02', mainchara2:'', subchara: 'ginga-01', name: 'name_mirai', keyword: '', event:'', text: 'さすが銀河ね。アメリカで行われている全米射撃大会で優勝した実績があるだけのことはあるわ' },
                        { step: 4, back: 'back-09', mainchara: 'ginga-01', mainchara2:'', subchara: 'mirai-02', name: 'name_ginga', keyword: '', event:'', text: 'なかなかこの特技をお披露目できる機会がなかったのですが、お役に立てて光栄です' },
                        { step: 4, back: 'back-09', mainchara: 'nagisa-02', mainchara2:'', subchara: 'ginga-01', name: 'name_nagisa', keyword: '', event:'', text: '・・・この達磨さんは私がお預かりします！では、甘味処に戻りましょう！' },
                        { step: 4, back: 'back-07', mainchara: 'mirai-02', mainchara2:'', subchara: 'nagisa-02', name: 'name_mirai', keyword: '', event:'', text: 'あなたが欲しがっていた『切望の達磨』よ！' },
                        { step: 4, back: 'back-07', mainchara: 'joren-01', mainchara2:'', subchara: 'mirai-02', name: 'name_joren', keyword: '', event:'', text: 'おー！！本当に持ってきてくれたのか！' },
                        { step: 4, back: 'back-07', mainchara: 'joren-01', mainchara2:'', subchara: 'mirai-02', name: 'name_joren', keyword: '', event:'', text: 'じゃあ早速願いを書いてっと' },
                        { step: 4, back: 'back-07', mainchara: '', mainchara2:'', subchara: '', name: '', keyword: '', event:'', text: '浄蓮は達磨に「有名人になる」と書き込んだ' },
                        { step: 4, back: 'back-07', mainchara: 'joren-01', mainchara2:'', subchara: 'mirai-02', name: 'name_joren', keyword: '', event:'', text: 'よーしこれでOK、じゃあ君たち帰っていいよ' },
                        { step: 4, back: 'back-07', mainchara: 'mirai-03', mainchara2:'', subchara: 'joren-01', name: 'name_mirai', keyword: '', event:'', text: 'この達磨持ってきたのだから、成川輪廻の居場所を教えなさい！' },
                        { step: 4, back: 'back-07', mainchara: 'joren-01', mainchara2:'', subchara: 'mirai-03', name: 'name_joren', keyword: '', event:'', text: 'あーそうだった。成川輪廻ならいつも江須派川の河川敷にいるよ' },
                        { step: 4, back: 'back-07', mainchara: 'joren-01', mainchara2:'', subchara: 'mirai-03', name: 'name_joren', keyword: '', event:'', text: 'よく太陽の絵をかいてるから、この時間だと一番西のあたりにいるんじゃないかな' },
                        { step: 4, back: 'back-07', mainchara: 'mirai-01', mainchara2:'', subchara: 'joren-01', name: 'name_mirai', keyword: '', event:'', text: 'ありがとう。早速向かってみるわ' },
                        { step: 4, back: 'back-10', mainchara: 'mirai-01', mainchara2:'', subchara: '', name: 'name_mirai', keyword: '', event:'', text: 'はぁぁ疲れたわね。もうすぐ日が暮れそうだわ。<br>それにしてもあの達磨、本当に願いが叶うのかしら' },
                        { step: 4, back: 'back-10', mainchara: 'nagisa-02', mainchara2:'', subchara: 'mirai-01', name: 'name_nagisa', keyword: '', event:'', text: '見てくださいお嬢様！' },
                        { step: 4, back: 'back-10', mainchara: '', mainchara2:'', subchara: '', name: '', keyword: '', event:'', text: '渚は持っていた紙袋から達磨を取り出す' },
                        { step: 4, back: 'back-10', mainchara: 'nagisa-02', mainchara2:'', subchara: 'mirai-01', name: 'name_nagisa', keyword: '', event:'', text: 'じゃーん！切望の達磨さんです！' },
                        { step: 4, back: 'back-10', mainchara: 'mirai-01', mainchara2:'', subchara: 'nagisa-02', name: 'name_mirai', keyword: '', event:'', text: 'えっ、どうしたのそれ？達磨は浄蓮に渡したはずじゃないの？' },
                        { step: 4, back: 'back-10', mainchara: 'nagisa-02', mainchara2:'', subchara: 'mirai-01', name: 'name_nagisa', keyword: '', event:'', text: '実は渡した方は私が用意した偽物です！<br>３Dプリンターでちゃちゃっと模造品を用意しちゃいました！' },
                        { step: 4, back: 'back-10', mainchara: 'mirai-01', mainchara2:'', subchara: 'nagisa-02', name: 'name_mirai', keyword: '', event:'', text: 'じゃあ、こっちが本物？' },
                        { step: 4, back: 'back-10', mainchara: 'nagisa-02', mainchara2:'', subchara: 'mirai-01', name: 'name_nagisa', keyword: '', event:'', text: 'そうなんです！渡した偽物は何故かおもりを再現しても立ち続けるということはなかったのですが、本物は不思議と倒れないんです' },
                        { step: 4, back: 'back-10', mainchara: 'nagisa-02', mainchara2:'', subchara: 'mirai-01', name: 'name_nagisa', keyword: '', event:'', text: 'ほんとにエスパー的な力が込められているかもしれません' },
                        { step: 4, back: 'back-10', mainchara: 'mirai-01', mainchara2:'', subchara: 'nagisa-02', name: 'name_mirai', keyword: '', event:'', text: 'じゃあ浄蓮脚の願いは自力で叶えるしかないのね（すこし笑いながら）' },
                        { step: 4, back: 'back-10', mainchara: 'nagisa-01', mainchara2:'', subchara: 'mirai-01', name: 'name_nagisa', keyword: '', event:'', text: '浄蓮さんに渡して、気が変わって『僕のメイドになってほしい』とか書かれても嫌だったので' },
                        { step: 4, back: 'back-10', mainchara: 'nagisa-02', mainchara2:'', subchara: 'mirai-01', name: 'name_nagisa', keyword: '', event:'', text: '勝手に偽物を渡しちゃいました！' },
                        { step: 4, back: 'back-10', mainchara: 'mirai-01', mainchara2:'', subchara: 'nagisa-02', name: 'name_mirai', keyword: '', event:'', text: 'なるほどね。あら、よくみるとこの達磨、もう願いが書いてあるわ' },
                        { step: 4, back: 'back-10', mainchara: '', mainchara2:'', subchara: '', name: '', keyword: '', event:'', text: '達磨には「晴れが続きますように」と書かれている' },
                        { step: 4, back: 'back-10', mainchara: 'nagisa-02', mainchara2:'', subchara: 'mirai-01', name: 'name_nagisa', keyword: '', event:'', text: '最近雨ばかりでお嬢様が退屈にしていらっしゃったので、今日みたいな晴れが続くと良いなと思って私が書きました！' },
                        { step: 4, back: 'back-10', mainchara: 'mirai-02', mainchara2: '', subchara: 'nagisa-02', name: 'name_mirai', keyword: '', event: '', text: 'とても素敵ね' },

                        { step: 4, back: 'back-10', mainchara: '', mainchara2:'', subchara: '', name: '', keyword: '', event:'', text: '<span class="red">渚は達磨を紙袋に戻した</span>'},
                        { step: 5, back: 'back-11', mainchara: 'ginga-01', mainchara2: '', subchara: '', name: 'name_ginga', keyword: '', event: '', text: '到着しました。お嬢様。川沿いで絵を描いている人物がいます' },
                        { step: 5, back: 'back-11', mainchara: 'mirai-01', mainchara2:'', subchara: 'ginga-01', name: 'name_mirai', keyword: '', event:'', text: 'あいつが成川輪廻ね' },
                        { step: 5, back: 'back-11', mainchara: '', mainchara2:'', subchara: '', name: '', keyword: '', event:'', text: 'ミライは走って河辺にいる人に近づく' },
                        { step: 5, back: 'back-11', mainchara: 'mirai-01', mainchara2:'', subchara: '', name: 'name_mirai', keyword: '', event:'', text: '見つけたわ！成川輪廻！！' },
                        { step: 5, back: 'back-11', mainchara: 'mirai-03', mainchara2:'', subchara: '', name: 'name_mirai', keyword: '', event:'', text: '私たちを2度も閉じ込めた犯人はあなたね！！！' },
                        { step: 5, back: 'back-11', mainchara: 'narikawa-01', mainchara2:'', subchara: 'mirai-03', name: 'name_narikawa', keyword: '', event:'', text: 'なんで諸君らがここに！アトリエに閉じ込めたはずじゃ！' },
                        { step: 5, back: 'back-11', mainchara: 'mirai-03', mainchara2:'', subchara: 'narikawa-01', name: 'name_mirai', keyword: '', event:'', text: '何とか抜け出して、あなたを探しに来たのよ！' },
                        { step: 5, back: 'back-11', mainchara: 'narikawa-01', mainchara2:'', subchara: 'mirai-03', name: 'name_narikawa', keyword: '', event:'', text: 'ぐぬぬ・・・ここまで来られたからには仕方ない・・・' },
                        { step: 5, back: 'back-11', mainchara: 'mirai-03', mainchara2:'', subchara: 'narikawa-01', name: 'name_mirai', keyword: '', event:'', text: '私たちをこんな目に合わせて、いったい何が目的ですの？！！' },
                        { step: 5, back: 'back-11', mainchara: 'narikawa-01', mainchara2:'', subchara: 'mirai-03', name: 'name_narikawa', keyword: '', event:'', text: '吾輩は生まれ変わった筆塚石生として“本物の筆塚石生の代わりに”生きていくつもりだった' },
                        { step: 5, back: 'back-11', mainchara: 'narikawa-01', mainchara2:'', subchara: 'mirai-03', name: 'name_narikawa', keyword: '', event:'', text: 'なのに諸君らが変な詮索を始めたせいで計画が狂おうとしていた' },
                        { step: 5, back: 'back-11', mainchara: 'mirai-01', mainchara2:'', subchara: 'narikawa-01', name: 'name_mirai', keyword: '', event:'', text: '何をワケの分からないことを言っているの！？' },
                        { step: 5, back: 'back-11', mainchara: 'narikawa-01', mainchara2:'', subchara: 'mirai-01', name: 'name_narikawa', keyword: '', event:'', text: 'これ以上吾輩の邪魔をするのならば・・・諸君らに鉄槌を下す' },
                        { step: 5, back: 'back-11', mainchara: '', mainchara2:'', subchara: '', name: '', keyword: '', event:'', text: '成川は突然、3人に向けて親指を立てて、<br>もっていたキャンバスに筆で勢いよく絵を描き始める' },
                        { step: 5, back: 'back-11', mainchara: 'narikawa-01', mainchara2:'', subchara: '', name: 'name_narikawa', keyword: '', event:'', text: 'ぬうぉぉぉぉ（カキカキカキ）' },
                        { step: 5, back: 'back-11', mainchara: 'mirai-01', mainchara2:'', subchara: 'narikawa-01', name: 'name_mirai', keyword: '', event:'', text: '何をしてますの？？' },
                        { step: 5, back: 'back-11', mainchara: 'nagisa-03', mainchara2:'', subchara: 'mirai-01', name: 'name_nagisa', keyword: '', event:'', text: 'もしかして私たち、被写体にされてます！？' },
                        { step: 5, back: 'back-11', mainchara: 'ginga-02', mainchara2:'', subchara: 'nagisa-03', name: 'name_ginga', keyword: '', event:'', text: 'こやつ何やら怪しいです・・・気を付けてくださいお嬢様！' },
                        { step: 5, back: 'back-11', mainchara: 'narikawa-01', mainchara2:'', subchara: 'ginga-02', name: 'name_narikawa', keyword: '', event:'', text: 'よーーーーしできた！！これで終わりだ！！' },
                        { step: 5, back: 'back-11', mainchara: '', mainchara2: '', subchara: '', name: '', keyword: '', event: '', text: '成川が描き終えたキャンバスを3人に見せる。<br>すると、銀河と渚がキャンバスの中に吸い込まれていく' },
                        { step: 5, back: 'back-11', mainchara: 'nagisa-ginga', mainchara2: '', subchara: '', name: 'name_nagisa_ginga', keyword: '', event:'', text: 'ミライお嬢様ーーー！！！' },
                        { step: 5, back: 'back-11', mainchara: 'mirai-01', mainchara2:'', subchara: '', name: 'name_mirai', keyword: '', event:'', text: '銀河ー！渚！！' },
                        { step: 5, back: 'back-11', mainchara: 'narikawa-01', mainchara2:'', subchara: 'mirai-01', name: 'name_narikawa', keyword: '', event:'', text: 'よーーーしこれでいいだろう。<br>おや？一人取り残された人がいるようだね' },
                        { step: 5, back: 'back-11', mainchara: 'mirai-03', mainchara2:'', subchara: 'narikawa-01', name: 'name_mirai', keyword: '', event:'', text: '二人をどこにやったの！！' },
                        { step: 5, back: 'back-11', mainchara: 'narikawa-01', mainchara2:'', subchara: 'mirai-03', name: 'name_narikawa', keyword: '', event:'', text: 'さっきの二人ならここさ' },
                        { step: 5, back: 'back-11', mainchara: '', mainchara2: '', subchara: '', name: '', keyword: '', event: '', text: '成川が描き終えた絵画をもう一度ミライに見せる。<br><span class="red">イラストの中には銀河と渚がいた</span>'},
                        { step: 5, back: 'back-11', mainchara: 'mirai-03', mainchara2:'', subchara: '', name: 'name_mirai', keyword: '', event:'', text: '二人とも絵になってるじゃない！？' },
                        { step: 5, back: 'back-11', mainchara: 'narikawa-01', mainchara2: '', subchara: 'mirai-03', name: 'name_narikawa', keyword: '', event: '', text: '二人は一生仲良くこの絵の中さ。邪魔するものはみなこうしてきたのだよ！' },
                        { step: 5, back: 'back-11', mainchara: 'mirai-03', mainchara2:'', subchara: 'narikawa-01', name: 'name_mirai', keyword: '', event:'', text: 'なんてひどいことを！' },
                        { step: 5, back: 'back-11', mainchara: 'narikawa-01', mainchara2:'', subchara: 'mirai-03', name: 'name_narikawa', keyword: '', event:'', text: '君は閉じ込められなくて運が良かったね。本当は3人まとめて閉じ込めたかったけれど' },
                        { step: 5, back: 'back-11', mainchara: 'narikawa-01', mainchara2:'', subchara: 'mirai-03', name: 'name_narikawa', keyword: '', event:'', text: 'どうやらこの付け焼刃の絵じゃ二人しか閉じ込められなかったようだ' },
                        { step: 5, back: 'back-11', mainchara: 'narikawa-01', mainchara2:'', subchara: 'mirai-03', name: 'name_narikawa', keyword: '', event:'', text: 'でもまあいい、君は一人寂しくこっちの絵の中に閉じ込めてあげよう' },
                        { step: 5, back: 'back-11', mainchara: '', mainchara2:'', subchara: '', name: '', keyword: '', event:'', text: '成川はキャンバスに新たな絵を描き始め、ミライを閉じ込めようとする' },


                        { step: 6, back: 'back-11', mainchara: '', mainchara2: '', subchara: '', name: '', keyword: '', event: 'ENDING1', text: 'ミライが銀河と渚が閉じ込められたキャンバスをミライが逆さにすると、突然雨が降り出した' },
                        { step: 6, back: 'back-11', mainchara: 'narikawa-02', mainchara2:'', subchara: '', name: 'name_narikawa', keyword: '', event:'', text: '何故このタイミングで急に雨が！！！これじゃあ絵が描けない！！' },
                        { step: 6, back: 'back-11', mainchara: 'mirai-01', mainchara2: '', subchara: 'narikawa-02', name: 'name_mirai', keyword: '', event:'', text: '二人が閉じ込められたのは、きっとその筆が原因ね！' },
                        { step: 6, back: 'back-11', mainchara: '', mainchara2: '', subchara: '', name: '', keyword: '', event: '', text: 'ミライは慌てている成川に駆け寄り成川から筆を取り上げる。<br>そして、成川の目の前でその筆を真っ二つに折った' },
                        { step: 6, back: 'back-11', mainchara: 'narikawa-05', mainchara2: '', subchara: 'mirai-01', name: 'name_narikawa', keyword: '', event:'', text: 'なんてことだーー！！吾輩の筆がああああ・・・' },
                        { step: 6, back: 'back-11', mainchara: '', mainchara2:'', subchara: '', name: '', keyword: '', event:'', text: '筆を折ったことでキャンバスが消えてなくなり、銀河と渚が現れた' },
                        { step: 6, back: 'back-11', mainchara: 'nagisa-02', mainchara2:'', subchara: '', name: 'name_nagisa', keyword: '', event:'', text: 'わーー！！絵の中から出られました！' },
                        { step: 6, back: 'back-11', mainchara: 'ginga-01', mainchara2: '', subchara: 'nagisa-02', name: 'name_ginga', keyword: '', event:'', text: 'お嬢様！絵の中から活躍を見ておりました！流石でございます！！' },
                        { step: 6, back: 'back-11', mainchara: 'mirai-03', mainchara2: '', subchara: 'ginga-01', name: 'name_mirai', keyword: '', event:'', text: '成川輪廻覚悟しなさい！' },
                        { step: 6, back: 'back-11', mainchara: 'narikawa-05', mainchara2: '', subchara: 'mirai-03', name: 'name_narikawa', keyword: '', event:'', text: 'もう・・・この筆がないんじゃ僕の計画も終わりだ・・・' },
                        { step: 6, back: 'back-11', mainchara: 'narikawa-05', mainchara2: '', subchara: 'mirai-03', name: 'name_narikawa', keyword: '', event: '', text: '仕方がない、あきらめて全てを話そう・・・<br>筆塚石生は絵の中に閉じ込めて、アトリエに自画像として隠している' },
                        { step: 6, back: 'back-11', mainchara: 'mirai-01', mainchara2: '', subchara: 'narikawa-05', name: 'name_mirai', keyword: '', event: '', text: 'え！？あの部屋の中に筆塚イルヨが居たの！？' },
                        { step: 6, back: 'back-11', mainchara: 'narikawa-05', mainchara2: '', subchara: 'mirai-01', name: 'name_narikawa', keyword: '', event:'', text: '吾輩が筆塚石生として生きていくために彼を絵の中に閉じ込めたのだ・・・' },
                        { step: 6, back: 'back-11', mainchara: 'mirai-03', mainchara2: '', subchara: 'narikawa-05', name: 'name_mirai', keyword: '', event:'', text: 'じゃあ、筆塚イタヨ軟禁の犯人として警察に出頭するのよ！' },
                        { step: 6, back: 'back-11', mainchara: 'narikawa-05', mainchara2: '', subchara: 'mirai-03', name: 'name_narikawa', keyword: '', event:'', text: '無念・・・' },
                        { step: 6, back: 'back-11', mainchara: '', mainchara2:'', subchara: '', name: '', keyword: '', event:'', text: 'そういうと成川は近くの交番へ入っていった' },
                        { step: 6, back: 'back-11', mainchara: '', mainchara2: '', subchara: '', name: '', keyword: '', event: '', text: '3人とも移動するために車に戻る。<br>車には絵画にされていた館長が元に戻り座席に座っていた' },
                        { step: 6, back: 'back-11', mainchara: 'mirai-01', mainchara2:'', subchara: '', name: 'name_mirai', keyword: '', event:'', text: '館長！？あなたもしかして成川輪廻に絵画にされていたの！？' },
                        { step: 6, back: 'back-11', mainchara: 'kantyou-01', mainchara2: '', subchara: 'mirai-01', name: 'name_kantyou', keyword: '', event:'', text: 'そうなんです。あなたがたに何かをしようとしていた成川輪廻を見つけて止めようとしたら逆に私が絵に閉じ込められてしまいました・・・' },
                        { step: 6, back: 'back-11', mainchara: 'kantyou-01', mainchara2: '', subchara: 'mirai-01', name: 'name_kantyou', keyword: '', event: '', text: '隠す場所がなかったのか、私は無理やり車に押し込められていたのです ' },
                        { step: 6, back: 'back-11', mainchara: 'mirai-01', mainchara2: '', subchara: 'kantyou-01', name: 'name_mirai', keyword: '', event: '', text: 'なるほど・・・それは災難だったわね' },
                        { step: 6, back: 'back-11', mainchara: 'mirai-02', mainchara2: '', subchara: 'kantyou-01', name: 'name_mirai', keyword: '', event:'', text: 'でもおかげで色々と事件は解決したわ！今からアトリエに向かうわよ！' },
                        { step: 6, back: 'back-05', mainchara: '', mainchara2:'', subchara: '', name: '', keyword: '', event:'', text: '<span class="blue">～ピンポーン～</span>'},
                        { step: 6, back: 'back-05', mainchara: 'mirai-01', mainchara2:'', subchara: '', name: 'name_mirai', keyword: '', event:'', text: '筆塚イソウ！居るんでしょ！中に入るわよ！' },
                        { step: 6, back: 'back-05', mainchara: '', mainchara2:'', subchara: '', name: '', keyword: '', event:'', text: '<span class="blue">ガチャン（扉を開ける音）</span>'},
                        { step: 6, back: 'back-05', mainchara: '', mainchara2:'', subchara: '', name: '', keyword: '', event:'', text: 'ミライは扉を開けて中に入る' },
                        { step: 6, back: 'back-06', mainchara: 'mirai-01', mainchara2:'', subchara: '', name: 'name_mirai', keyword: '', event:'', text: '居たわね。あなたを探していたのよ' },
                        { step: 6, back: 'back-06', mainchara: 'fudetsuka-01', mainchara2: '', subchara: 'mirai-01', name: 'name_fudetsuka', keyword: '', event:'', text: 'おおお助かった。見つけてくれてありがとう' },
                        { step: 6, back: 'back-06', mainchara: 'fudetsuka-01', mainchara2: '', subchara: 'mirai-01', name: 'name_fudetsuka', keyword: '', event:'', text: '絵画の中から、君たちが私を探してくれていたのをずっと見ていたよ' },
                        { step: 6, back: 'back-06', mainchara: 'mirai-02', mainchara2: '', subchara: 'fudetsuka-01', name: 'name_mirai', keyword: '', event:'', text: '私が成川輪廻をこらしめて絵画の中から救ってあげたのよ（誇らしげ）' },
                        { step: 6, back: 'back-06', mainchara: 'fudetsuka-01', mainchara2: '', subchara: 'mirai-02', name: 'name_fudetsuka', keyword: '', event:'', text: '本当にありがとう。館長も心配をかけてすまなかった' },
                        { step: 6, back: 'back-06', mainchara: 'kantyou-01', mainchara2: '', subchara: 'fudetsuka-01', name: 'name_kantyou', keyword: '', event: '', text: 'いえいえ、ご無事で何よりです' },
                        { step: 6, back: 'back-06', mainchara: 'kantyou-01', mainchara2: '', subchara: 'fudetsuka-01', name: 'name_kantyou', keyword: '', event:'', text: '美術館に戻って明日から展示を再開しましょう！' },
                        { step: 6, back: 'back-06', mainchara: 'nagisa-02', mainchara2: '', subchara: 'kantyou-01', name: 'name_nagisa', keyword: '', event:'', text: '私たちも事務所に戻りましょう！' },
                        { step: 6, back: 'back-06', mainchara: 'mirai-02', mainchara2: '', subchara: 'nagisa-02', name: 'name_mirai', keyword: '', event:'', text: 'そうね！' },
                        { step: 6, back: 'back-01', mainchara: '', mainchara2: '', subchara: '', name: 'name_radio', keyword: '', event: '', text: '<span class="blue">ニュースです。各地で謎の失踪事件が相次いでいましたが、<br>一斉に行方不明者が発見されるという珍事が発生しました</span>'},
                        { step: 6, back: 'back-01', mainchara: '', mainchara2:'', subchara: '', name: 'name_radio', keyword: '', event:'', text: '<span class="blue">行方不明だった人々は口をそろえて画家が犯人だと言っているそうです</span>'},
                        { step: 6, back: 'back-01', mainchara: '', mainchara2: '', subchara: '', name: 'name_radio', keyword: '', event: '', text: '<span class="blue">次のニュースです。中止となっていた江須派美術館のイベントですが、<br>明日から問題なく再開すると発表されました。今回、筆塚石生～～～～～</span>'},
                        { step: 6, back: 'back-01', mainchara: 'mirai-02', mainchara2:'', subchara: '', name: 'name_mirai', keyword: '', event:'', text: '疲れたわね！無事事件が解決して良かったわ！' },
                        { step: 6, back: 'back-01', mainchara: 'nagisa-02', mainchara2: '', subchara: 'mirai-02', name: 'name_nagisa', keyword: '', event:'', text: '私が持っていた達磨をキャンバスごとひっくりかえして、<br>逆の願いを叶えるなんて、まさに逆転の発想でした！！' },
                        { step: 6, back: 'back-01', mainchara: 'mirai-02', mainchara2: '', subchara: 'nagisa-0', name: 'name_mirai', keyword: '', event:'', text: '今回はみんなの活躍があってこその事件解決だったわ。<br>こちらこそ本当にありがとう' },
                        { step: 6, back: 'back-01', mainchara: 'ginga-01', mainchara2: '', subchara: 'mirai-02', name: 'name_ginga', keyword: '', event:'', text: '今後もミライお嬢様のお手伝いをさせて頂きます！' },
                        { step: 6, back: 'back-01', mainchara: 'mirai-02', mainchara2: '', subchara: 'ginga-01', name: 'name_mirai', keyword: '', event:'', text: 'これからも沢山の事件を解決していきましょう！' },
                        { step: 6, back: 'back-01', mainchara: 'nagisa-ginga', mainchara2: '', subchara: '', name: 'name_nagisa_ginga', keyword: '', event:'', text: 'かしこまりました！お嬢様！' },
                        { step: 6, back: 'back-01', mainchara: '', mainchara2:'', subchara: '', name: '', keyword: '', event:'', text: 'こうして事件は無事解決し、平凡な日常が戻ってきました。さて、次はいったいどんな事件が彼女らを待ち受けているのでしょうか？' },
                        { step: 6, back: 'back-01', mainchara: '', mainchara2:'', subchara: '', name: '', keyword: '', event:'', text: '今後のミライお嬢様の活躍に乞うご期待です' },
                        { step: 6, back: 'back-01', mainchara: 'mirai-01', mainchara2:'', subchara: '', name: 'name_mirai', keyword: '', event:'', text: 'そういえば、人を絵に閉じ込めるあの筆って一体何だったのかしら？' },
                        { step: 6, back: 'back-01', mainchara: '', mainchara2: '', subchara: '', name: 'name_hatena', keyword: '17', event:'END', text: 'なるほど。あの『<span class="keyword"  id="keyword" >執念の筆</span>』が壊されたと。どうやらこの街には<br>　‟不思議な力”が使える探偵さんがいるようですね・・・'},
                     /*   { step: 6, back: 'back-01', mainchara: '', mainchara2:'', subchara: '', name: '', keyword: '', event:'END', text: '' },*/
                      
                        { step: 7, back: 'back-11', mainchara: 'narikawa-02', mainchara2: '', subchara: '', name: 'name_narikawa', keyword: '', event:'ENDING2', text: 'はぁ・・・はぁ・・・' },
                        { step: 7, back: 'back-11', mainchara: '', mainchara2:'', subchara: '', name: '', keyword: '', event:'', text: '成川輪廻はひどく消耗したようだ' },
                        { step: 7, back: 'back-11', mainchara: 'mirai-02', mainchara2:'', subchara: '', name: 'name_mirai', keyword: '', event:'', text: '今がチャンスですわ！！' },
                        { step: 7, back: 'back-11', mainchara: '', mainchara2:'', subchara: '', name: '', keyword: '', event:'', text: 'ミライは成川輪廻に瞬時に近づき、筆を奪った' },
                        { step: 7, back: 'back-11', mainchara: 'mirai-01', mainchara2:'', subchara: '', name: 'name_mirai', keyword: '', event:'', text: '観念しなさい！' },
                        { step: 7, back: 'back-11', mainchara: '', mainchara2:'', subchara: '', name: '', keyword: '', event:'', text: '<span class="blue">バキッ</span>'},
                        { step: 7, back: 'back-11', mainchara: '', mainchara2:'', subchara: '', name: '', keyword: '', event:'', text: '筆を折ったことでキャンバスが消えてなくなり、銀河と渚が現れた' },
                        { step: 7, back: 'back-11', mainchara: 'nagisa-02', mainchara2:'', subchara: '', name: 'name_nagisa', keyword: '', event:'', text: 'わーー！！絵の中から出られました！' },
                        { step: 7, back: 'back-11', mainchara: 'ginga-01', mainchara2: '', subchara: 'nagisa-02', name: 'name_ginga', keyword: '', event: '', text: 'お嬢様！絵の中から活躍を見ておりました！<br>ぶっ続けで100問解いてしまうとは・・・<br>流石でございます！！' },
                        { step: 7, back: 'back-11', mainchara: 'mirai-03', mainchara2: '', subchara: 'ginga-01', name: 'name_mirai', keyword: '', event:'', text: '成川輪廻覚悟しなさい！私たちを閉じ込めた容疑で逮捕するわ！' },
                        { step: 7, back: 'back-11', mainchara: 'narikawa-05', mainchara2: '', subchara: 'mirai-03', name: 'name_narikawa', keyword: '', event: '', text: 'もう・・・この筆がないんじゃ吾輩の計画も終わりだ・・・' },
                        { step: 7, back: 'back-11', mainchara: 'narikawa-05', mainchara2: '', subchara: 'mirai-03', name: 'name_narikawa', keyword: '', event:'', text: '仕方がない、あきらめて全てを話そう・・・<br>筆塚石生は絵の中に閉じ込めて、アトリエに自画像として飾ってある' },
                        { step: 7, back: 'back-11', mainchara: 'mirai-01', mainchara2: '', subchara: 'narikawa-05', name: 'name_mirai', keyword: '', event:'', text: 'え！？あの絵の中に筆塚イルヨが居たの！？' },
                        { step: 7, back: 'back-11', mainchara: 'narikawa-05', mainchara2: '', subchara: 'mirai-01', name: 'name_narikawa', keyword: '', event:'', text: '吾輩が筆塚石生として生きていくために彼を絵の中に閉じ込めたのだ・・・' },
                        { step: 7, back: 'back-11', mainchara: 'mirai-03', mainchara2: '', subchara: 'narikawa-05', name: 'name_mirai', keyword: '', event:'', text: 'じゃあ、筆塚イタヨ軟禁の犯人として警察に出頭するのよ！' },
                        { step: 7, back: 'back-11', mainchara: 'narikawa-05', mainchara2: '', subchara: 'mirai-03', name: 'name_narikawa', keyword: '', event:'', text: '無念・・・' },
                        { step: 7, back: 'back-11', mainchara: '', mainchara2:'', subchara: '', name: '', keyword: '', event:'', text: 'そういうと成川は近くの交番へ入っていった' },
                        { step: 7, back: 'back-11', mainchara: 'ginga-01', mainchara2:'', subchara: '', name: 'name_ginga', keyword: '', event:'', text: '筆塚石生がいるアトリエに行きましょう' },
                        { step: 7, back: 'back-11', mainchara: 'mirai-01', mainchara2: '', subchara: 'ginga-01', name: 'name_mirai', keyword: '', event:'', text: 'そうね' },
                        { step: 7, back: 'back-11', mainchara: '', mainchara2:'', subchara: '', name: '', keyword: '', event:'', text: '3人とも移動するために車に戻る<br>車には絵画にされていた館長が元に戻り座席に座っていた' },
                        { step: 7, back: 'back-11', mainchara: 'mirai-01', mainchara2:'', subchara: '', name: 'name_mirai', keyword: '', event:'', text: '館長！？あなたもしかして成川輪廻に絵画にされていたの！？' },
                        { step: 7, back: 'back-11', mainchara: 'kantyou-01', mainchara2: '', subchara: 'mirai-01', name: 'name_kantyou', keyword: '', event:'', text: 'そうなんです。あなたがたに何かをしようとしていた成川輪廻を見つけて<br>止めようとしたら逆に私が絵に閉じ込められてしまいました・・・' },
                        { step: 7, back: 'back-11', mainchara: 'kantyou-01', mainchara2: '', subchara: 'mirai-01', name: 'name_kantyou', keyword: '', event:'', text: '隠す場所がなかったのか、私は無理やり車に押し込められてたのです' },
                        { step: 7, back: 'back-11', mainchara: 'mirai-01', mainchara2: '', subchara: 'kantyou-01', name: 'name_mirai', keyword: '', event:'', text: 'なるほど・・・それは災難だったわね。' },
                        { step: 7, back: 'back-11', mainchara: 'mirai-02', mainchara2: '', subchara: 'kantyou-01', name: 'name_mirai', keyword: '', event:'', text: 'でもおかげで色々と事件は解決したわ！今からアトリエに向かうわよ！' },
                        { step: 7, back: 'back-05', mainchara: '', mainchara2:'', subchara: '', name: '', keyword: '', event:'', text: '<span class="blue">～ピンポーン～</span>'},
                        { step: 7, back: 'back-05', mainchara: 'mirai-01', mainchara2:'', subchara: '', name: 'name_mirai', keyword: '', event:'', text: '筆塚イソウ！居るんでしょ！中に入るわよ！' },
                        { step: 7, back: 'back-05', mainchara: '', mainchara2:'', subchara: '', name: '', keyword: '', event:'', text: '<span class="blue">ガチャン（扉を開ける音）</span>'},
                        { step: 7, back: 'back-05', mainchara: '', mainchara2:'', subchara: '', name: '', keyword: '', event:'', text: 'ミライは扉を開けて中に入る' },
                        { step: 7, back: 'back-06', mainchara: 'mirai-01', mainchara2:'', subchara: '', name: 'name_mirai', keyword: '', event:'', text: '居たわね。あなたを探していたのよ' },
                        { step: 7, back: 'back-06', mainchara: 'fudetsuka-01', mainchara2: '', subchara: 'mirai-01', name: 'name_fudetsuka', keyword: '', event:'', text: 'おおお助かった。見つけてくれてありがとう' },
                        { step: 7, back: 'back-06', mainchara: 'fudetsuka-01', mainchara2: '', subchara: 'mirai-01', name: 'name_fudetsuka', keyword: '', event:'', text: '絵画の中から、君たちが私を探してくれていたのをずっと見ていたよ' },
                        { step: 7, back: 'back-06', mainchara: 'mirai-02', mainchara2: '', subchara: 'fudetsuka-01', name: 'name_mirai', keyword: '', event:'', text: '私が成川輪廻をこらしめて絵画の中から救ってあげたのよ（誇らしげ）' },
                        { step: 7, back: 'back-06', mainchara: 'fudetsuka-01', mainchara2: '', subchara: 'mirai-02', name: 'name_fudetsuka', keyword: '', event:'', text: '本当にありがとう。館長も心配をかけてすまなかった' },
                        { step: 7, back: 'back-06', mainchara: 'kantyou-01', mainchara2: '', subchara: 'fudetsuka-01', name: 'name_kantyou', keyword: '', event:'', text: 'いえいえ、御無事で何よりです' },
                        { step: 7, back: 'back-06', mainchara: 'kantyou-01', mainchara2: '', subchara: 'fudetsuka-01', name: 'name_kantyou', keyword: '', event:'', text: '美術館に戻って明日から展示を再開しましょう！' },
                        { step: 7, back: 'back-06', mainchara: 'nagisa-02', mainchara2: '', subchara: 'kantyou-01', name: 'name_nagisa', keyword: '', event:'', text: '私たちも事務所に戻りましょう！' },
                        { step: 7, back: 'back-06', mainchara: 'mirai-02', mainchara2: '', subchara: 'nagisa-02', name: 'name_mirai', keyword: '', event:'', text: 'そうね！' },
                        { step: 7, back: 'back-01', mainchara: '', mainchara2: '', subchara: '', name: 'name_radio', keyword: '', event: '', text: '<span class="blue">ニュースです。各地で謎の失踪事件が相次いでいましたが、<br>一斉に行方不明者が発見されるという珍事が発生しました</span>'},
                        { step: 7, back: 'back-01', mainchara: '', mainchara2:'', subchara: '', name: 'name_radio', keyword: '', event:'', text: '<span class="blue">行方不明だった人々は口をそろえて画家が犯人だと言っているそうです。</span>'},
                        { step: 7, back: 'back-01', mainchara: '', mainchara2:'', subchara: '', name: 'name_radio', keyword: '', event:'', text: '<span class="blue">次のニュースです。中止となっていた江須派美術館のイベントですが、<br>明日から問題なく再開すると発表されました。今回、筆塚石・～～～～～</span>'},
                        { step: 7, back: 'back-01', mainchara: 'mirai-02', mainchara2:'', subchara: '', name: 'name_mirai', keyword: '', event:'', text: '疲れたわね！無事事件が解決して良かったわ！' },
                        { step: 7, back: 'back-01', mainchara: 'mirai-02', mainchara2:'', subchara: '', name: 'name_mirai', keyword: '', event:'', text: '今回はみんなの活躍があってこその事件解決だったわ。<br>こちらこそ本当にありがとう' },
                        { step: 7, back: 'back-01', mainchara: 'ginga-01', mainchara2: '', subchara: 'mirai-02', name: 'name_ginga', keyword: '', event:'', text: '今後もミライお嬢様のお手伝いをさせて頂きます！' },
                        { step: 7, back: 'back-01', mainchara: 'mirai-02', mainchara2: '', subchara: 'ginga-01', name: 'name_mirai', keyword: '', event:'', text: 'これからも沢山の事件を解決していきましょう！' },
                        { step: 7, back: 'back-01', mainchara: 'nagisa-ginga', mainchara2: '', subchara: '', name: 'name_nagisa_ginga', keyword: '', event:'', text: 'かしこまりました！お嬢様！' },
                        { step: 7, back: 'back-01', mainchara: '', mainchara2:'', subchara: '', name: '', keyword: '', event:'', text: 'こうして事件は無事解決し、平凡な日常が戻ってきました。さて、次はいったいどんな事件が彼女らを待ち受けているのでしょうか？' },
                        { step: 7, back: 'back-01', mainchara: '', mainchara2:'', subchara: '', name: '', keyword: '', event:'', text: '今後のミライお嬢様の活躍に乞うご期待です' },
                        { step: 7, back: 'back-01', mainchara: 'mirai-02', mainchara2:'', subchara: '', name: 'name_mirai', keyword: '', event:'', text: 'そういえば、人を絵に閉じ込めるあの筆って一体何だったのかしら？' },
                        { step: 7, back: 'back-01', mainchara: '', mainchara2: '', subchara: '', name: '', keyword: '17', event: 'END', text: 'なるほど。あの『<span class="keyword"  id="keyword" >執念の筆</span>』が壊されたと。どうやらこの街には<br>‟不思議な力”が使える探偵さんがいるようですね・・・' },
/*                        { step: 7, back: 'Tobecontinued', mainchara: '', mainchara2: '', subchara: '', name: '', keyword: '', event:'END', text: '' }*/

                    ],

                step03items: [
                    { item: "step3_book_03.png?2", keyno: 3, setumei: "本3", syurui:1 },
                    { item: "step3_book_02.png?2", keyno: 2, setumei: "本2", syurui: 1 },
                    { item: "step3_book_06.png?2", keyno: 6, setumei: "本6", syurui: 1},
                    { item: "step3_book_04.png?2", keyno: 4, setumei: "本4", syurui: 1},
                    { item: "step3_book_05.png?2", keyno: 5, setumei: "本5", syurui: 1 },
                    { item: "step3_book_01.png?2", keyno: 1, setumei: "本1", syurui: 1},

                    { item: "step3_nuno.png?2", keyno: 7, setumei: "布", syurui: 2},
                    { item: "step3_kuginuki.png?2", keyno: 8, setumei: "釘抜き", syurui: 3},
                    { item: "step3_canvas_nuno_pw.png?2", keyno: 10, setumei: "キャンバス", syurui: 3 },
                    { item: "step3_canvas_waku.png?2", keyno: 11, setumei: "木枠", syurui: 3 },
                ],

                step04Sentakusi:
                    [
                        {
                            ques: 1, ans: 2, text: "達磨を見た場所は？", q1: "美術館", q2: "アトリエ", q3: "車", q4:"事務所"},
                        { ques: 2, ans: 1, text: "写真に一緒に映っていたものは？", q1: "こけし", q2: "貯金箱", q3: "かざぐるま", q4: "まねきねこ" },
                        { ques: 3, ans: 4, text: "待泊神社の特徴は？", q1: "自販機が多い", q2: "賽銭箱が多い", q3: "カフェが多い", q4: "屋台が多い" },
                        { ques: 4, ans: 3, text: "達磨の特徴は？", q1: "すごく小さい", q2: "たまに動く", q3: "おもりがついている", q4: "しゃべる" },
                        { ques: 5, ans: 0, text: "条件を満たす屋台は？", q1: "", q2: "", q3: "", q4: "" },
                    ],

                step05Answer: [
                    ['C', 'シー', 'しー', 'Ｃ', '', ''],
                    ['ねずみ', 'ネズミ', '鼠', '', '', ''],
                    ['車', 'くるま', 'クルマ', '', '', ''],
                    ['ちょきん', 'チョキン', '貯金', '', '', ''],
                    ['ロケット', 'ろけっと', '', '', '', ''],
                    ['アート', 'あーと', 'art', 'ART', 'ＡＲＴ', ''],
                    ['くじら', 'クジラ', '鯨', '', '', ''],
                    ['ドラ', 'どら', '銅鑼', '', '', ''],
                    ['サーモン', 'さーもん', '鮭', 'しゃけ', 'シャケ', ''],
                    ['しほん', 'シホン', '資本', '', '', ''],
                    ['うえ', 'ウエ', '上', '', '', ''],
                    ['天下', 'てんか', 'テンカ', '添加', '点火', '転嫁'],
                    ['もちつき', 'モチツキ', '餅つき', '餅ツキ', '', ''],
                    ['アニマル', 'あにまる', '', '', '', ''],
                    ['タイル', 'たいる', '', '', '', ''],
                    ['ブレッド', 'ぶれっど', '', '', '', ''],
                    ['かつどん', 'カツドン', 'かつ丼', 'カツ丼', '', ''],
                    ['とろ', 'トロ', '', '', '', ''],
                    ['ごご', 'ゴゴ', '午後', '', '', ''],
                    ['パンダ', 'ぱんだ', '', '', '', ''],
                    ['こふん', 'コフン', '古墳', '', '', ''],
                    ['こおりみず', 'コオリミズ', '氷水', '', '', ''],
                    ['きんぐ', 'キング', '', '', '', ''],
                    ['ナンバー', 'なんばー', '', '', '', ''],
                    ['オン', 'おん', '', '', '', ''],
                    ['いち', 'イチ', '', '', '', ''],
                    ['WORD', 'ワード', 'わーど', 'word', 'ＷＯＲＤ', ''],
                    ['スライド', 'すらいど', '', '', '', ''],
                    ['くり', '栗', 'クリ', '', '', ''],
                    ['かり', 'カリ', '', '', '', ''],
                    ['あした', 'アシタ', '明日', '', '', ''],
                    ['ういんく', 'ウインク', '', '', '', ''],
                    ['ポスター', 'ぽすたー', '', '', '', ''],
                    ['よこ', 'ヨコ', '横', '', '', ''],
                    ['ちか', 'チカ', '地下', '', '', ''],
                    ['やご', 'ヤゴ', '', '', '', ''],
                    ['茨城', 'いばらき', 'イバラキ', '', '', ''],
                    ['ふきだし', 'フキダシ', '', '', '', ''],
                    ['ひな', 'ヒナ', '雛', '', '', ''],
                    ['ゆき', 'ユキ', '雪', '', '', ''],
                    ['くだ', 'クダ', '管', '', '', ''],
                    ['エト', 'えと', '干支', '', '', ''],
                    ['ミリグラム', 'みりぐらむ', '', '', '', ''],
                    ['ハード', 'はーど', '', '', '', ''],
                    ['b', 'B', 'ビー', 'びー', 'Ｂ', ''],
                    ['本', 'ほん', 'ホン', '', '', ''],
                    ['中華', 'ちゅうか', 'チュウカ', '', '', ''],
                    ['タライ', 'たらい', '', '', '', ''],
                    ['うみ', 'ウミ', '海', '', '', ''],
                    ['まいんど', 'マインド', '', '', '', ''],
                    ['パンチ', 'ぱんち', '', '', '', ''],
                    ['皿', 'さら', 'サラ', '', '', ''],
                    ['くじら', 'クジラ', '鯨', '', '', ''],
                    ['熊', 'くま', 'クマ', '', '', ''],
                    ['保育園', 'ほいくえん', 'ホイクエン', '', '', ''],
                    ['解明', 'かいめい', 'カイメイ', '', '', ''],
                    ['ペンキ', 'ぺんき', '', '', '', ''],
                    ['温泉まんじゅう', 'おんせんまんじゅう', 'オンセンマンジュウ', '温泉マンジュウ', '', ''],
                    ['カード', 'かーど', '', '', '', ''],
                    ['はーと', 'ハート', '', '', '', ''],
                    ['花形', 'はながた', 'ハナガタ', '', '', ''],
                    ['本', 'ほん', 'ホン', '', '', ''],
                    ['熱気球', 'ねつききゅう', 'ネツキキュウ', '', '', ''],
                    ['犬', 'いぬ', 'イヌ', '', '', ''],
                    ['爪', 'つめ', 'ツメ', '', '', ''],
                    ['インク', 'いんく', '', '', '', ''],
                    ['未来都市', 'ミライトシ', 'みらいとし', '', '', ''],
                    ['鎖', 'くさり', 'クサリ', '', '', ''],
                    ['とんかつ', 'トンカツ', '', '', '', ''],
                    ['小松菜', 'こまつな', 'コマツナ', '', '', ''],
                    ['う', 'ウ', '', '', '', ''],
                    ['イラスト', 'いらすと', '', '', '', ''],
                    ['スワン', 'すわん', '', '', '', ''],
                    ['王', 'おう', 'オウ', '', '', ''],
                    ['外伝', 'ガイデン', 'がいでん', '', '', ''],
                    ['終結', 'しゅうけつ', 'シュウケツ', '', '', ''],
                    ['M', 'エム', 'えむ', 'm', 'Ｍ', ''],
                    ['再生紙', 'さいせいし', 'サイセイシ', '', '', ''],
                    ['いす', 'イス', '椅子', '', '', ''],
                    ['未来', 'みらい', 'ミライ', '', '', ''],
                    ['カメ', 'かめ', '亀', '', '', ''],
                    ['取り消し', 'とりけし', 'トリケシ', '取リ消シ', '', ''],
                    ['ヒーロー', 'ひーろー', '', '', '', ''],
                    ['顔', 'かお', 'カオ', '', '', ''],
                    ['つる', 'ツル', '', '', '', ''],
                    ['福井', 'ふくい', 'フクイ', '福イ', '', ''],
                    ['正常', 'せいじょう', 'セイジョウ', '清浄', '成城', ''],
                    ['担々麺', 'たんたんめん', 'タンタンメン', '', '', ''],
                    ['ハナ', 'はな', '', '', '', ''],
                    ['ひ', 'ヒ', '', '', '', ''],
                    ['画家', 'がか', 'ガカ', '', '', ''],
                    ['サイン', 'さいん', '', '', '', ''],
                    ['うとうと', 'ウトウト', '', '', '', ''],
                    ['ちから', 'チカラ', '力', '', '', ''],
                    ['office', 'OFFICE', 'ＯＦＦＩＣＥ', '', '', ''],
                    ['ナイフ', 'ないふ', '', '', '', ''],
                    ['はね', 'ハネ', '', '', '', ''],
                    ['ルーマニア', 'るーまにあ', '', '', '', ''],
                    ['カオス', 'かおす', '', '', '', ''],
                    ['心得', 'ココロエ', 'こころえ', '', '', ''],

                    ],
            }
        },
        computed: {
        },
        methods: {
            handleLineClick(e) {
                let clickedElId = e.target.id
                var no = "";
                if (clickedElId === 'keyword') {
                   // console.log("temp_testing_div2 clicked!", e)

                    no = this.data[this.savedata.nowque].keyword;
                    console.log(no)
                   
                }
                else if(clickedElId === 'keyword1') {
                    // console.log("temp_testing_div2 clicked!", e)

                     no = this.data[this.savedata.nowque].keyword;
                    console.log(no)
                    no = no.split(",")[0];

                } else if (clickedElId === 'keyword2') {
                    // console.log("temp_testing_div2 clicked!", e)

                     no = this.data[this.savedata.nowque].keyword;
                    console.log(no)
                    no = no.split(",")[1];

                }

                if (no != "") {
                    this.dispkeyword(no);
                    e.stopPropagation();
                    return false;
                }
            },
            datareset() {

                this.rensyustart = "";
                this.savedata.step1sinenflg = 0;



                this.savedata.clearqeus = [
                    [0, 0, 0, 0, 0],
                ];

                this.savedata.step1flg = 0;
                this.savedata.step1dispmode = 0;
            
                this.savedata.step01kami = ["00", "00", "00", "00"];
             
                this.savedata.step1sinen = [
                        [0, 0, 0, 0, 0],
                        [0, 0, 0, 0, 0],
                        [0, 0, 0, 0, 0],
                        [0, 0, 0, 0, 0],

                    ];

                this.savedata.gatItem = [];
                this.savedata.useItem = [];
                this.savedata.step03clean = [0, 0, 0, 0, 0, 0];
                this.savedata.step03deg = [0, 0, 0];
                this.savedata.step03openkinko = 0;
                this.savedata.step3sinen = [0, 0, 0, 0, 0];
                this.savedata.step05deg = 0;
                this.savedata.step05win = false;

                this.step3room = 0;
                this.step3ueki = 0;
                    this.step3kusson = 0;
                this.step3useItem = 0;
                this.step3useItemKeyNo = 0;
                this.step3lock = [0, 0, 0, 0, 0, 0];
                this.step3pclock = [0, 0, 0, 0];
                this.step3bookstand = [];
                this.step03desk = false;
                this.step03Item =  0;
                this.step03DispItem = 0;
                this.inputStep3pc1 = "";
                this.inputStep3pc2 = "";
                this.inputStep3pc3 = "";
                this.inputStep3pc4 = "";

                this.inputStep4 = "";

                this.step3sinenflg = 0;
                this.step3pc = 1;
                this.step3pcsinen = 0;
                this.step3dispc = false;
                this.step3Opendesk = 0;
                this.step5gameover = 0;
                this.step04quesNo = 0;
                this.step04ans = [
                    [false, false, false, false],
                    [false, false, false, false],
                    [false, false, false, false],
                    [false, false, false, false],
                    [false, false, false, false],
                ];
                this.step04error = false;
                this.nputStep4 = "";


                this.Step05seikai = 100;
                this.step05queno = 1;
                this.inputStep5 = "";


                var trans = "rotate(" + (this.savedata.step05deg * 30) + "deg)"
                $("#kaiganari").css({ transform: trans });


                $("#aikotobaerror1").hide();

                //step04ans: [
                //    [false, false, false, false],
                //    [false, false, false, false],
                //    [false, false, false, false],
                //    [false, false, false, false],
                //    [false, false, false, false],
                //],

                //step04error: false,
                //inputStep4: "",

                //step3Hondanazoom: 0,
                //step3lock: [0, 0, 0, 0, 0, 0],
                //step3pclock: [0, 0, 0, 0],
                //savedata: {
                //    nowque: 0,
                //    maxque: 0,
                //    clearstep: 0,

                //    story:1,
                //    nowstep: 0,

                //    step1flg:0,
                //    step1dispmode: 0,
                //    step1sinenflg: 0,
                   

                //    step01kami: ["00", "00", "00", "00"],

                //    //Step03
                //    gatItem: [],
                //    useItem: [],
                //    step03clean:[0,0,0,0,0,0],
                //    step03deg: [0, 0, 0],
                //    step03openkinko: 0,
                //    //step03getbook5: false,
                //    //step03getbook2:false,

                //    //step02DB: 0,
                //    //step04DB: 0,

                //    step1sinen: [
                //        [0, 0, 0, 0, 0],
                //        [0, 0, 0, 0, 0],
                //        [0, 0, 0, 0, 0],
                //        [0, 0, 0, 0, 0],

                //    ],
                //    step3sinen: [
                //        [0, 0, 0, 0, 0],

                //    ],

                //    clearqeus: [
                //        [0, 0, 0, 0,0],
                //    ],

                //    checkkeyword:[1,2,3],


                //    step05deg: 0,
                //    step05win:false,

                //    endfl: 0,
                    
                //    //answercheck: [
                //    //    [false, false, false, false, false, false],
                //    //    [false, false, false, false, false, false],
                //    //    [false, false, false, false, false, false],
                //    //    [false, false, false, false, false, false],
                //    //    [false, false, false, false, false, false],
                //    //    [false, false, false, false, false, false],
                //    //    [false, false, false, false, false, false],
                //    //    [false, false, false, false, false, false],
                //    //],


                //},

                //step1 :0,

                //hint1: false,
                //error1: false,
                //tea1: false,
                //seikai1: false,


                //keywordDB: false,

                //step2: 0,
                //hint2: false,
                //error2: false,
                //tea2: false,
                //seikai2: false,

                ////SteopCommon=======
                //selectkey: 0,
                //logstep:[],

                ////Step03================
                //step3room: 0,
                //step3ueki: 0,
                //step3kusson: 0,
                //step3useItem: 0,
                //step3useItemKeyNo: 0,
                //step3Hondanazoom: 0,
                //step3lock: [0, 0, 0, 0, 0, 0],
                //step3pclock: [0, 0, 0, 0],

                //step3bookstand:[],
                //step03desk: false,
                //step03Item:0,
                //step03DispItem:0,
                //inputStep3pc1:"",
                //inputStep3pc2: "",
                //inputStep3pc3: "",
                //inputStep3pc4: "",

                ////Step04====================
                //step04quesNo: 0,
                //step04ans: [
                //    [false, false, false, false],
                //    [false, false, false, false],
                //    [false, false, false, false],
                //    [false, false, false, false],
                //    [false, false, false, false],
                //],

                //step04error: false,
                //inputStep4: "",


                ////Step05===
                //step05queno: 1,
                //Step05seikai: 100,
                //inputStep5:"",

                //step05error:false,


                //popupflg: 0,


                //isPopUpShow: false,
                //isPopUpShow2: false,

                //isPopUpShowKami: false,
                //isPopUpShowDataBase: false,
                //isPopUpShowLog:false,


                //inputstart: "",
                //rensyustart: "",
                //inputStep1: "",

                //inputDB: "",
                //serchcount: 0,
                //serchtitle: "",
                //serchresult: "",

                //step02flg: 0,
                //step2play: 0,
                //step2gameover: 0,
                //step2clear:0,

                //step3sinenflg: 0,
                //step3pc: 1,
                //step3pcsinen: 0,
                //step3dispc: false,
                //step3Opendesk: 0,
                //step5gameover: 0,



                //inputanswer: [
                //    ["", "", ""],
                //    ["", "", ""],
                //    ["", "", ""],
                //    ["", "", ""],
                //    ["", "", ""],
                //    ["", "", ""],
                //    ["", "", ""],
                //    ["", "", ""],
                //    ["", "", ""],
                //    ["", "", ""],
                //    ["", "", ""],
                //    ["", "", ""],
                //],
            },
            showtyui() {
                if (this.chuiflg == 0) {

                    this.chuiflg = 1;

                } else {
                    this.chuiflg =  0;
                }
            },
            gamestartclick() {
                this.gamestart = 0;
                localStorage.setItem(localstorageKeyGameStatus, JSON.stringify(this.gamestart));
            },
            showtop() {
                this.gamestart = -1;
                localStorage.setItem(localstorageKeyGameStatus, JSON.stringify(this.gamestart));
            },
            targetStep() {

                const searchParams = new URLSearchParams(window.location.search)
                var s = searchParams.get('step')
                console.log(s)

                if (s != null && s != "") {
                    console.log(s)
                    this.setStep(s);

                    if (this.savedata.clearstep < this.data[this.savedata.nowque].step - 1) {
                        this.savedata.clearstep = this.data[this.savedata.nowque].step - 1;
                    }
                    //for (var i = 0; i < this.data.length; i++) {
                    //    if (this.data[i].step == s) {
                    //        console.log("data")
                    //        console.log(this.data[i].step)
                    //        console.log(i)
                    //        this.story = 1;
                    //        this.savedata.nowque = i;
                    //         this.gamestart = 2;
                    //        break;
                    //    }
                    //}

                } else {
                    var data = localStorage.getItem(localstorageKeyNowQue);
                    var clearstep = localStorage.getItem(localstorageKeyClearStep);
                    var maxque = localStorage.getItem(localstorageKeyMaxQue);

                    var status = localStorage.getItem(localstorageKeyGameStatus);
                    if (data != null) {

                        var que = parseInt(data);
                        this.SetGame(que);

                       
                       
                    }
                    if (clearstep != null) {
                        this.savedata.clearstep = parseInt(clearstep);
                    }
                    if (maxque != null) {
                        this.savedata.maxque = parseInt(maxque);
                    }

                    if (status != null) {
                        this.gamestart = status;
                    }

                    
                }

            },
            setStep(step) {
                this.datareset();
                var que = 0;
                for (var i = 0; i < this.data.length; i++) {
                    if (this.data[i].step == step) {
                        console.log("data")
                        console.log(this.data[i].step)
                        console.log(i)
                        que = i;
                        
                        break;
                        //this.story = 1;
                        //this.savedata.nowque = i;
                        //this.gamestart = 2;
                        //break;
                    }
                }
                this.SetGame(que);
            },

            showmenu() {
                this.menu = 1;
            },
            closemenu() {
                this.menu = 0;
            },

            Start() {

                if (this.inputstart == null || this.inputstart == "") return;
                var inasns = toNormalizeString(this.inputstart);
                if (toNormalizeString("rainbow") == inasns) {
                    this.gamestart = 1;
                    $("#aikotobaerror").hide();

                    localStorage.setItem(localstorageKeyGameStatus, JSON.stringify(this.gamestart));
                 
                    // localStorage.setItem(localstorageKey, JSON.stringify(this.savedata));
                } else {
                    $("#aikotobaerror").show();
                }

            },
            Rensyu() {

                if (this.rensyustart == null || this.rensyustart == "") return;
                var inasns = toNormalizeString(this.rensyustart);
                if (toNormalizeString("失踪") == inasns || toNormalizeString("シッソウ") == inasns) {

                    $("#aikotobaerror1").hide();
                    //this.savedata.nowstep = 1;
           

                    $("#seikaistamp").show();
                    setTimeout(this.setNexstkaiwa, 1000);
                    
                } else {
                    $("#aikotobaerror1").show();
                }
            },
            kyanpenopentop() {

                if (this.camflg1 == 0) {
                    this.camflg1 = 1;
                } else {
                    this.camflg1 = 0;
                }
                //$('#kyanpen').show();

            },
            kyanpenopen() {

                if (this.camflg == 0) {
                    this.camflg = 1;
                } else {
                    this.camflg = 0;
                }
                //$('#kyanpen').show();

            },
            setNexstkaiwa() {

                $("#seikaistamp").hide();

                this.savedata.story = 1;
                this.NextQue();
            },
            GameNewStart() {

                this.gamestart = 2;
                this.savedata.nowque = 0;
                this.savedata.clearstep = 0;
                this.savedata.story = 1;

                this.checkkeyword = [1, 2, 3];
                this.datareset();

                //data[savedata.nowque].step
                //this.savedata.nowstep = 0;
                localStorage.setItem(localstorageKeyGameStatus, JSON.stringify(this.gamestart));

                localStorage.setItem(localstorageKeyNowQue, this.savedata.nowque);
                localStorage.setItem(localstorageKeyClearStep, this.savedata.clearstep);
                localStorage.setItem(localstorageKeyMaxQue, this.savedata.nowque);

            },
            GameContinue() {

                this.gamestart = 2;
                localStorage.setItem(localstorageKeyGameStatus, JSON.stringify(this.gamestart));


                var data = localStorage.getItem(localstorageKeyNowQue);
                var clearstep = localStorage.getItem(localstorageKeyClearStep);
                var maxque = localStorage.getItem(localstorageKeyMaxQue);
                if (data != null) {

                    var que = parseInt(data);
                    this.SetGame(que);
                    //キーワードのセット

                    //this.savedata = JSON.parse(data);
                    //console.log(data);
                }
                if (clearstep != null) {
                    this.savedata.clearstep = parseInt(clearstep);
                }
                if (maxque != null) {
                    this.savedata.maxque = parseInt(maxque);
                }
                // this.changeStep(this.savedata.nowstep);

            },
            SetGame(que) {
                this.savedata.nowque = 0;
                for (var i = 0; i <= que; i++) {
                    this.savedata.nowque = i;
                    this.addKeyWord();
                }
                // this.nowque = data;
                this.savedata.story = 1;
                this.gamestart = 2;
                localStorage.setItem(localstorageKeyGameStatus, JSON.stringify(this.gamestart));
               
            },
            Next() {

                this.savedata.story = 1;

                var nowstep = this.data[this.savedata.nowque].step;

                var event = this.data[this.savedata.nowque].event;



                //DBのチュートリアル表示
                if (this.data[this.savedata.nowque].step == 2 && event == "DB" ) {
                    this.keywordDB = false;
                    this.showDB();

                }
                else if (this.data[this.savedata.nowque].step == 4 && event == "DB" ) {
                    this.keywordDB = false;
                    this.showDB();

                }

                else if (this.data[this.savedata.nowque].step == 4 && event == "change") {
                    this.savedata.story = 2;
                }
                else if (event == "END") {
                    this.gamestart = 3;
                    localStorage.setItem(localstorageKeyGameStatus, JSON.stringify(this.gamestart));
                    //return;
                }

                else if (this.data[this.savedata.nowque].step == 4
                    && (event == "sentaku1" || event == "sentaku2"
                        || event == "sentaku3" || event == "sentaku4" || event == "sentaku5")) {
                    this.savedata.story = 3;
                    if (event == "sentaku1") {
                        this.step04quesNo = 0;
                    }
                    if (event == "sentaku2") {
                        this.step04quesNo = 1;
                    }
                    if (event == "sentaku3") {
                        this.step04quesNo = 2;
                    }

                    if (event == "sentaku4") {
                        this.step04quesNo = 3;
                    }

                    if (event == "sentaku5") {
                        this.step04quesNo = 4;
                    }
                }
                //else if (event == "ED") {

                //    return;
                //}
                //
                else if (this.savedata.nowque + 1 <= this.data.length) {
                    var nexstep = this.data[this.savedata.nowque + 1].step;
                    // var nowstep = this.data[this.savedata.nowque].step;

                    // var queno = this.savedata.nowque + 1;


                    this.addKeyWord();


                    if (nowstep >= 0 && nowstep != nexstep && nexstep != 5) {
                        //次のステップに進むのであれば出題する
                        this.savedata.story = 2;

                    } else {
                        //同じStep内であれば次に進む
                        this.NextQue();
                        //this.savedata.nowque = this.savedata.nowque + 1;
                    }
                }

                this.menu = 0;
              
                localStorage.setItem(localstorageKeyNowQue, this.savedata.nowque);
                localStorage.setItem(localstorageKeyClearStep, this.savedata.clearstep);

            },
            addKeyWord() {

              
                var keyno = this.data[this.savedata.nowque + 1].keyword;
                if (keyno != "" && this.savedata.checkkeyword.includes(keyno) == false) {
                    //this.savedata.checkkeyword.push(keyno);
                    var addlist = keyno.split(",");

                    for (var a = 0; a < addlist.length; a++) {

                        if (this.savedata.checkkeyword.includes(addlist[a]) == false) {
                            this.$set(this.savedata.checkkeyword, this.savedata.checkkeyword.length, addlist[a]);
                            console.log("AddKeyword:" + addlist[a] + ":" + this.savedata.checkkeyword.length);
                        }
                        //savedata.checkkeyword.includes(key.no)

                    }
                    console.log("AddKeywordTrue:" + this.savedata.checkkeyword.includes(keyno));
                    this.keyword.splice();

                    //this.savedata.checkkeyword.splice();
                }
            },

            NextQue() {

                this.savedata.nowque++;
                if (this.savedata.maxque < this.savedata.nowque) {

                    this.savedata.maxque = this.savedata.nowque;
                }

                //var nowclear = localStorage.getItem(localstorageKeyClearStep, 0);
                if (this.data[this.savedata.nowque].step > this.savedata.clearstep) {

                    this.savedata.clearstep = this.data[this.savedata.nowque].step ;
                }

                //if (this.data[this.savedata.nowque].keyword != "") {
                //    $(".keyword").on("click", function () {
                //        alert("Handler for `click` called.");
                //    });
                //}

                this.savedata.nowstep = this.data[this.savedata.nowque].step;
                
                localStorage.setItem(localstorageKeyMaxQue, this.savedata.maxque );
                localStorage.setItem(localstorageKeyNowQue, this.savedata.nowque);
                localStorage.setItem(localstorageKeyClearStep, this.savedata.clearstep);
            },
            Step1(mode) {

                if (mode == -1) {
                    return false;
                }

                //if (this.savedata.step1flg == 0) {
                //    if (mode == 1) {
                //        return;
                //    }
                //}

                if (this.savedata.step1flg <= 2) {
                    this.savedata.step1flg++;
                }
                this.inputStep1 = "";
                $("#step1error").hide();

                this.savedata.step1dispmode = mode;
                //localStorage.setItem(localstorageKeyNowQue, this.savedata.nowque);
                //localStorage.setItem(localstorageKeyClearStep, this.savedata.clearstep);

            },
            Step1Sine(onoff) {
                this.savedata.step1sinenflg = onoff;

                if (this.savedata.step1flg == 1) {
                    this.savedata.step1flg++;
                }

                //localStorage.setItem(localstorageKeyNowQue, this.savedata.nowque);
                //localStorage.setItem(localstorageKeyClearStep, this.savedata.clearstep);
            },
            Step3Sine(onoff) {
                this.step3sinenflg = onoff;

                console.log(this.step3pc);
                console.log(this.step3sinenflg);
                if (this.step3sinenflg == 1 && this.step3pc == 2) {
                    this.step3pcsinen = 1;
                    console.log(this.step3pcsinen);
                }

                //if (this.savedata.step1flg == 1) {
                //    this.savedata.step1flg++;
                //}

                // localStorage.setItem(localstorageKey, JSON.stringify(this.savedata));
            },

            step1Kaiga(index, no) {

                /* this.savedata.step1sinen[index][no] = 1;*/
                if (this.savedata.step1sinenflg == 1) {
                    this.$set(this.savedata.step1sinen[index], no, 1);




                    if (this.savedata.step1flg == 2) {
                        this.savedata.step1flg++;
                    }
                    window.setTimeout(function () {
                        //座標の取得
                        var x = pageX;
                        var y = pageY;

                        //しずくになるdivの生成、座標の設定
                        var sizuku = document.createElement("div");
                        sizuku.style.top = y + "px";
                        sizuku.style.left = x + "px";

                        document.body.appendChild(sizuku);

                        //アニメーションをする className を付ける
                        sizuku.className = "sizuku";

                        ////アニメーションが終わった事を感知してしずくを remove する
                        sizuku.addEventListener("animationend", function () {
                            this.parentNode.removeChild(this);
                        }, false);

                    }, 50)


                    //localStorage.setItem(localstorageKeyNowQue, this.savedata.nowque);
                    //localStorage.setItem(localstorageKeyClearStep, this.savedata.clearstep);
                }
            },
            Step1Ansewer() {
                //今表示されている問題


                if (this.inputStep1 == null || this.inputStep1 == "") return;
                var inasns = toNormalizeString(this.inputStep1);

                if (this.savedata.step1dispmode == 1) {

                    if (toNormalizeString("トモシビ") == inasns) {
                        this.savedata.clearqeus[0][0] = 1;
                        $("#step1error").hide();

                        if (this.savedata.step01kami.includes("01") == false) {
                            for (var i = 0; i < 4; i++) {
                                if (this.savedata.step01kami[i] == "00") {
                                    this.savedata.step01kami[i] = "01";

                                    this.$set(this.savedata.step01kami, i, "01");

                                    this.isPopUpShowKami = true;
                                    this.step1kami = 1;

                                    //$("#seikaistamp").show();
                                    //setTimeout(function () {
                                    //    $("#seikaistamp").hide();
                                    //}, 1000);

                                    break;
                                }
                            }
                        }
                        // localStorage.setItem(localstorageKey, JSON.stringify(this.savedata));
                    } else {
                        $("#step1error").show();
                    }

                }
                if (this.savedata.step1dispmode == 2) {

                    if (toNormalizeString("ナミ") == inasns) {
                        this.savedata.clearqeus[0][1] = 1;
                        $("#step1error").hide();

                        if (this.savedata.step01kami.includes("02") == false) {
                            for (var ii = 0; ii < 4; ii++) {
                                if (this.savedata.step01kami[ii] == "00") {
                                    this.savedata.step01kami[ii] = "02";
                                    this.$set(this.savedata.step01kami, ii, "02");
                                    this.isPopUpShowKami = true;
                                    this.step1kami = 2;
                                    break;
                                }
                            }
                        }

                        // localStorage.setItem(localstorageKey, JSON.stringify(this.savedata));
                    } else {
                        $("#step1error").show();
                    }

                }
                if (this.savedata.step1dispmode == 3) {

                    if (toNormalizeString("チカラ") == inasns) {
                        this.savedata.clearqeus[0][2] = 1;
                        $("#step1error").hide();

                        if (this.savedata.step01kami.includes("03") == false) {
                            for (var iii = 0; iii < 4; iii++) {
                                if (this.savedata.step01kami[iii] == "00") {
                                    this.savedata.step01kami[iii] = "03";
                                    this.$set(this.savedata.step01kami, iii, "03");

                                    this.isPopUpShowKami = true;
                                    this.step1kami = 3;
                                    break;
                                }
                            }
                        }
                        // localStorage.setItem(localstorageKey, JSON.stringify(this.savedata));
                    } else {
                        $("#step1error").show();
                    }

                }

                if (this.savedata.step1dispmode == 4) {

                    if (toNormalizeString("ケイテキ") == inasns || toNormalizeString("警笛") == inasns) {
                        this.savedata.clearqeus[0][3] = 1;
                        $("#step1error").hide();

                        if (this.savedata.step01kami.includes("04") == false) {
                            for (var iiiii = 0; iiiii < 4; iiiii++) {
                                if (this.savedata.step01kami[iiiii] == "00") {
                                    this.savedata.step01kami[iiiii] = "04";
                                    this.$set(this.savedata.step01kami, iiiii, "04");

                                    this.isPopUpShowKami = true;
                                    this.step1kami = 4;
                                    break;
                                }
                            }
                        }
                        // localStorage.setItem(localstorageKey, JSON.stringify(this.savedata));
                    } else {
                        $("#step1error").show();
                    }

                }
                if (this.savedata.step1dispmode == 5) {

                    if (toNormalizeString("アトリエ") == inasns) {
                        this.savedata.clearstep = 1;
                        //this.savedata.nowque = this.savedata.nowque + 1;

                        $("#step1error").hide();

                        $("#aikotobaerror1").hide();
                        $("#seikaistamp").show();
                        setTimeout(this.setNexstkaiwa, 1000);

                        //this.NextQue();
                        //this.savedata.story = 1;
                        //$("#step1error").hide();
                        // localStorage.setItem(localstorageKey, JSON.stringify(this.savedata));
                    } else {
                        $("#step1error").show();
                    }

                }
                //localStorage.setItem(localstorageKey, JSON.stringify(this.savedata));
                //localStorage.setItem(localstorageKeyNowQue, JSON.stringify(this.savedata.nowque));
                //localStorage.setItem(localstorageKeyClearStep, JSON.stringify(this.savedata.clearstep));
                return false;
            },


            DBSerach() {
                //if (this.savedata.step02DB == 0) {
                //    this.savedata.step02DB = 1;
                //}


                this.serchcount = 0;

                for (var i = 0; i < this.db.length; i++) {

                    if (toNormalizeString(this.db[i].key) == toNormalizeString(this.inputDB)) {
                        this.serchcount = 1;
                        this.serchtitle = this.db[i].key;
                        this.serchresult = this.db[i].text;

                        //if (this.data[this.savedata.nowque].step == 2 && this.keywordDB == false && i == 0) {
                        //   // this.savedata.step02DB = 1;
                        //    //this.savedata.nowque++;
                        //    //this.Next();

                        //    this.NextQue();
                        //}

                        //if (this.data[this.savedata.nowque].step == 4 && this.keywordDB == false && i == 1) {
                        //    //this.savedata.step04DB = 1;
                        //    //this.savedata.nowque++;
                        //    //this.Next();

                        //    this.NextQue();
                        //}
                        break;
                    }
                }

            },
            dbNext() {
                this.NextQue();
                this.hidisPopUpDataBase();
            },
            videoTimeUpdate(no) {

                console.log($("#video" + no)[0].currentTime);
                var time = $("#video" + no)[0].currentTime;

                if (this.step02flg  == 0 &&  no == 1 && time > 128) {
                    //$("#video" + no)[0].pause();
                    this.step2gameover = 1;
                    this.step2gameover2 = 0;
                }

                else if (this.step02flg == 1 && no == 2 && time > 68) {
                    /*  $("#video" + no)[0].pause();*/
                    this.step2gameover = 1;
                    this.step2gameover2 = 0;

                } else if (this.step02flg == 2 && no == 3 && time > 38) {
                    /* $("#video" + no)[0].pause();*/
                    this.step2gameover = 1;
                    this.step2gameover2 = 0;

                } else if (this.step02flg == 3 && no == 4 && time > 13) {
                    /*  $("#video" + no)[0].pause();*/
                    this.step2gameover = 1;
                    this.step2gameover2 = 0;

                }



            },
            //正解
            Step2Next(y, x) {
                if (this.step2gameover != 1) {
                    var no = this.step02flg + 1;
                    //var check = false;

                    console.log("x:" + x + ",y:" + y);
                    var time = $("#video" + no)[0].currentTime;
                    if (time <= 6) {
                        return;
                    }

                    $("#video1")[0].pause();
                    $("#video2")[0].pause();
                    $("#video3")[0].pause();
                    $("#video4")[0].pause();

                    if (no == 1 && x == 4 && y == 7) {
                        $("#video" + no)[0].pause();
                        this.step2clear = 1;
                    }
                    else if (no == 2 && x == 5 && y == 3) {
                        $("#video" + no)[0].pause();
                        this.step2clear = 1;
                    }
                    else if (no == 3 && x == 3 && y == 8) {
                        $("#video" + no)[0].pause();
                        this.step2clear = 1;
                    }
                    else if (no == 4 && x == 2 && y == 2) {
                        $("#video" + no)[0].pause();
                        this.step2clear = 1;
                    }
                    else {
                        $("#video" + no)[0].pause();
                        this.step2gameover = 1;
                        this.step2gameover2 = 1;
                    }



                }
            },

            Step2NextVideo(no) {

                $("#video1")[0].pause();
                $("#video2")[0].pause();
                $("#video3")[0].pause();
                $("#video4")[0].pause();


                $("#video1")[0].currentTime = 0;
                $("#video2")[0].currentTime = 0;
                $("#video3")[0].currentTime = 0;
                $("#video4")[0].currentTime = 0;
                //this.step02flg = no;
                this.step2clear = 0;
                this.step2gameover = 0;
               

                if (no != 5) {
                    this.Step02PlayVide(no);
                } else {
                    //this.savedata.clearstep = 2;
                    //this.savedata.nowque = this.savedata.nowque + 1;

                    //           this.savedata.nowstep = 3;
                    this.NextQue();


                    this.savedata.story = 1;

                    //localStorage.setItem(localstorageKeyNowQue, this.savedata.nowque);
                    //localStorage.setItem(localstorageKeyClearStep, this.savedata.clearstep);
                }


            },

            Step02PlayVide(no) {
                /*     $("#video" + no)[0].video.preload = 'metadata';*/
                $("#video" + no)[0].currentTime = 0
                $("#video" + no)[0].play();
                this.step02flg = (no - 1);
                console.log("STEP02:" + this.step02flg);

            },


            //Step03=========================

            leftRoom() {

                this.step3room++;

                if (this.step3room > 3) {
                    this.step3room = 0;
                }

            },
            rightRoom() {

                this.step3room--;

                if (this.step3room < 0) {
                    this.step3room = 3;
                }
            },

            itemClick(item) {
                //step3ueki: 0,
                //    step3kusson: 0,

                console.log("selectitem:" + item);

                if (item == 1) {
                    if (this.step3ueki == 0) {
                        this.step3ueki = 1;
                    } else {
                        this.step3ueki = 0;
                    }
                }
                if (item == 2) {
                    if (this.step3kusson == 0) {
                        this.step3kusson = 1;
                    } else {
                        this.step3kusson = 0;
                    }
                }

                if (item == 3) {
                    this.step3Hondanazoom = 1;

                }

            },
            stop() {
                return false;
            },
            step03num(no) {
                var count = this.step3lock[no];
                count++;
                if (count > 9) {
                    count = 0;
                }
                this.$set(this.step3lock, no, count);

                //check
                //if (this.step3lock[0] == 2 &&
                //    this.step3lock[1] == 5 &&
                //    this.step3lock[2] == 8 &&
                //    this.step3lock[3] == 1 &&
                //    this.step3lock[4] == 3 &&
                //    this.step3lock[5] == 9) {
                if (this.step3lock[0] == 3 &&
                    this.step3lock[1] == 2 &&
                    this.step3lock[2] == 6 &&
                    this.step3lock[3] == 4 &&
                    this.step3lock[4] == 5 &&
                    this.step3lock[5] == 1) {
                    this.step3Opendesk = 1;

                }

            },
            step03Itemopen(flg) {
                this.step03Item = flg;
            },
            step03desklockopen(flg) {
                this.step03desk = flg;
            },
            getItem(no) {
                if (this.savedata.gatItem.includes(no) == false) {
                    console.log(no);
                    this.savedata.gatItem.push(no);

                    if (no == 1) {
                        //this.savedata.gatItem.push(1);
                        this.savedata.gatItem.push(4);
                        this.savedata.gatItem.push(6);
                        this.savedata.gatItem.push(3);
                        this.savedata.step03openkinko = 2;
                    }
                 
                    this.savedata.gatItem.splice();

            
                }
            },
            getItem2(no) {
                if (this.savedata.gatItem.includes(no) == false) {
                    console.log(no);
                    this.savedata.gatItem.push(no);

                    this.step3bookstand.splice(this.step3bookstand.indexOf(no), 1);

                    this.savedata.gatItem.splice();


                }
            },
            setItem(index) {
                if (this.savedata.gatItem.length >= index) {

                    var target = this.savedata.gatItem[index - 1];
                    for (var i = 0; i < this.step03items.length; i++) {

                        if (this.step03items[i].keyno == target) {
                            return "./img/step03/" + this.step03items[i].item;
                        }
                    }

                } else {
                    return "";
                }
            },
            useItem(index) {
                console.log(index);
                //var target = this.savedata.gatItem[index - 1];

                ////アイテムごとの動き
                //if (target == 1) {

                //}
                this.step3useItem = index;
                var target = this.savedata.gatItem[index - 1];
                console.log(target);

                if (target == 10 || target == 11) {
                    for (var i = 0; i < this.step03items.length; i++) {
                        if (target == this.step03items[i].keyno) {

                            this.step03Item = 2;
                            this.step3useItemKeyNo = i
                            break;
                        }
                    }
                }

                //if (

                    //step3Opendesk
                //for (var i = 0; i < this.step03items.length; i++) {

                //    if (i == (index - 1)){
                //        $("#step03item" + index).parent("td").addClass("selectitem");
                //    } else {
                //        $("#step03item" + index).parent("td").removeClass("selectitem");
                //    }

                //}

            },

            clearn(no) {

                console.log(no);
                console.log(this.step3useItem);
                var keyno = this.savedata.gatItem[this.step3useItem - 1];
                console.log(keyno);

                if (keyno == 7) {

                    this.$set(this.savedata.step03clean, no - 1, 1);


                }
            },

            rotation(no) {

                if (this.savedata.step03openkinko >= 1) {
                    return;
                }
                if (this.savedata.step03deg[no - 1] >= 12) {
                    this.savedata.step03deg[no - 1] = 0;
                }
                this.savedata.step03deg[no - 1]++;

                var trans = "rotate(" + (this.savedata.step03deg[no - 1] * 30) + "deg)"
                $("#kaiga" + no).css({ transform: trans });

                console.log(this.savedata.step03deg[0] + "," + this.savedata.step03deg[1] + "," + this.savedata.step03deg[2]);
                if (this.savedata.step03deg[0] == 2 && this.savedata.step03deg[1] == 9 && this.savedata.step03deg[2] == 6) {
                    this.savedata.step03openkinko = 1;
                }


            },
            setBook() {

                var keyno = this.savedata.gatItem[this.step3useItem - 1];


                for (var i = 0; i < this.step03items.length; i++) {
                    //本のときだけ実行
                    if (this.step03items[i].keyno == keyno && this.step03items[i].syurui == 1) {

                        this.$set(this.step3bookstand, this.step3bookstand.length, keyno);
                       
                        this.savedata.gatItem.splice(this.step3useItem - 1, 1);
                        this.step3useItem = 0;
                        this.$set(this.savedata.useItem, this.savedata.useItem.length, keyno);

                        //var filepath = this.step03items[i].item;

                        //window.setTimeout(function () {
                        //    ////座標の取得
                        //    //var x = pageX;
                        //    //var y = pageY;

                        //    ////しずくになるdivの生成、座標の設定
                        //    //var book = document.getElementById("setbook" + keyno);
                        //    //book.style.top = ( y - 480) + "px";
                        //    //book.style.left = (x - 524)  + "px";

                        //    // var book = document.getElementById("setbook" + keyno);
                        //    // var hondana = document.getElementById("sethondana");
                        //    //var rect = hondana.getBoundingClientRect();
                        //    // var body = document.body.getBoundingClientRect();
                        //    //book.style.top = (x - $("#sethondana").offset().left) + "px";
                        //    //book.style.left = (y - $("#sethondana").offset().top) + "px";


                        //    $("#setbook" + keyno).draggable({
                        //        containment: ".hondana"
                        //    });
                        //    // book.src = "./img/step03/" + filepath;

                        //    // document.body.appendChild(book);

                        //    ////アニメーションをする className を付ける
                        //    //book.className = "book";

                        //    //////アニメーションが終わった事を感知してしずくを remove する
                        //    //sizuku.addEventListener("animationend", function () {
                        //    //    this.parentNode.removeChild(this);
                        //    //}, false);

                        //}, 50)
                        
                        console.log(this.savedata.useItem);
                        break;
                    }

                }

            },
            closeHondana() {
                this.step3Hondanazoom = 0;

                //一旦：あとで消す
                //this.clearStep03();
            },

            step03clear() {
                if (this.step3sinenflg == true && this.step3pc == 8) {

                    this.savedata.clearstep = 3;
                    //this.savedata.nowque = this.savedata.nowque + 1;

                    window.setTimeout(function () {
                        $("#clearroom").show();
                    }, 50);

                    window.setTimeout(function () {
                        $("#clearroom2").show();
                    }, 1000);

                    window.setTimeout(this.setClear3, 3000);


                }
            },
            //step03clear() {
            //    if (this.step3sinenflg == true && this.step3pc == 8) {

            //        this.savedata.clearstep = 3;
            //        //this.savedata.nowque = this.savedata.nowque + 1;

            //        window.setTimeout(this.setClear3, 300);


            //    }
            //},
            setClear3() {
                $("#clearroom").hide();
                this.NextQue();

                //this.savedata.nowstep = 4;
                this.savedata.story = 1;

              
            },

            sentaku(no) {

                var answer = this.step04Sentakusi[this.step04quesNo].ans;

                if (no == answer) {
                    this.step04ans[this.step04quesNo][no - 1] = false;
                    //this.savedata.nowque = this.savedata.nowque + 1;

                    this.NextQue();

                    this.savedata.story = 1;
                    this.step04error = false;
                } else {
                    this.step04ans[this.step04quesNo][no - 1] = true;
                    this.step04error = true;

                }


            },
            canvas() {

                if (this.step3useItem != 0) {
                    var keyno = this.savedata.gatItem[this.step3useItem - 1];

                    //釘抜き使用
                    if (keyno == 8) {
                        this.getItem(10);

                        this.getItem(11);


                        //this.step3Opendesk = 1;
                        this.step03Itemopen(1);
                    }
                }
                //if (this.step3useItem != 0) {
                //    var keyno = this.getItem[this.step3useItem - 1];
                //    if (keyno == 8) {
                //        console.log("Canvas分解")
                //    }
                //}


            },

            setSeikaiStamp() {
                //
                $("#seikaistamp").removeClass("stampanime");

                $("#seikaistamp").addClass("stampanime");
                $("#seikaistamp").show();

                //アニメーションをする className を付ける
                $("#seikaistamp").off("animationend");

                ////アニメーションが終わった事を感知して remove する
                $("#seikaistamp")[0].addEventListener("animationend", function () {
                    this.parentNode.removeChild(this);
                    $("#seikaistamp").hide();
                }, false);
            },
            step03atama() {
                if (this.step3sinenflg == 1) {
                    window.setTimeout(function () {
                        //座標の取得
                        var x = pageX;
                        var y = pageY;

                        //しずくになるdivの生成、座標の設定
                        var sizuku = document.createElement("div");
                        sizuku.style.top = y + "px";
                        sizuku.style.left = x + "px";

                        document.body.appendChild(sizuku);

                        //アニメーションをする className を付ける
                        sizuku.className = "sizuku";

                        ////アニメーションが終わった事を感知してしずくを remove する
                        sizuku.addEventListener("animationend", function () {
                            this.parentNode.removeChild(this);
                        }, false);

                    }, 50)

                    console.log(this.step3pc);
                    if (this.step3pc == 2) {
                        this.$set(this.savedata.step3sinen, 0, 1);
                    }else if (this.step3pc == 4) {
                        this.$set(this.savedata.step3sinen, 1, 1);
                    } else if (this.step3pc == 6) {
                        this.$set(this.savedata.step3sinen, 2, 1);
                    }
                    console.log(this.savedata.step3sinen);
                }

            },
            //セキュリティーチェック
            security(num, select) {
                console.log(num + ":" + select);

                if (num == 1) {
                    if (select == 1) {
                        this.step3pc++;
                    } else {
                        this.setPCError();
                    }
                }
                if (num == 2) {
                    if (select == 3) {
                        this.step3pc++;
                    } else {
                        this.setPCError();
                    }
                }
                if (num == 3) {
                    if (select == 2) {
                        this.step3pc++;
                    } else {
                        this.setPCError();
                    }
                }
              
            },
            setPCNext() {
                this.step3pc++;
            },
                setPCError() {
                this.step3pc = 9;
                window.setTimeout(this.setPCErrorReset, 500);

            },
            setPCErrorReset() {
                console.log(this.step3pc);
                if (this.step3pc == 9) {
                    this.step3pc = 1;
                }
            },
            step03PcOK() {
                if (this.step3pc == 1) {
                    if (this.inputStep3pc1 == "5" &&
                        this.inputStep3pc2 == "1" &&
                        this.inputStep3pc3 == "3" &&
                        this.inputStep3pc4 == "6") {
                        this.step3pc = 2;
                    } else {
                        this.setPCError();
                    }

                }

            },
            inputnext(no) {

                $("#num" + no).focus();
            },
            setSinen() {

                if (this.step3sinenflg == 1) {
                    window.setTimeout(function () {
                        //座標の取得
                        var x = pageX;
                        var y = pageY;

                        //しずくになるdivの生成、座標の設定
                        var sizuku = document.createElement("div");
                        sizuku.style.top = y + "px";
                        sizuku.style.left = x + "px";

                        document.body.appendChild(sizuku);

                        //アニメーションをする className を付ける
                        sizuku.className = "sizuku";

                        ////アニメーションが終わった事を感知してしずくを remove する
                        sizuku.addEventListener("animationend", function () {
                            this.parentNode.removeChild(this);
                        }, false);

                    }, 50)
                }
            },



            step04hide() {
                this.step04error = false;
            },

            Step4Ansewer() {
                if (this.inputStep4 == null || this.inputStep4 == "") return;
                var inasns = toNormalizeString(this.inputStep4);
                if (toNormalizeString("射的") == inasns || toNormalizeString("シャテキ") == inasns) {
                    this.step04error = false;
                    //this.savedata.nowque = this.savedata.nowque + 1;

                    //this.NextQue();

                    //$("#step1error").hide();

                    //$("#aikotobaerror1").hide();
          
                    this.savedata.clearstep = 4;

                    $("#seikaistamp").show();
                    setTimeout(this.setNexstkaiwa, 1000);

                    //this.savedata.story = 1;
                
                } else {
                    this.step04error = true;
                }

                //localStorage.setItem(localstorageKeyNowQue, this.savedata.nowque);
                //localStorage.setItem(localstorageKeyClearStep, this.savedata.clearstep);

            },

      


            //Step05
            rotationstep05() {

                //if (this.savedata.step05win == true) {
                //    return;
                //}


                if (this.savedata.step05deg >= 11) {
                    this.savedata.step05deg = 0;
                }
                this.savedata.step05deg++;

                var trans = "rotate(" + (this.savedata.step05deg * 30) + "deg)"
                $("#kaiganari").css({ transform: trans });

                if (this.savedata.step05deg == 6) {
                    this.savedata.step05win = true;

                    if (timer != null) {
                        clearTimeout(timer);
                        timer = null;
                    }

                    localStorage.setItem(localstorageKey, JSON.stringify(this.savedata));
                    this.goEnding(1)
                }


            },
            Step5Ansewer() {

 

                if (this.inputStep5 == null || this.inputStep5 == "") return;

                if (this.enbale == false) return;


                var inasns = toNormalizeString(this.inputStep5);
                if (this.step05Answer[this.step05queno - 1].includes(inasns)) {
                    this.step05error = false;
                    this.Step05seikai--;
                    if (timer != null) {
                        clearTimeout(timer);
                        timer = null;
                    }
                    this.enbale = false;
                    $("#seikaistamp2").show();
                    setTimeout(this.step05QuestionNext, 1000);


                   // this.step05QuestionNext();
                } else {
                    this.step05error = true;
                }

                //localStorage.setItem(localstorageKeyNowQue, this.savedata.nowque);
                //localStorage.setItem(localstorageKeyClearStep, this.savedata.clearstep);
               //timer = function () {
               //     if (this.step05queno < 100) {
               //         this.step05queno++;
               //     }
               // };


               // const timer = setTimeout(log, 3000, "Hello");
               // //Step05===
               // step05queno: 1,
               //     Step05seikai: 0,


            },

     
            step05QuestionNext() {

                $("#seikaistamp2").hide();

                this.enbale = true;

                if (timer != null) {
                    clearTimeout(timer);
                }

                if (this.savedata.step05win == true) {
                    return;
                }

                if (this.step05queno < 100) {

                    this.inputStep5 = "";
                    this.step05error = false;
                    $("#quesimg").removeClass("zoom");

                    this.step05queno++;

                    window.setTimeout(function () {
                        $("#quesimg").addClass("zoom");

                    }, 50)

                    //自動で次の問題へ
                    timer = window.setTimeout(this.GameOver, 15050)

                } else if (this.step05queno == 100 && this.Step05seikai == 0) {

                    //１００問正解ルートへ
                    this.goEnding(2);
                   
                }
                else {
                    if (this.savedata.step05win == false) {
                        //GameOver
                        this.step5gameover = 1;

                        //this.goEnding(2);
                    }
                }
            },
            step05Rest() {
                this.Step05seikai = 100;
                this.step5gameover = 0;
                this.step05queno = 0;
                this.step05QuestionNext();
            },
            GameOver() {
                this.step5gameover = 1;
               
            },
            NexstQue() {
               
                //$("#quesimg").addClass("zoom");
                this.step05QuestionNext();
            },
            goEnding(flg) {

               // var nowstep = this.data[this.savedata.nowque].step;

               //var event = this.data[this.savedata.nowque].event;
                var index = 0;
                for (var i = 0; i < this.data.length; i++) {
                    var event = this.data[i].event;

                    if (flg == 1 && event == "ENDING1") {
                        index = i;
                        break;
                    }else if (flg == 2 && event == "ENDING2") {
                        index = i;
                        break;
                    }

                }
                this.savedata.nowque = index;
                this.nowstep = this.data[this.savedata.nowque].step;

                if (this.savedata.maxque < index) {
                    this.savedata.maxque = index;
                }
                
                this.savedata.story = 1;
                this.savedata.clearstep = 5;

                localStorage.setItem(localstorageKeyNowQue, this.savedata.nowque);
                localStorage.setItem(localstorageKeyClearStep, this.savedata.clearstep);

            },
            dispkeyword(no) {

                if (no != 9) {
                    this.isPopUpShow = true;
                    this.selectkey = (no - 1);
                } else {
                    this.keywordDB = true;
                    this.showDB();
                }
            },
            showDB() {
                this.inputDB = "";
                this.serchcount = 0;
                this.serchtitle = "";
                this.serchresult = "";
 
                //this.DBSerach();
                this.isPopUpShowDataBase = true;
            },


            openmenu(event) {
                console.log(event.target);
                $(event.target).next('.menusecond').slideToggle("fast");
            },
            replay(no) {
                console.log(no);

                this.closemenu();
            },

            kaiwalog(no) {
                console.log(no);

                this.isPopUpShowLog = true;
                this.closemenu();

                this.logstep = [];
         

                var s = no;
                if (s == 1) {
                    this.$set(this.logstep, 0, 0);
                }
                
                this.$set(this.logstep, this.logstep.length, no);

                if ( s == 5 && this.savedata.clearstep == 6) {
                    this.$set(this.logstep, this.logstep.length, 6);
                }
                if ( s == 5 &&  this.savedata.clearstep == 7) {
                    this.$set(this.logstep, this.logstep.length, 7);
                }
                //this.logstep = this.data[this.savedata.nowque].step;
                console.log(this.logstep);
            },
            kaiwalognow() {
               

                this.isPopUpShowLog = true;
                this.closemenu();
                this.logstep = [];
                var s = this.data[this.savedata.nowque].step;
                if (s == 1) {
                    this.$set(this.logstep, 0, 0);
                }
                else if (s == 6 || s ==  7) {
                    this.$set(this.logstep, 0, 5);
                }
                this.$set(this.logstep, this.logstep.length, this.data[this.savedata.nowque].step);
                //this.logstep = this.data[this.savedata.nowque].step;
                console.log(this.logstep);
            },
      
            hidisPopLog() {

                    this.isPopUpShowLog = !this.isPopUpShowLog
                this.closemenu();
            },
            openPC(flg) {
                this.step3dispc = flg;

            },





            dispQuestion(no) {
                if (no == 4) {
                    this.savedata.story = 1;

                    //this.savedata.nowstep = 5;
                    //this.savedata.nowque++;

                    this.NextQue();
                }
                else {
                    this.savedata.story = 3;
                }
                if (no == 2) {
                    this.Step02PlayVide(1);

                }

                if (no == 5) {
                   // this.savedata.nowstep = 6;
                    this.savedata.story = 3;
                    this.inputStep5 = "";
                    this.step05queno = 0;
                 
                    this.step05error = false;
                    this.Step05seikai = 100;
                    this.step05QuestionNext();
                }
               
                //localStorage.setItem(localstorageKeyNowQue, this.savedata.nowque);
                //localStorage.setItem(localstorageKeyClearStep, this.savedata.clearstep);
            },


            //Answer(step,num,next) {


            //    if (this.inputanswer[step][num] == null || this.inputanswer[step][num] == "") return;


            //    var inasns = toNormalizeString(this.inputanswer[step][num]);

            //    console.debug(inasns);
             

            //    var anss = this.answers[step][num];
            //    var ans = anss.split(",");
            //    console.log(anss);
            //    var check = false
            //    ans.forEach(function (a) {
            //        if (toNormalizeString(a) == inasns) {
            //            check = true;
                       
            //        }
            //    });

            //    if (check == true) {

            //        //this.savedata.answercheck[step][num] = true;
            //        this.$set(this.savedata.answercheck[step], num, true);

            //        localStorage.setItem(localstorageKeyNowQue, this.savedata.nowque);
            //        localStorage.setItem(localstorageKeyClearStep, this.savedata.clearstep);

            //       // this.scrollTop(next);
            //        //this.goToStepNext(next);
            //        this.Scroll(next);
            //    } else {
            //        alert("不正解")
            //    }


               

            //},
            //AnswerLast() {

            //    if (this.inputanswer[6][1] == null || this.inputanswer[6][1] == "") return;


            //    var inasns = toNormalizeString(this.inputanswer[6][1]);

            //    console.debug(inasns);


              

            //    if (inasns == toNormalizeString("1")) {

            //        this.goNextStep();
            //    } else if (inasns == toNormalizeString("2")) {
            //        this.goNextStep();
            //    } else if (inasns == toNormalizeString("3")) {
            //        this.goNextStep();
            //    } else if (inasns == toNormalizeString("4")) {
            //        this.goNextStep();
            //    } else {
            //        alert("１～４のコードの番号を入力してください。");
            //    }
            //},
            returnMovie() {
                this.savedata.endfl = 0;
                //localStorage.setItem(localstorageKeyNowQue, this.savedata.nowque);
                //localStorage.setItem(localstorageKeyClearStep, this.savedata.clearstep);
            },
            selectMovie(no) {
                this.savedata.endfl = no;
                //localStorage.setItem(localstorageKeyNowQue, this.savedata.nowque);
                //localStorage.setItem(localstorageKeyClearStep, this.savedata.clearstep);
            },
            goToStep(step,next) {

                console.log("Step:" + (this.data[this.savedata.nowque].step - 1) + "->" + step);


                this.savedata.nowstep = step;

                if (this.savedata.clearstep < step) {
                    this.savedata.clearstep = step;
                   
                }

                localStorage.setItem(localstorageKeyNowQue, this.savedata.nowque);
                localStorage.setItem(localstorageKeyClearStep, this.savedata.clearstep);
         

                this.scrollTop(next);
            },
            goToStepNext(next) {

                console.log("Step:" + (this.data[this.savedata.nowque].step - 1) + "->" + next);


                this.savedata.nowstep = next;

                if (this.savedata.clearstep < next) {
                    this.savedata.clearstep = next;

                    localStorage.setItem(localstorageKeyNowQue, this.savedata.nowque);
                    localStorage.setItem(localstorageKeyClearStep, this.savedata.clearstep);
                }

        

                this.Scroll(next);
            },

            //goNextStep() {

            //   // this.scrollTop();

            //    var now = this.savedata.nowstep;
            //    now++;
            //    console.log("Step:" + (now - 1) + "->" + now);

            //    if (this.savedata.nowstep < now) {
            //        this.savedata.clearstep = now;
            //        this.savedata.nowstep = now;
            //        localStorage.setItem(localstorageKey, JSON.stringify(this.savedata));
            //    }

            //    this.scrollTarget("#step" + now);
                
            //},
            goNextEnd(no) {

                this.savedata.endfl = no;
                this.goToStep(9,24);


            },
            changeStep(no) {
                this.savedata.nowstep = no;
                localStorage.setItem(localstorageKeyNowQue, this.savedata.nowque);
                localStorage.setItem(localstorageKeyClearStep, this.savedata.clearstep);
                this.scrollTop();

            },
        
            
            //dragitem() {

            //    //$(".item1").draggable(
               
            //    //);
            //    //$(".item2").draggable();
            //    //$(".item3").draggable();
            //    //$(".item4").draggable();
            //    //$(".item5").draggable();
            //    //$(".item6").draggable();
            //    //$(".item7").draggable();
            //    //$(".item8").draggable();
            //    //$(".item9").draggable();
            //    //$(".item10").draggable();
            //    $(".item").draggable();


            //    $(".set").droppable({
            //        //ドロップOKの要素を指定
            //        accept: ".item",
            //        //ドロップ時の動作
            //        greedy: true,
            //        drop: ((event, ui) => {
            //            console.log(event, ui);

            //            //ここに何がドロップされたか
            //            console.log("BASE:" + ui.draggable.attr("id"));
            //            console.log(event.target.id)


            //            var id = ui.draggable.attr("id");
            //            var targetid = event.target.id;
                    

            //            var strFoot = id.slice(-2);
            //            var index = parseInt(strFoot);

            //            var num = targetid.slice(-1);
            //            var snum = parseInt(num);
            //            if (isNaN(snum) == true) {
            //                snum = 0;
            //            }
            //            console.log("NUM:" + snum)
            //            this.itempos[index - 1] = snum;

            //            this.itemPosCheck();
            //            //this.drop(ui.draggable.attr("id"), 0);
            //        }),
                   
            //    });


            //    //$(".itemallarea").droppable({
            //    //    //ドロップOKの要素を指定
            //    //    accept: ".item",
            //    //    //ドロップ時の動作
            //    //    greedy: true,
            //    //    drop: ((event, ui) => {
            //    //        console.log(event, ui);
                        
            //    //        //ここに何がドロップされたか
            //    //        console.log("BASE:" + ui.draggable.attr("id"));
            //    //        console.log(event.target.id)


            //    //        var id = ui.draggable.attr("id");
            //    //        var targetid = event.target.id;


            //    //        var strFoot = id.slice(-2);
            //    //        var index = parseInt(strFoot);

            //    //        var num = targetid.slice(-1);
            //    //        var snum = parseInt(num);
            //    //        if (isNaN(snum) == true) {
            //    //            snum = 0;
            //    //        }
            //    //        console.log("NUM:" + snum)
            //    //        this.itempos[index - 1] = snum;

            //    //        this.itemPosCheck();
            //    //        //this.drop(ui.draggable.attr("id"), 0);
            //    //    })
            //    //});

                

            //},
            //itemPosCheck() {
            //    console.log(this.itempos);

            //    var check = true;

            //    var i = 0;
            //    for (i = 0; i < this.itempos.length; i++) {
            //        if (this.itempos[i] != this.seikaipos[i]) {
            //            check = false
            //        }
            //    }

            //    if (check == true) {

            //        //
            //        this.$set(this.savedata.answercheck[3], 1, true);
            //        this.Scroll(14);
            //        //this.goToStep(14);
            //    }
            //},
            change() {
            
               
            },
           
          
            close1() {
                this.hidPopUp2();

                this.step1 = 0;
                this.step2 = 0;
            },
            rousoku() {
             
            },
            dragmuch() {


            },

            menuOpen() {
                this.isPopUpMenu = true;
            },
            hidPopUpMenu() {
                this.isPopUpMenu = false;
            },

            openPopUp() {
                this.isPopUpShow = true
            },
            hidPopUp() {
                this.isPopUpShow = false
            },

            openPopUp2() {
                this.isPopUpShow2 = true
            },
            hidPopUp2() {
                this.isPopUpShow2 = false
            },

            hidPopUpKami() {

                this.isPopUpShowKami = false;
                this.savedata.step1dispmode = 0;
            },
            hidisPopUpDataBase() {

                //if (this.data[this.savedata.nowque].step == 2 && this.savedata.step02DB == 0) {
                //    return;
                //}
                //if (this.data[this.savedata.nowque].step == 4 && this.savedata.step04DB == 0) {
                //    return;
                //}

               
                this.isPopUpShowDataBase = false;
                this.keywordDB = false;
            },


          

            Scroll(no) {
                

                setTimeout(function () {
                    var element = document.getElementById('step' + no);
                    var rect = element.getBoundingClientRect();
                    var elemtop = rect.top + window.pageYOffset;
                    window.scrollTo({
                        top: elemtop,
                        behavior: "smooth"
                    });
                }, 300);

            },
            scrollTop() {
                setTimeout(function () {
                    window.scrollTo({
                        top: 0,
                         /*behavior: "smooth"*/
                    });
                }, 0);
            },

            scrollTarget(id) {
                setTimeout(function () {
                    $(window).scrollTop($(id).position().top);
                }, 300);
               

            },
            //scrollTop() {
            //    setTimeout(function () {
            //        window.scrollTo({
            //            top: 0,
            //           /* behavior: "smooth"*/
            //        });
            //    }, 300);
            //},
            OpenPop(no) {
                this.popupflg = no;
                this.isPopUpShow = true;
            },

       
            InstOpen2() {
                var url = "https://www.instagram.com";

                window.open(url, '_blank');

            },


        }
    }
    function drop(e) {

        ////座標の取得
        pageX = e.pageX;
        pageY = e.pageY;

        ////しずくになるdivの生成、座標の設定
        //var sizuku = document.createElement("div");
        //sizuku.style.top = y + "px";
        //sizuku.style.left = x + "px";

        //document.body.appendChild(sizuku);

        ////アニメーションをする className を付ける
        //sizuku.className = "sizuku";

        //////アニメーションが終わった事を感知してしずくを remove する
        //sizuku.addEventListener("animationend", function () {
        //    this.parentNode.removeChild(this);
        //}, false);
    }

    //function setdl() {
    //    $("dd").hide();
    //    $("dt").click(function () {
    //        var index = $("dt").index(this);
    //        $("dd").eq(index).slideToggle("fast");

    //    }).css("cursor", "pointer");
    //}

    const hantozen = {};
    const zentohan = {};

    //function kyanpenopen() {

    //    $('#kyanpen').show();
    //}

    function create2(zenstr, hanstr) {
        const zenInc = arguments[2] !== (void 0) ? arguments[2] : 0;
        const hanMark = arguments[3] !== (void 0) ? arguments[3] : '';
        const zens = zenstr.split('');
        hanstr.split('').map(function (han, i) {
            const zen = zens[i].charCodeAt(0);
            hantozen[han] = zen;
            zentohan[zen] = han;
            if (zenInc) {
                const hanv = han + hanMark;
                const zenv = zen + zenInc;
                hantozen[hanv] = zenv;
                zentohan[zenv] = hanv;
            }
        });
    }

    function create(hash) {
        for (let zen in hash) {
            const han = hash[zen];
            zen = zen.charCodeAt(0);
            hantozen[han] = zen;
            zentohan[zen] = han;
        }
    }
    create2('ァィゥェォアイウエオナニヌネノマミムメモヤユヨラリルレロワヲンッ、。', 'ｧｨｩｪｫｱｲｳｴｵﾅﾆﾇﾈﾉﾏﾐﾑﾒﾓﾔﾕﾖﾗﾘﾙﾚﾛﾜｦﾝｯ､｡');
    create2('カキクケコサシスセソタチツテトハヒフヘホ', 'ｶｷｸｹｺｻｼｽｾｿﾀﾁﾂﾃﾄﾊﾋﾌﾍﾎ', 1, 'ﾞ');
    create2('ハヒフヘホ', 'ﾊﾋﾌﾍﾎ', 2, 'ﾟ');
    create({
        'ヴ': 'ｳﾞ',
        '、': '､',
        '。': '｡',
        '・': '･',
        '「': '｢',
        '」': '｣',
        'ー': 'ｰ'
    });

    function toNormalizeString(text) {
        if (text == null) return "";
        return toKatakana(toZenkaku(text.toString().toUpperCase())).replace(/\s+/, ' ');
    }

    function toKatakana(source) {
        const src = '' + source;
        let out = '';
        for (let i = 0, l = src.length; i < l; ++i) {
            const ch = src.charCodeAt(i);
            if (ch >= 0x3041 && ch <= 0x3096) {
                out += String.fromCharCode(ch + 96);
            } else {
                out += src[i];
            }
        }
        return out;
    }

    function toZenkaku(source) {
        const src = '' + source;
        let out = '';
        for (let i = 0; i < src.length; ++i) {
            let ch = src.charCodeAt(i);

            if (ch === 0x20) {
                ch = 0x3000;
            } else if (ch === 0x7ce7) {
                ch = 0x6599;
            } else if (ch >= 0x21 && ch <= 0x7F) {
                ch = ch + 0xFF01 - 0x21;
            } else if (ch >= 0xFF61 && ch <= 0xFF9F) {
                let han = src[i];
                if (src[i + 1] === 'ﾞ' || src[i + 1] === 'ﾟ') {
                    han += src[++i];
                }
                ch = hantozen[han];
            }
            out += String.fromCharCode(ch);
        }
        return out;
    }
      (function ($) {
      

            $.fn.rwdImageMaps = function () {
                var $img = this;
                console.log($img)

                var rwdImageMap = function () {

                    $img.each(function () {
                        if (typeof ($(this).attr('usemap')) == 'undefined') {

                            return;
                        }


                        var that = this,
                            $that = $(that);

                        // Since WebKit doesn't know the height until after the image has loaded, perform everything in an onload copy
                        $('<img />').off('load').on('load', function () {


                            var attrW = 'width',
                                attrH = 'height',
                                w = $that.attr(attrW),
                                h = $that.attr(attrH);

                            if (!w || !h) {
                                var temp = new Image();
                                temp.src = $that.attr('src');
                                if (!w)
                                    w = temp.width;
                                if (!h)
                                    h = temp.height;
                            }

                            var wPercent = $that.width() / 100
                            var hPercent = $that.height() / 100
                            //var mapdata = $that.attr('usemap');

                            var map = $that.attr('usemap').replace('#', '')
                            var c = 'coords'

                            // console.log(map)
                            // console.log("width:" + $that.height() + ",height:" + $that.width() )
                            // console.log("hParcent:" + hPercent + ",wPercent:" + wPercent)



                            $('map[name="' + map + '"]').find('area').each(function () {
                                //console.log(map)
                                var $this = $(this);
                                if (!$this.data(c))
                                    $this.data(c, $this.attr(c));

                                var coords = $this.data(c).split(','),
                                    coordsPercent = new Array(coords.length);
                                // console.log("cords:" +  coords)
                                for (var i = 0; i < coordsPercent.length; ++i) {
                                    if (i % 2 === 0)
                                        coordsPercent[i] = parseInt(((coords[i] / w) * 100) * wPercent);
                                    else
                                        coordsPercent[i] = parseInt(((coords[i] / h) * 100) * hPercent);
                                }
                                //console.log(coordsPercent.toString())
                                $this.attr(c, coordsPercent.toString());
                            });


                        }).attr('src', $that.attr('src'));
                    });
                };

                $(window).resize(rwdImageMap).trigger('resize');

                return this;
            };
    })(jQuery);

  
    $(function () {
        $('#menu-btn').click(function () {
            $(this).blur();
            $('header').toggleClass('open');

            if ($('header').hasClass('open') == false) {
                $('header').addClass('close');

  
            } else {
                $('header').removeClass('close');

     

            }

        });


        
        $('.header__links > li > img').click(function () {
            $('header').removeClass('open');
            $('header').removeClass('shadow');

            $('header').addClass('close');

        });
    });
</script>

<style lang="scss">
    .hooper-sample {
        background-color: #d5d5d5;
        text-align: center;
        &__hooper

    {
    }

    &__slide {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    }
</style>

